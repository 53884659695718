import CustomerViewer from "./CustomerViewer";
import { connect } from "react-redux";
import { getCustomerList } from "../../../../redux/actions/userManagement/customer.action";
import { getAllCustomerList } from "../../../../redux/actions/userManagement/getAllCustomerList.action";

const mapStateToProps = (state) => {

  return {
    list: state.customerList.list,
    next: state.customerList.next,
    isLoading: state.customerList.loading,
    myRights:state.myRights.myRights,
    allCustomerOption:state?.onboardingRequest?.getAllCustomerList.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerList: (status = "", searchKey = "", next = "", orgName = "", serviceType = "", orderBy = "") => {
      return dispatch(getCustomerList(status, searchKey, next, orgName, serviceType, orderBy));
    },
    getAllCustomerList:() => {
      return dispatch(getAllCustomerList());
    }


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerViewer);

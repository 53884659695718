import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './table.module.css';

const Table = ({ headers, data, height, width, maxHeight, columnConfig, nodata, onRowClick, isClisk }) => {
  const [selectedRow, setSelectedRow] = useState(null)
  const getColumnWidth = (header) => {
    const column = columnConfig.find(col => col.name === header.name || col.name === header.key);
    return column ? column.flex : 1;
  };
  const handleRowClick = (operatorCode, rowIndex) => {
    setSelectedRow(rowIndex);
    onRowClick(operatorCode);
  };

  return (
    <div className={`${styles.tableContainer} table-responsive`} style={{ height, width, maxHeight }}>
      <table className={`table ${styles.customTable}`} style={{margin:0}}>
        <thead className={styles.tableHeader}>
          <tr>
            {headers.map((header, index) => (
              <th key={index} style={{ flex: getColumnWidth(header) }} className={styles.tableHeaderCell}>
                <div className={styles.headerContent}>{header}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={styles.tabledata}>
        {data?.length === 0 ? (
            <tr className={styles.nodata}>
              <td colSpan={headers?.length} className={styles.filterdata}>
                {nodata}
              </td>
            </tr>
          ) : (
            data?.map((row, rowIndex) => (
              isClisk?<tr key={rowIndex} className={`${rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow} ${selectedRow === rowIndex ? styles.selectedRow : ''} ${styles.selectable}`}  onClick={() => handleRowClick(row['Operator Code'], rowIndex)}>
              {headers.map((header, colIndex) => (
                <td key={colIndex} className={`${styles.tableCell}`} style={{ flex: getColumnWidth(header) }}>
                  <div className={`${styles.cellcontent}`}>
                    {row[header.key || header]}
                  </div>
                </td>
              ))}
            </tr>:
              <tr key={rowIndex} className={`${rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}`}>
                {headers.map((header, colIndex) => (
                  <td key={colIndex} className={`${styles.tableCell}`} style={{ flex: getColumnWidth(header) }}>
                    <div className={`${styles.cellcontent}`}>
                      {row[header.key || header]}
                    </div>
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnConfig: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  })).isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default Table;
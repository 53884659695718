export const UserManagementTab = {
    CUSTOMER: "CUSTOMERS",
    VF_GROUP_USER: "VF_GROUP_USER",
    ON_BOARDING_APPROVALS: "ON_BOARDING_APPROVALS",
    // MY_REQUEST: "MY_REQUEST",
    ON_BOARDING_REQUEST: "ON_BOARDING_REQUEST", 
}

export const ServiceType = {
    International_Voice_Services: "International_Voice_Services",
    IPX_Services: "IPX_Services",   
}
export const My_Request_SUBTAB = {
    ON_BOARDING_REQUEST: "ON_BOARDING_REQUEST",
    EXTENSION_ACCESS_REQUEST: "EXTENSION_ACCESS_REQUEST",
    EXTENSION_ACCESS_APPROVAL: "EXTENSION_ACCESS_APPROVAL",
    ON_BOARDING_APPROVAL: "ON_BOARDING_APPROVAL",
}

export const EXTENSTION_STATUS = {
    PENDING : "pending",
    APPROVED : "approved"
}

export const ON_BOARDING_STATUS = {
    PENDING : "pending",
    APPROVED : "approved",
    REJECTED : "rejected"
}

export const FUNCTIONAL_ROLE_ENUM = {
    USER_MANAGEMENT:"user_management_customer",
    INCIDENT_MANAGEMENT:"incident_management_customer",
    DASHBOARD:"dashboard_customer"
}

export const headers = ["Operator Code", "Operator Name"];
export const columnConfig = [{ name: "Operator Code", flex: 1 },{ name: "Operator Name", flex: 2 }];

export const customerListcolumnConfig = [
    { name: 'organisationLegalName', flex: 3.9 },
    { name: 'serviceType', flex: 2.7 },
    { name: 'onBoardedBy', flex: 2.7 },
    { name: 'onBoardedOn', flex: 2.7 },
  ];

export const onboardingRequestcolumnConfig = [
    { name: 'requestId', flex: 2 },
    { name: 'organisationLegalName', flex: 2 },
    { name: 'serviceType', flex: 2 },
    { name: 'requestAssignTo', flex: 2 },
    { name: 'requestedOn', flex: 2 },
    { name: 'modifiedOn', flex: 2 },
  ];

export const onboardingApprovalcolumnConfig = [
    { name: 'requestId', flex: 2 },
    { name: 'organisationLegalName', flex: 2 },
    { name: 'serviceType', flex: 2 },
    { name: 'requestedOn', flex: 2 },
    { name: 'modifiedOn', flex: 2 },
  ];

export const serviceType = [{label:"IPX Service", value:"ipx_service"},{label:"International Voice Service", value:"ivc_service"}]
import { connect } from "react-redux";
import { getRequestList } from "../../../../../../redux/actions/userManagement/onBoardingRequestList.action";
import OnboardingRequestListViewer from "./onboardingRequestListViewer";
import { ReadNotification } from "../../../../../../redux/actions/notification/getEventNotificationList.action";

const mapStateToProps = (state) => {
  return {
    requestList : state.onboardingRequest.onboardingRequestList.data,
    loading : state.onboardingRequest.onboardingRequestList.isLoading,
    next : state.onboardingRequest.onboardingRequestList.next,
    statusCount: state.onboardingRequest.onboardingRequestList.statusCount,
    notificationdata:state?.notifications?.moduleNotification?.data?.data?.CUSTOMER_ONBOARDING_REQUEST
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
     getRequestList: (next=null,status = "",)=>{
      return dispatch(getRequestList(next=null,status))
    },
    ReadNotification: (payload,id) => {
      return dispatch(ReadNotification(payload,id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingRequestListViewer);

import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

const dropdownStyle = {
    control: (provided, state) => {
        const { selectProps: { hasError }, isDisabled } = state;
        return {
            ...provided,
            backgroundColor: isDisabled ? "#999" : "#ffffff",
            borderRadius: 5,
            height: 48,
            borderWidth: 1,
            borderColor: hasError ? "#CD3500" : "#C5C1C8"
        };
    },
    singleValue: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "#100045",
        fontFamily: "VodafoneRg-Regular",
        fontSize: 13
    }),
    placeholder: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "#837B8B",
        fontFamily: "VodafoneRg-Regular",
        fontSize: 16
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        width: 0
    }),
    container: (provided, state) => {
        const { selectProps: { customStyles } } = state;
        return {
            ...provided,
            ...customStyles
        };
    },
    menuList: (provided) => ({
        ...provided,
        maxHeight: 200,
        overflowY: 'auto',
        position: 'relative',
        WebkitOverflowScrolling: 'touch',
        paddingBottom: 4,
        paddingTop: 4,
        boxSizing: 'border-box'
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'nowrap',
        overflowX: 'auto',
        maxWidth: '100%'
    }),
    MultiValueRemove:(provided) =>({
        ...provided,
        display:"none",
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: "default",
        display: "flex",
        fontSize: "inherit",
        width: "100%",
        userSelect: "none",
        backgroundColor: state.isSelected ? "#FFFFFF" : state.isFocused ? "#FFF0F0" : "transparent",
        // color: state.isSelected ? "hsl(0, 0%, 100%)" : provided.color,
        padding: "8px 12px",
        boxSizing: "border-box",
        // borderBottom: "1px solid #EBEBEB",
        position: "relative",
        "&::after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: "10%",
            width: "80%",
            borderBottom: "1px solid #EBEBEB"
        },

        "&:hover": {
            backgroundColor: "#FFF0F0",
            // color: "hsl(0, 0%, 100%)"
        }
    })
};

const defaultCustomStyles = {
    width: "auto"
};

// const CustomOption = (props) => {
//     return (
//         <components.Option {...props}>
//             <input
//                 type="checkbox"
//                 checked={props.isSelected}
//                 onChange={() => null}
//                 style={{ marginRight: 8 }}
//             />
//             <label>{props.label}</label>
//         </components.Option>
//     );
// };

const CustomOption = (props) => {
    return (
        <components.Option {...props} style={{display:'flex'}}>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
                style={{ 
                    marginRight: 8,
                    appearance: "none",
                    width: 16,
                    height: 16,
                    border: "1px solid #C5C1C8",
                    borderRadius: 3,
                    backgroundColor: props.isSelected ? "#CCCCCC" : "transparent",
                    position: "relative",
                    margin:'auto 5px auto 0'
                }}
            />
            {props.isSelected && (
                <span style={{
                    position: "absolute",
                    // top: 14,
                    // left: 0,
                    width: 16,
                    height: 16,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#333333",
                    fontSize: 13,
                    marginTop:3,
                    marginLeft:0
                }}>
                    ✓
                </span>
            )}
            <label style={{ color: props.isSelected ? "#333333" : "inherit" }}>{props.label}</label> {/* Change label color */}
        </components.Option>
    );
};
const CustomMultiValueRemove = () => null;
const CustomDropdownIndicator = () => null;
const CustomClearIndicator = () => null;

const DropDown = ({ options, placeholder, onChange, value, hasError, customStyles = defaultCustomStyles, menuIsOpen }) => {
    return (
        <Select
            placeholder={placeholder}
            options={options}
            isMulti={true}
            onChange={onChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={value}
            customStyles={customStyles}
            menuIsOpen={menuIsOpen}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ 
                Option: CustomOption, 
                MultiValueRemove: CustomMultiValueRemove,
                DropdownIndicator: CustomDropdownIndicator,
                ClearIndicator: CustomClearIndicator
            }}
        />
    );
};

DropDown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    hasError: PropTypes.bool,
    customStyles: PropTypes.object,
    menuIsOpen: PropTypes.bool
};

DropDown.defaultProps = {
    placeholder: "Select",
    value: [],
    hasError: false,
    customStyles: defaultCustomStyles,
    menuIsOpen: false
};

export default DropDown;
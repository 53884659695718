import ServiceRequestViewer from "./myserviceRequestViewer";
import { getServiceList } from "../../../../redux/actions/incidentManagement/serviceList.action";
import { connect } from "react-redux";
import { ReadNotification } from "../../../../redux/actions/notification/getEventNotificationList.action";

const mapStateToProps = (state) => {
  return {
    serviceList: state.serviceList,
    myRights : state.myRights.myRights,
    statusCount: state.serviceList.statusCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceList: (next=null,status="",orderBy="")=>{
      return dispatch(getServiceList(next,status,orderBy))
  },
  // ReadNotification: (payload,id) => {
  //   return dispatch(ReadNotification(payload,id))
  // }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequestViewer);

import { FETCH_ONBOARDING_APPROVAL_LIST } from "../actionTypes";
import { fetchOnBoardingApprovalList } from "../../../api/userManagement/onBoardingApprovalList.service";

const onBordingApprovalListRequest = ()=>({type: FETCH_ONBOARDING_APPROVAL_LIST.REQUEST})
const onBordingApprovalListFailure = (error)=>({type: FETCH_ONBOARDING_APPROVAL_LIST.FAILURE, error})
const onBordingApprovalListSuccess = (obj)=>({type: FETCH_ONBOARDING_APPROVAL_LIST.SUCCESS, obj})


export const getOnBordingApprovalList = (next,status = "",)=>{
    return (dispatch, getState)=>{        
        dispatch(onBordingApprovalListRequest())
        return fetchOnBoardingApprovalList(next,status)
        .then(response => {         
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false,
                'statusCount':response?.statusCount
            }    
            dispatch(onBordingApprovalListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(onBordingApprovalListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
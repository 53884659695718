
import {
    BASE_URL, 
    POST,  
    GET,
    PATCH,
    EVENT_NOTIFICATION_LIST,
    READ_EVENT_NOTIFICATION,
    NOTIFICATION_DROPDOWN_LIST
  } from "../constants";
  import { statusParsing, jsonParsing } from "../utils";
  import { getAccessToken } from "../../authActions/authActions";
  

    export const fetchEventNotificationList = async (next,option) => {
      
        const token = await getAccessToken();
        let url = `${BASE_URL}${EVENT_NOTIFICATION_LIST}?module=${option}`;
      
      let array = [
        {
          key:"next",
          value:next
        } // download true
      ]  
      
      let queryString=``;
      array.forEach((item)=>{
        if(item.value){
          if(queryString){
            queryString=queryString+`&${item.key}=${item.value}`
          }else{
            queryString=`${item.key}=${item.value}`
          }
        }
      })
      if(queryString){
        url=`${url}?${queryString}`;
        url = url.replace ("filter=&", "");
      }
        return fetch(url, {
          method: GET,
          headers: {
            "Content-Type": "application/json",
            userAuth: `bearer ${token}`,
          },
        })
          .then(statusParsing)
          .then(jsonParsing);
      };
      


export const ReadEventNotificationList = async (payload, requestId) => {
    const token = await getAccessToken();
    const resource = `${BASE_URL}${READ_EVENT_NOTIFICATION}/${requestId}`

    return fetch(resource, {
        method: PATCH,
        headers: {
            "Content-Type": "application/json",
            "userauth": `bearer ${token}`
        },
        body: JSON.stringify(payload)
    })
        .then(statusParsing)
        .then(jsonParsing)
}

export const fetchNotificationDropdown = async (requestId) => {

  const token = await getAccessToken();
  const url = `${BASE_URL}${NOTIFICATION_DROPDOWN_LIST}`;
  return fetch(url, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);
};

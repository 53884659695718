import { connect } from "react-redux";
import OnboardingApprovalListViewer from "./onboardingApprovalListViewer";
import { getOnBordingApprovalList } from "../../../../../../redux/actions/userManagement/onBoardingApprovalList.action";
import { ReadNotification } from "../../../../../../redux/actions/notification/getEventNotificationList.action";

const mapStateToProps = (state) => {
  return {
    requestList : state.onboardingRequest.onBooardingApprovalList,
    myRights : state.myRights.myRights,
    statusCount: state.onboardingRequest.onBooardingApprovalList.statusCount,
    notificationdata:state?.notifications?.moduleNotification?.data?.data?.CUSTOMER_ONBOARDING_APPROVAL,
  };
};

const mapDispatchToProps = (dispatch) => {
  return { 
    getOnBordingApprovalList: (next=null,status = "",)=>{
      return dispatch(getOnBordingApprovalList(next=null,status))
    },
    ReadNotification: (payload,id) => {
      return dispatch(ReadNotification(payload,id))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingApprovalListViewer);
import { Link, useParams, useSearchParams } from "react-router-dom";
import IMAGES from "../../../../assets/images/images";
import {
  ChildContainer,
  Container,
} from "../../../../components/Container/Containers";
import { Search } from "../../../../components/search/search";
import NewCustomer from "../add/NewCustomer.container";
import styles from "./CustomerViewer.module.css";
import CustomerDetail from "../detail/CustomerDetail.container";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../components/loader/Loader";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";
import { useNavigate } from "react-router-dom";
import SectionGuard from "../../../../hoc/guards/SectionGuard";
import CAN from "../../../../casl/can";
import { Action, Resource } from "../../../../casl/constants";
import { formatDate } from "../../../../utils/arrayUtils";
import Table from "../../../../components/table/Table";
import { customerListcolumnConfig, serviceType } from "../../../../utils/constants/userManagementConstant";
import SecondaryTooltip from "../../../../components/secondaryToolTip/secondaryToolTip";
import { AppliedFilter, Filter } from "../../../../components/filter/filter";


const CustomerViewer = ({ list,allCustomerList,myRights, isLoading,getAllCustomerList, getCustomerList,next,allCustomerOption }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const customerId = searchParams.get("customerId");
  const [sortOrder, setSortOrder] = useState("desc");
  const [filterState, setFilterState] = useState({
    isOrgnameFilter: false,
    selectedOrgNameOptions: [],
    isServiceTypeFilter: false,
    selectedServiceTypeptions: []
  });

  let [searchKeyValue, setSearchKeyValue] = useState("");
  const getSearchKeyValue = (e) => {
    setSearchKeyValue(e.target.value);
  };

  const filterData = () => {
    const searchKey = searchKeyValue.trim();
    const cp_name = myRights?.cpName;
    const orgName = filterState.selectedOrgNameOptions.map((item) => item.value).join(",");
    const serviceType = filterState.selectedServiceTypeptions.map((item) => item.value).join(",");
    getCustomerList(cp_name, searchKey, "", orgName, serviceType, sortOrder);
  };

  // const onKeyDownHandler = (e) => {
  //   if (e.key === "Enter") {
  //     const orgName = filterState.selectedOrgNameOptions.map((item) => item.value).join(",");
  //     const serviceType = filterState.selectedServiceTypeptions.map((item) => item.value).join(",");
  //     filterData(orgName, serviceType);
  //   }
  // };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
  };

  useEffect(() => {
    filterData();
  }, [filterState.selectedOrgNameOptions,filterState.selectedServiceTypeptions,sortOrder]);

  useEffect(() => {
    if (!customerId) {  // if null then its listing page and call listing api
        getCustomerList();
    }
  }, [customerId]);
useEffect(() => {
  getAllCustomerList();
}, []);
  const removeCustomerId = useCallback(() => {
    setSearchParams((p) => {
      p.delete("customerId");
      return p;
    });
  }, [setSearchParams]);

  useEffect(() => {
    return () => {
      if (customerId) {
        removeCustomerId();
      }
    };
  }, [customerId, removeCustomerId]);
  if (customerId) {
    return <CustomerDetail onBack={removeCustomerId} customerId={customerId} />;
  }

  const headers =[
    <div className={styles.organisationName} key="organisationLegalName"><div className={styles.orgname}>Organisation Legal Name</div>
    {/* <img className={styles.filter} src={IMAGES.filtericon} onClick={() => setFilterState(prevState => ({ ...prevState, isOrgnameFilter: true }))} alt="Filter"/> */}
    </div>,   
    <div className={styles.organisationName} key="serviceType"><div className={styles.orgname}>Service Type</div>
    {/* <img className={styles.filter} src={IMAGES.filtericon} onClick={() => setFilterState(prevState => ({ ...prevState, isServiceTypeFilter: true }))} alt="Filter"/> */}
    </div>,
    <div className={styles.organisationName} key="onBoardedBy"><div className={styles.orgname}>Onboarded By</div></div>,
    <div className={styles.organisationName} key="onBoardedOn"><div className={styles.orgname}>Onboarded On</div><img className={`${styles.sorting} ${sortOrder==='desc'?styles.desc:styles.asc}`} src={IMAGES.sorticon} onClick={toggleSortOrder} alt="sort"/></div>
];

const mappedData = list.map((item) => {
  const serviceType = item?.serviceSpecificDetails?.map((detail)=>detail.serviceType?.label)
  return {
    organisationLegalName: <span className={`${styles.orglgname}`}><SecondaryTooltip text={item.displayName} positions={['bottom', 'right']}> <Link className={styles.link} to={`/user-management?customerId=${encodeURIComponent(item.uniqueCustomerRefId)}`}>{item.displayName}</Link></SecondaryTooltip></span>,
    serviceType: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={serviceType} positions={['bottom', 'right']}>{serviceType}</SecondaryTooltip></span>,
    onBoardedBy: <span className={`${styles.customDataContent}`}><SecondaryTooltip copy={true} text={item.createdBy.email} positions={['bottom', 'right']}>{item.createdBy.name}</SecondaryTooltip></span>,
    onBoardedOn: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={formatDate(item.createdAt)} positions={['bottom', 'right']}>{formatDate(item.createdAt)}</SecondaryTooltip></span>
  };
});


const handleServiceFilterApply = (options) => {
  setFilterState(prevState => ({
    ...prevState,
    selectedServiceTypeptions: options
  }));
};

const handleOrgNameFilterReset = () => {
  setFilterState(prevState => ({
    ...prevState,
    isOrgnameFilter:false,
    selectedOrgNameOptions: [],
  }));
};

const handleServiceTypeFilterReset = () => {
  setFilterState(prevState => ({
    ...prevState,
    isServiceTypeFilter:false,
    selectedServiceTypeptions: [],
  }));
};

const resetappliedFilter = () =>{
  handleOrgNameFilterReset();
  handleServiceTypeFilterReset();
}

const handleOrgNameFilterApply = (selectedOptions,lastremoved) => {
  setFilterState(prevState => ({
    ...prevState,
    selectedOrgNameOptions: selectedOptions
  }));
  if(lastremoved){
    setFilterState(prevState => ({
      ...prevState,
      selectedServiceTypeptions: []
    }));
  }
};
const handleServiceTypeFilterApply = (selectedOptions,lastremoved) => {
  setFilterState(prevState => ({
    ...prevState,
    selectedServiceTypeptions: selectedOptions
  }));
  if(lastremoved){
    setFilterState(prevState => ({
      ...prevState,
      selectedOrgNameOptions: []
    }));
  }
};

const filterByQuickSearch=(value)=>{
  setSearchKeyValue(value)
    const searchKey = value.trim();
    const cp_name = myRights?.cpName;
    const orgName = filterState.selectedOrgNameOptions.map((item) => item.value).join(",");
    const serviceType = filterState.selectedServiceTypeptions.map((item) => item.value).join(",");
  getCustomerList(cp_name, searchKey, "", orgName, serviceType, sortOrder);
}
  return (
    <>
      <div className={styles.fincainfo}><img className={styles.circleinfo} src={IMAGES.infoCircle}/>This section provides an overview of all demands raised by customers. Additionally, you can add comments and update the status as needed.</div>
      <React.Fragment>
        <div className={styles.wrapperchild}>
        <ChildContainer>
          <div className={styles.headContainer}>
            <div className={styles.search}>
             <Search 
                 getSearchKeyValue={getSearchKeyValue}
                 placeholder="Search By Org. Legal Name"
                 onButtonClick={filterByQuickSearch}
                 searchKeyValue={searchKeyValue}
              />
            </div>
            <SectionGuard canAccess={CAN(Action.CREATE,Resource.CUSTOMER_ACCESSR_REQUEST)}>
            <NewCustomer getCustomerList = {allCustomerList} />
            </SectionGuard>
          </div>
          {(filterState.selectedOrgNameOptions.length > 0 || filterState.selectedServiceTypeptions.length>0) && (<AppliedFilter selectedFilterOptions={filterState?.selectedOrgNameOptions} selectedInvoicingPartyOptions={filterState?.selectedServiceTypeptions} onReset={resetappliedFilter}setSelectedFilterOptions={setFilterState} handleFilterApply={handleOrgNameFilterApply} handleInvoicingFilterApply={handleServiceTypeFilterApply}  firstFilterLabel={"Organisation Legal Name"} secondFilterLabel={"Service Type"}/>)}
          <div className={styles.wrapper}>
            {filterState.isOrgnameFilter && (
              <div className={styles.popup}><Filter isOpen={filterState?.isOrgnameFilter} options={allCustomerOption?.map(item => ({ value: item?.displayName, label: item?.displayName }))} onApply={handleOrgNameFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isOrgnameFilter: false }))} label={"Organisation Legal Name"} initialSelectedOptions={filterState?.selectedOrgNameOptions} onReset={handleOrgNameFilterReset}top="55px"left="17%"/></div>)}
            {filterState.isServiceTypeFilter && (
              <div className={styles.popup}><Filter isOpen={filterState.isServiceTypeFilter} options={serviceType} onApply={handleServiceFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isServiceTypeFilter: false }))} label={"Service Type"} initialSelectedOptions={filterState.selectedServiceTypeptions} onReset={handleServiceTypeFilterReset} top="55px"left="38%"/></div>)}
          </div>
          {isLoading && <Loader />}
        {/* {
          !isLoading && list.length==0?
          <EmptyContainer>No Customer Found</EmptyContainer>:<></>
        } */}
          <InfiniteScroll
            dataLength={list?.length} //This is important field to render the next data
            next={() =>  {
              // return getCustomerList(myRights.cpName, searchKeyValue.trim(), next);
              let orgName = filterState.selectedOrgNameOptions.join(",");
              let serviceType = filterState.selectedServiceTypeptions.join(",");
              return getCustomerList(myRights.cpName, searchKeyValue.trim(), next, orgName, serviceType, sortOrder);
            }}
            hasMore={next}
            loader={
                <Loader />
            }
          >
          {/* {list?.map((item, key) => (
            <div className={styles.container} key= {key}>
              <div className={styles.containerheader}>
                <Link className={styles.link} to={`/user-management?customerId=${encodeURIComponent(item.uniqueCustomerRefId)}`}>
                  <div className={styles.text}>{item.displayName}</div>
                </Link>
              </div>

              <div className={styles.wrapper}>
               
                  <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Service Type</div>
                   <div className={styles.sub}>{item?.serviceSpecificDetails?.map((detail)=>detail.serviceType?.label).join(' & ')}</div>
                    </div>
                  </div>
                   <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Onboarded By</div>
                      <div className={styles.sub}>{item.createdBy.name}</div>
                    </div>
                  </div>
                    <div className={styles.parentDiv}>
                    <div className={styles.headData}>
                      <div className={styles.headSub}>Onboarded On</div>
                      <div className={styles.sub}>{formatDate(item.createdAt)}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}   */}
            <Table headers={headers} data={mappedData} height="auto" width="100%" columnConfig={customerListcolumnConfig} nodata={mappedData?.length===0?"No Data.":""} />
             </InfiniteScroll>
        </ChildContainer>
        </div>
      </React.Fragment>
    </>
  );
};

export default CustomerViewer;

import { BASE_URL, POST, GET, GET_INVOICES } from "../constants"
import { statusParsing, jsonParsing } from "../utils"
import { getAccessToken } from "../../authActions/authActions"

export const fetchInvoiceDetails = async (invoicePeriod, fincaId) => {

  const token = await getAccessToken()
  const url = `${BASE_URL}${GET_INVOICES}/${fincaId}?invoicePeriod=${invoicePeriod}`
  // console.log(" invoicePeriod : ", invoicePeriod, "fincaId : ",fincaId)
  // console.log(" url : ", url)
  return fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`
    }
  })
    .then(statusParsing)
    .then(jsonParsing)
}

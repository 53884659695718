import {combineReducers} from "redux";

import myRights from "./userManagement/myRights.reducer";
import newService from "./newService/index.reducer";
import servicesList from "./newService/servicesList.reducer";
import diameterDashboard from "./dashboard/diameter/index.reducer"
import weatherMapDashboard from "./dashboard/weatherMap/index.reducer"
import sigtranDashboard from "./dashboard/sigtran/index.reducer"
import genericInformation from "./genericInformation/genericInformation.reducer";
import liveServices from "./liveServices/liveServices.reduce";
import newuser from "./userManagement/newUser.reducer";
import userViewer from "./userManagement/userViewer.reducer";
import functionalRoles from "./userManagement/functionalRoles.reducer";
import customerList from "./userManagement/customer.reducer"
import CustomerDetail from "./userManagement/customerDetails.reducer"
import submitCustomerDetails from "./userManagement/submitNewCustomer.reducer"
import serviceDetail from "./newService/serviceDetail.reducer";
import contactUs from "./contactUs/contactUs.reducer";
import verifyEmail from "./userManagement/verifyEmail.reducer";
import forgotPassword from "./userManagement/forgotPassword.reducer";
import alert from "./alert/alert.reducer";
import IncidentManagement from "./incidentManagement/raiseIncident.action.reducer";
import incidentTab from "./incidentManagement/incidentTab.reducer";
import incidentList from "./incidentManagement/incidentList.reducer"
import incidentDetail from "./incidentManagement/incidentDetail.reducer"
import incidentComment from "./incidentManagement/incidentComment.reducer"
import serviceDetails from "./incidentManagement/serviceDetails.reducer";
import serviceList from "./incidentManagement/serviceList.reducer";
import serviceComment from "./incidentManagement/serviceComment.reducer";
import onboardingRequest from "./userManagement/index.reducer";
import accountAccessRequest from "./userManagement/accountAccessRequest.reducer"
import accountAccessApproval from "./userManagement/accountAccessApproval.reducer"
import tabReducer from "./userManagement/userManagementTab.reducer";
import fincaInvoicesReducer from "./finca/fincaInvoices.reducer";
import invoiceDetailsReducer from "./finca/invoiceDetails.reducer";
import alertErrorMessage from "./alertErrorMessage/alertErrorMessage.reducer";
import fincaTadig from "./finca/fincaTadig.reducer";
import notifications from "./notification/index.reducer";
import missingFincaCustomers from "./finca/missingFincaCustomers.reducer"
export default combineReducers({
    myRights,
    newService,
    servicesList,
    diameterDashboard,
    weatherMapDashboard,
    sigtranDashboard,
    genericInformation,
    liveServices,
    newuser,
    userViewer,
    functionalRoles,
    serviceDetail,
    customerList,
    submitCustomerDetails,
    contactUs,
    CustomerDetail,
    verifyEmail,
    forgotPassword,
    alert,
    IncidentManagement,
    incidentTab,
    incidentList,
    incidentDetail,
    incidentComment,
    serviceDetails,
    serviceList,
    serviceComment,
    onboardingRequest,
    accountAccessRequest,
    accountAccessApproval,
    tabReducer,
    fincaInvoicesReducer,
    invoiceDetailsReducer,
    alertErrorMessage,
    fincaTadig,
    notifications,
    missingFincaCustomers
})
import { fetchCustomerList, fetchCustomerDetails } from "../../../api/userManagement/userManagement.service";
import { FETCH_CUSTOMER_LIST, FETCH_CUSTOMER_DETAILS } from "../actionTypes";

const request = () => ({ type: FETCH_CUSTOMER_LIST.REQUEST });
const failure = (error) => ({ type: FETCH_CUSTOMER_LIST.FAILURE, error });
const success = (payload) => ({ type: FETCH_CUSTOMER_LIST.SUCCESS, list: payload.customers,next:payload.next, isLoadMore : payload.isLoadMore });

export const getCustomerList = (cp_name="", searchKey="", next="", orgName="", serviceType="", orderBy="desc") => {
  return (dispatch) => {
    dispatch(request());
    return fetchCustomerList(cp_name, searchKey, next, orgName, serviceType, orderBy)
      .then((res) => {
        dispatch(success({...res, isLoadMore: !!next}));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve(false);
      });
  };
};



const requestDetails = () => ({ type: FETCH_CUSTOMER_DETAILS.REQUEST });
const failureDetails = (error) => ({ type: FETCH_CUSTOMER_DETAILS.FAILURE, error });
const successDetails = (payload) => ({ type: FETCH_CUSTOMER_DETAILS.SUCCESS, customer: payload });

export const getCustomerDetails = (customerID) => {
  return (dispatch) => {
    dispatch(requestDetails());
    return fetchCustomerDetails(customerID)
      .then((res) => {
        dispatch(successDetails(res.customer?.[0]));
        return Promise.resolve(true);
      })
      .catch((error) => {
        dispatch(failureDetails(error?.message));
        return Promise.resolve(false);
      });
  };
};
import React, { useEffect, useRef, useState} from "react";
import styles from "./fincaInvoices.module.css";
import {ChildContainer,Container} from "../../../components/Container/Containers";
import { Banner } from "../../../components/banner/Banners";
import IMAGES from "../../../assets/images/images";
import { USER_TYPE} from "../../../utils/constants/userConstants";
import { useLocation } from "react-router-dom";
import currencySymbol from "../../../utils/constants/currencySymbol";
import { columnConfig, filterInvoices, getTooltipText, hasValidFincaTadigCode, INVOICE_STATUS } from "../../../utils/constants/fincaConstant";
import { SelectField, TextField } from "../component/Component";
import InvoiceDetails from "../detail/invoiceDetails.container";
import { downloadPDF } from "../../../utils/base64ToPdf";
import { fetchInvoiceDetails } from "../../../api/finca/invoiceDetails.service";
import SecondaryTooltip from "../../../components/secondaryToolTip/secondaryToolTip";
import Table from "../../../components/table/Table";
import Status from "../../../components/status/Status";
import {AppliedFilter, Filter} from "../../../components/filter/filter";
import moment from 'moment';
import MissingFincalist from "../missingFincaList/MissingFincalist";
import { Action, Resource } from "../../../casl/constants";
import CAN from "../../../casl/can";
import SectionGuard from "../../../hoc/guards/SectionGuard";

const FincaInvoices = (props) => {
  const filterIconRef = useRef(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [lastGenratedInvoice,setLastGenratedInvoice] = useState({lastinvoice:true,months:false,custom:false});
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [disabledInvoices, setDisabledInvoices] = useState({});
  const [filterState, setFilterState] = useState({
    isFilterPopupOpen: false,
    selectedFilterOptions: [],
    isFilterPopupOpenInvoicingParty: false,
    selectedInvoicingPartyOptions: []
  });
  const [isSortedAscending, setIsSortedAscending] = useState(false);

  const handleTextFieldFocus = () => {
  }
  const selectRef = useRef(null);
  const [filteredData, setFilteredData] = useState(props.invoiceData);
  const allcount = filterState.selectedFilterOptions.length===0||filterState.selectedInvoicingPartyOptions===0?props?.invoiceData.length:filteredData.length;
  const clientApprovedCount = filterState.selectedFilterOptions.length===0||filterState.selectedInvoicingPartyOptions===0?props?.invoiceData?.filter(invoice => invoice.status === INVOICE_STATUS.clientApproved)?.length:filteredData.filter(invoice => invoice.status === INVOICE_STATUS.clientApproved)?.length;
  const postedToEvoCount = filterState.selectedFilterOptions.length===0||filterState.selectedInvoicingPartyOptions===0?props?.invoiceData?.filter(invoice => invoice.status === INVOICE_STATUS.postedToEVO).length:filteredData.filter(invoice => invoice.status === INVOICE_STATUS.postedToEVO)?.length;
  const paidCount = filterState.selectedFilterOptions.length===0||filterState.selectedInvoicingPartyOptions===0?props?.invoiceData?.filter(invoice => invoice.status === INVOICE_STATUS.paid).length:filteredData.filter(invoice => invoice.status === INVOICE_STATUS.paid)?.length;
  const dueCount = filterState.selectedFilterOptions.length===0||filterState.selectedInvoicingPartyOptions===0?props?.invoiceData?.filter(invoice => invoice.status === INVOICE_STATUS.postedToEVO || invoice.status === INVOICE_STATUS.clientApproved)?.length:filteredData?.filter(invoice => invoice.status === INVOICE_STATUS.postedToEVO || invoice.status === INVOICE_STATUS.clientApproved)?.length
  const statuses = [
    { id: 'all', label: 'All', count: allcount, className: 'statusAll',color: '#CCCCCC',outline:"#9E9E9E" },
    { id: 'clientApproved', label: 'Client Approved', count: clientApprovedCount,color: '#D5F9FF',infoMessage : "Invoice has been approved by client and can be sent to EVO.",outline:"#89DB89" },
    { id: 'postedToEvo', label: 'Posted to EVO', count: postedToEvoCount, color: '#FFEA96', infoMessage: "Invoice has been approved by client and sent to EVO.",outline:"#F4D55B" },
    { id: 'paid', label: 'Paid', count: paidCount, color: '#ACEDD0', infoMessage: "Invoice has been paid.",outline:"#80D8B0" },
  ];

  const customerStatuses = [
    { id: 'all', label: 'All', count: allcount, className: 'statusAll',color: '#CCCCCC',outline:"#9E9E9E" },
    { id: 'postedToEvo', label: 'Due', count: dueCount, color: '#FFEA96', infoMessage: "Invoice has been approved by client and sent to EVO.",outline:"#F4D55B" },
    { id: 'paid', label: 'Paid', count: paidCount, color: '#ACEDD0', infoMessage: "Invoice has been paid.",outline:"#80D8B0" },
  ];
  const [checkedStatuses, setCheckedStatuses] = useState(statuses.reduce((acc, status) => ({ ...acc, [status.id]: true }), {}));

  // useEffect(() => {
  //   const newFilteredData = props.invoiceData.filter((invoice) => {
  //     const statusFilter = checkedStatuses.all ||
  //       (checkedStatuses.clientApproved && invoice.status === INVOICE_STATUS.clientApproved) ||
  //       (checkedStatuses.postedToEvo && invoice.status === INVOICE_STATUS.postedToEVO) ||
  //       (checkedStatuses.paid && invoice.status === INVOICE_STATUS.paid);

  //     return statusFilter;
  //   });
  //   if(filterState?.selectedInvoicingPartyOptions?.length===0&&filterState?.selectedFilterOptions?.length===0){
  //     setFilteredData(newFilteredData);
  //   }else{
  //     if(filterState?.selectedInvoicingPartyOptions?.length===0&&filterState?.selectedFilterOptions?.length>0){
  //       const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
  //         filterState?.selectedFilterOptions.some(option => option.value === invoice.invoicedPartyName)
  //       );
  //       setFilteredData(newFilteredData);
  //     }else if(filterState?.selectedInvoicingPartyOptions?.length>0&&filterState?.selectedFilterOptions?.length===0){
  //       const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
  //         filterState?.selectedInvoicingPartyOptions?.some(option => option.value === invoice.invoicingPartyName)
  //       );
  //       setFilteredData(newFilteredData);
  //     }else if(filterState?.selectedInvoicingPartyOptions?.length>0&&filterState?.selectedFilterOptions?.length>0){
  //       const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
  //         filterState?.selectedFilterOptions?.some(option => option.value === invoice.invoicedPartyName) &&
  //         filterState.selectedInvoicingPartyOptions.some(option => option.value === invoice.invoicingPartyName))
  //         setFilteredData(newFilteredData);
  //     }
  //     // handleSort(isSortedAscending);
  //     const sortedData = [...filteredData].sort((a, b) => {
  //       const dateA = new Date(a.documentDate);
  //       const dateB = new Date(b.documentDate);
  //       return isSortedAscending ? dateA - dateB : dateB - dateA;
  //     });
  //     setFilteredData(sortedData);
  //   }
  // }, [checkedStatuses,props.invoiceData,isSortedAscending]);

  useEffect(() => {
    const newFilteredData = props.invoiceData.filter((invoice) => {
      const statusFilter = checkedStatuses.all ||
        (checkedStatuses.clientApproved && invoice.status === INVOICE_STATUS.clientApproved) ||
        (checkedStatuses.postedToEvo && invoice.status === INVOICE_STATUS.postedToEVO) ||
        (checkedStatuses.paid && invoice.status === INVOICE_STATUS.paid);
  
      return statusFilter;
    });
  
    let filteredData = newFilteredData;
  
    if (filterState?.selectedInvoicingPartyOptions?.length > 0 || filterState?.selectedFilterOptions?.length > 0) {
      filteredData = newFilteredData.filter(invoice => {
        const invoicingPartyMatch = filterState?.selectedInvoicingPartyOptions?.length === 0 ||
          filterState.selectedInvoicingPartyOptions.some(option => option.value === invoice.invoicingPartyName);
        const invoicedPartyMatch = filterState?.selectedFilterOptions?.length === 0 ||
          filterState.selectedFilterOptions.some(option => option.value === invoice.invoicedPartyName);
  
        return invoicingPartyMatch && invoicedPartyMatch;
      });
    }
  
    const sortedData = filteredData.sort((a, b) => {
      const dateA = new Date(a.documentDate);
      const dateB = new Date(b.documentDate);
      return isSortedAscending ? dateA - dateB : dateB - dateA;
    });
  
    setFilteredData(sortedData);
  }, [checkedStatuses, props.invoiceData, filterState, isSortedAscending]);

  const handleStatusChange = (newCheckedStatuses) => {
    setCheckedStatuses(newCheckedStatuses);
  };

const handleFilterApply = (selectedOptions,lastremoved) => {
  setFilterState(prevState => ({
    ...prevState,
    selectedFilterOptions: selectedOptions
  }));
  if (filterState.selectedInvoicingPartyOptions.length > 0) {
    // if(lastremoved){
    //   const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
    //     selectedOptions.some(option => option.value === invoice.invoicedPartyName)
    //   );
    //   setFilteredData(newFilteredData);
    // }else{
      const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
        selectedOptions.some(option => option.value === invoice.invoicedPartyName) &&
        filterState.selectedInvoicingPartyOptions.some(option => option.value === invoice.invoicingPartyName)
      );
      setFilteredData(newFilteredData);
    // }
  } else {
    const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
      selectedOptions.some(option => option.value === invoice.invoicedPartyName)
    );
    setFilteredData(newFilteredData);
  }
};

const handleInvoicingFilterApply = (options,lastremoved) => {
  setFilterState(prevState => ({
    ...prevState,
    selectedInvoicingPartyOptions: options
  }));
  if (filterState.selectedFilterOptions.length > 0) {
    // if(lastremoved){
    //   const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
    //     options.some(option => option.value === invoice.invoicingPartyName)
    //   );
    //   setFilteredData(newFilteredData);
    // }else{
      const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
        options.some(option => option.value === invoice.invoicingPartyName) &&
        filterState.selectedFilterOptions.some(option => option.value === invoice.invoicedPartyName)
      );
      setFilteredData(newFilteredData);
    // }
  } else {
    const newFilteredData = filterInvoices(props.invoiceData, checkedStatuses).filter(invoice =>
      options.some(option => option.value === invoice.invoicingPartyName)
    );
    setFilteredData(newFilteredData);
  }
};

const handleFilterReset = () => {
  const data = filterInvoices(props.invoiceData, checkedStatuses)
  setFilteredData(data);
  setFilterState(prevState => ({
    ...prevState,
    selectedFilterOptions: [],
  }));
};

const handleInvoicingFilterReset = () => {
  const data = filterInvoices(props.invoiceData, checkedStatuses)
  setFilteredData(data);
  setFilterState(prevState => ({
    ...prevState,
    selectedInvoicingPartyOptions: [],
  }));
};

const resetappliedFilter = () =>{
  handleFilterReset();
  handleInvoicingFilterReset();
}

const handleSort = (isSortedAscending) => {
  const sortedData = [...filteredData].sort((a, b) => {
    const dateA = new Date(a.documentDate);
    const dateB = new Date(b.documentDate);
    return isSortedAscending ? dateA - dateB : dateB - dateA;
  });
  setFilteredData(sortedData);
};

  const headers = props?.myRights?.userType === USER_TYPE.INTERNAL
    ? [<div className={`${styles.organisationName} ${styles.minwidths}`} key="invoiceNumber">Invoice Number</div>,
        <div className={styles.organisationName} key="invoicedPartyName"><div className={styles.orgname} style={{position:"relative"}}>Invoiced Party</div>
          <img className={styles.filter} src={IMAGES.filtericon}  onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpen: true }))} alt="Filter"/>
          {filterState.isFilterPopupOpen && (
           <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpen} options={filterInvoices(props.invoiceData, checkedStatuses)?.map(invoice => ({ value: invoice.invoicedPartyName, label: invoice.invoicedPartyName }))} onApply={handleFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpen: false }))} label={"Invoiced Party"} initialSelectedOptions={filterState.selectedFilterOptions} onReset={handleFilterReset}/>
          </div>)}
        </div>,
        <div className={styles.organisationName} key="invoicingPartyName"><div className={styles.orgname}>Invoicing Party</div>
          <img className={styles.filter} src={IMAGES.filtericon}  onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenInvoicingParty: true }))} alt="Filter"/>
          {filterState.isFilterPopupOpenInvoicingParty && (
            <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpenInvoicingParty} options={filterInvoices(props.invoiceData, checkedStatuses)?.map(invoice => ({ value: invoice.invoicingPartyName, label: invoice.invoicingPartyName }))} onApply={handleInvoicingFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenInvoicingParty: false }))} label={"Invoicing Party"} initialSelectedOptions={filterState.selectedInvoicingPartyOptions} onReset={handleInvoicingFilterReset}/>
          </div>)}
        </div>,
        "Invoice Period","Amount","Invoice Type",<div className={styles.documentDate} key="documentDate"><div className={styles.document}>Invoice Date</div><img className={`${styles.sorting} ${isSortedAscending?styles.sortasc:""}`} src={IMAGES.sorticon} onClick={()=>{handleSort(isSortedAscending);setIsSortedAscending(!isSortedAscending)}} alt="sort"/></div>,"Invoice Due Date"]
    : [<div className={styles.organisationName} key="invoiceNumber">Invoice Number</div>,<div className={styles.organisationName} key="invoicedPartyName"><div className={styles.orgname}>Invoiced Party</div>
      <img className={styles.filter} src={IMAGES.filtericon}  onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpen: true }))} alt="Filter"/>
    </div>,
    <div className={styles.organisationName} key="invoicingPartyName"><div className={styles.orgname}>Invoicing Party</div>
      <img className={styles.filter} src={IMAGES.filtericon}  onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenInvoicingParty: true }))} alt="Filter"/>
    </div>,"Invoice Period","Amount","Invoice Type",<div className={styles.organisationName} key="documentDate"><div className={styles.orgname}>Invoice Date</div><img className={`${styles.sorting} ${isSortedAscending?styles.sortasc:""}`} src={IMAGES.sorticon} onClick={()=>{handleSort(isSortedAscending);setIsSortedAscending(!isSortedAscending)}} alt="sort"/></div>,"Invoice Due Date"];

  const getStatusLegendColorClass = (status, userType) => {
    switch (true) {
      case status === INVOICE_STATUS.paid && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
        return "statusLegendsIconGreen";
      case status === INVOICE_STATUS.clientApproved && userType === USER_TYPE.INTERNAL:
        return "statusLegendsIconBlue";
      case status === INVOICE_STATUS.postedToEVO && userType === USER_TYPE.INTERNAL:
        return "statusLegendsIconAmber";
      case status === INVOICE_STATUS.clientApproved && userType === USER_TYPE.CUSTOMER:
        return "statusLegendsIconAmber";
      case status === INVOICE_STATUS.postedToEVO && userType === USER_TYPE.CUSTOMER:
        return "statusLegendsIconAmber";
      default:
        return status;
    }
  };

  const currentUserType = props?.myRights?.userType;
  const mappedData = filteredData.map((invoice) => {
    const currencySym = currencySymbol[invoice?.currency]? currencySymbol[invoice?.currency]: invoice?.currency;
    const colorClass = getStatusLegendColorClass(invoice.status, currentUserType);
    const tooltipText = getTooltipText(invoice?.documentType);

    const download = (invoice) =>{
      setDisabledInvoices((prev) => ({ ...prev, [invoice.fincaID]: true }));
      fetchInvoiceDetails(invoice.invoicePeriod, invoice.fincaID)
      .then((result)=>{
        if(result?.success){
          downloadPDF(result?.data?.fileAsBase64,result?.data?.fileName)
          setDisabledInvoices((prev) => ({ ...prev, [invoice.fincaID]: false }));
        }else{
          alert("Error while downloding file!");
          setDisabledInvoices((prev) => ({ ...prev, [invoice.fincaID]: false }));
        }
      }).catch((error)=>{
        alert("Error while downloding file!")
        setDisabledInvoices((prev) => ({ ...prev, [invoice.fincaID]: false }));
      })
    }
    
    return {
      invoiceNumber: (
        <span className={`${styles.customDataContent}`}>
          {currentUserType===USER_TYPE.INTERNAL?<SecondaryTooltip text={colorClass==="statusLegendsIconGreen"?"Paid":colorClass==="statusLegendsIconAmber"?"Posted To EVO":"Client Approved"} positions={['bottom', 'left']}><span className={`${styles.statusLegendsIcon} ${styles[colorClass]} ${styles.spanSpaceing}`} /></SecondaryTooltip>:
          <SecondaryTooltip text={colorClass==="statusLegendsIconGreen"?"Paid":"Due"} positions={['bottom', 'left']}><span className={`${styles.statusLegendsIcon} ${styles[colorClass]} ${styles.spanSpaceing}`} /></SecondaryTooltip>}
          <span className={`${styles.invoiceId}`} onClick={() => handleInvoiceIdClick(invoice)}><SecondaryTooltip text={invoice?.documentReference} positions={['bottom', 'right']}>{invoice?.documentReference}</SecondaryTooltip></span>
        </span>
      ),
      invoicedPartyName: (
        <span className={`${styles.customDataContent}`}>
          <span className={`${styles.organame} ${styles.texttruncate}`}><SecondaryTooltip text={invoice?.invoicedPartyName} positions={['bottom', 'right']}>{invoice?.invoicedPartyName}</SecondaryTooltip></span>
        </span>
      ),
      invoicingPartyName:(
        <span className={`${styles.customDataContent}`}>
          <span className={`${styles.organame} ${styles.texttruncate}`}><SecondaryTooltip text={invoice?.invoicingPartyName} positions={['bottom', 'right']} >{invoice?.invoicingPartyName}</SecondaryTooltip></span>
        </span>
      ),
      "Invoice Period": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={moment(invoice?.invoicePeriod).format('MMM YYYY')} positions={['bottom', 'right']}>{moment(invoice?.invoicePeriod).format('MMM YYYY')}</SecondaryTooltip></span>,
      Amount: (
        <span className={`${styles.customDataContent}`}> <b><SecondaryTooltip text={`${currencySym} ${invoice?.documentGrossAmount}`} positions={['bottom', 'right']}>{currencySym} {invoice?.documentGrossAmount}</SecondaryTooltip></b></span>
      ),
      "Invoice Type": <span className={`${styles.customDataContents} ${styles.tooltip}`}><SecondaryTooltip text={tooltipText} positions={['bottom', 'right']}>{tooltipText}</SecondaryTooltip></span>,
      documentDate: <span className={`${styles.customDataContent} ${styles.tooltip}`}><SecondaryTooltip text={moment(invoice?.documentDate).format("DD MMM YYYY")} positions={['bottom', 'right']}>{moment(invoice?.documentDate).format("DD MMM YYYY")}</SecondaryTooltip></span>,
      "Invoice Due Date": <div className={styles.invoicedue}><span className={`${styles.customDataContent}`}>{invoice?.documentDueDate?<SecondaryTooltip text={invoice?.documentDueDate} positions={['bottom', 'right']}>{moment(invoice?.documentDueDate).format("DD MMM YYYY")}</SecondaryTooltip>:"---"}</span>
      {!disabledInvoices[invoice.fincaID]?<img className={styles.downloadlogo} src={IMAGES.downloadlogo} alt="Filter" ref={filterIconRef} onClick={() => download(invoice)}/>:<img className={styles.disabledownloadlogo} src={IMAGES.downloadlogo} alt="Filter" ref={filterIconRef}/>}</div>
    };
  });

  const noOfMonths = 6
  const getPreviousMonthsList = (noOfMonths) => {
    const months = [];
    const currentDate = new Date();
    for (let i = 1; i <= noOfMonths; i++) {
      const month = new Date(
        Date.UTC(currentDate.getFullYear(), currentDate.getMonth() - i)
      );
      const label =
        month.toLocaleString("default", { month: "long" }) +
        " " +
        month.getFullYear();
      const value = month.toISOString().slice(0, 7);
      months.push({ label, value });
    }
    return months;
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  let query = useQuery()

  const getPrevMonthsList = getPreviousMonthsList(noOfMonths)

  const handleDateSelect = (value) => {
    setIsSortedAscending(false)
    setFilterState(prevState => ({...prevState,selectedFilterOptions: [],selectedInvoicingPartyOptions:[]}));
    if (value) {
      props.clearInvoiceList();
      const values = Array.isArray(value) ? value : [value];
      values.sort((a, b) => b.localeCompare(a));
      if(values.length>1 && Array.isArray(value)){
        setIsLoading(true)
        setLastGenratedInvoice({lastinvoice:false,months:false,custom:true})
      }else if(values.length==1&& Array.isArray(value)){
        setLastGenratedInvoice({lastinvoice:false,months:false,custom:true})
      }else if(typeof value==="string"){
        setLastGenratedInvoice({lastinvoice:false,months:true,custom:false})
      }
      setProgress(0);

      values.reduce((promiseChain, currentValue, index) => {
        return promiseChain.then(() => {
          return props.getAllInvoicesList(currentValue).then(() => {
            setProgress(((index + 1) / values.length) * 100);
          });
        });
      }, Promise.resolve()).then(() => {
        setIsLoading(false);
      });
    }
  };
  
  const handleInvoiceIdClick = (invoiceRecord) =>{
    setSelectedInvoice(invoiceRecord)
    setIsSidebarOpen(true)
  }

const resetsecondaryFilter = () => {
};

  const closeSidebar=()=>{
    setIsSidebarOpen(false)
    setSelectedInvoice(null)
  }

  const invoiceDetailProps = {
    invoiceRecord:selectedInvoice,
    closeSidebar: closeSidebar
  }

  const getPreviousMonth = (date, monthsBack) => {
    const d = new Date(date);
    d.setMonth(d.getMonth() - monthsBack);
    return d.toISOString().slice(0, 7);
  };

  const fetchInvoices = async () => {
    const currentDate = new Date();
    const monthsToCheck = [];
    for (let i = 0; i <= 3; i++) {
      monthsToCheck.push(getPreviousMonth(currentDate, i));
    }
  
    for (const month of monthsToCheck) {
      await props.getAllInvoicesList(month);
    }
  };

  const fetchLastgenInvoice = async () => {
    setLastGenratedInvoice({lastinvoice:true,months:false,custom:false});
    if (props.invoiceData.length !== 0) {
      props.clearInvoiceList();
    }
    await fetchInvoices();
  };

  useEffect(() => {
    if(CAN(Action.LIST_MISSING_FINCAID,Resource.FINCAINVOICE)){
      props.getMissingFincaCustomers()
    }
    fetchInvoices();
    return () => {
      props.clearInvoiceList();
    };
  }, []);

  const CircularProgressBar = ({ progress, }) => {
    const radius = 50;
    const circumference = 2 * Math.PI * radius;
    // const offset = circumference - (progress / 100) * circumference;
  
    return (
      <div className={`${currentUserType===USER_TYPE.INTERNAL?styles.progressContainer:styles.custprogressContainer}`}>
        <svg className={styles.progressRing} width="120" height="120">
          <circle
            className={styles.progressRingCircle}
            stroke="url(#gradient)"
            // stroke="#E60000"
            strokeWidth="10"
            fill="transparent"
            r={radius}
            cx="60"
            cy="60"
            style={{ strokeDasharray: circumference }}
            strokeLinecap="round"
          />
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: '#E60000', stopOpacity: 1 }} />
              <stop offset="63.24%" style={{ stopColor: '#E60000', stopOpacity: 0.3 }} />
              <stop offset="100%" style={{ stopColor: '#E60000', stopOpacity: 0.5 }} />
            </linearGradient>
            </defs>
        </svg>
          <>
            {isLoading && (<div className={styles.progressText}>{`${Math.round(progress)}%`}</div>)}
            <div className={styles.loadingText}>Invoices Loading...</div>
          </>
      </div>
    );
  };

  return (
    <Container>
      <Banner
        image={IMAGES.banner}
        text={`${
          query.get("customerId") ? "My Invoices" : "Invoices"
        }`}
      ></Banner>
      <ChildContainer>
        {
          <>
            <div className={styles.fincainfo}><img className={styles.circleinfo} src={IMAGES.infoCircle}/>{currentUserType===USER_TYPE.CUSTOMER?"This section will present a list of all invoices generated in the past months. User can view the invoice details and download them by clicking on the specific invoice.":"This section will present a list of invoices for customers onboarded to the IPXplore portal, generated in the past months. Users can view the invoice details and download them by clicking on the specific invoice."}</div>
            {currentUserType===USER_TYPE.INTERNAL&&(
              <SectionGuard canAccess={CAN(Action.LIST_MISSING_FINCAID,Resource.FINCAINVOICE)}>
                <MissingFincalist missingFincaCustomers={props?.missingFincaCustomers}/>
              </SectionGuard>
            )}
            {((props?.totalCount===props?.missingFincaCustomers.length&&currentUserType===USER_TYPE.INTERNAL&&!props.loadingMissingFinca)||(currentUserType===USER_TYPE.CUSTOMER&&!hasValidFincaTadigCode(props?.myRights?.customerUserMetaInfo)))?
            <div className={styles.container}>
              <div className={styles.emptydivmissingFinca}>
                <div className={styles.noinvoice}>
                  <img className={styles.noinvoiceimage} src={IMAGES.noinvoice} alt="Invoice"/>
                  <div className={styles.noinvoicetext}>{(!hasValidFincaTadigCode(props?.myRights?.customerUserMetaInfo)&&currentUserType===USER_TYPE.CUSTOMER)?`We are currently unable to retrieve your invoices due to a missing configuration. Please contact your Account Manager for further details.`:`No customers are currently mapped with FinCA Code. Please map the FinCA code to see the invoice for the respective customer.`}</div>
                </div>
              </div>
            </div>:
            <>
            {/* {(currentUserType===USER_TYPE.CUSTOMER&&props?.myRights?.customerUserMetaInfo?.fincaTadigCode)} */}
            <div className={styles.container}>
            <div className={styles.childContainer}>
                  <div className={styles.subHeader}>{currentUserType===USER_TYPE.CUSTOMER?`${props?.myRights?.customerUserMetaInfo?.customerName} - `:""}Selected Month Invoices</div>
              <div className={styles.compWrapper}>
                {lastGenratedInvoice.lastinvoice?<div className={`${lastGenratedInvoice.lastinvoice?styles.sublabel:styles.disablelabel}`}>Last Generated Invoices</div>:
                <div className={`${lastGenratedInvoice.lastinvoice?styles.sublabel:styles.disablelabel}`} onClick={fetchLastgenInvoice}>Last Generated Invoices</div>}
                <div className={styles.monthparent}>
                <SelectField
                  name="month_selector"
                  ref={selectRef}
                  options={getPrevMonthsList}
                  onChange={(label, value) => {
                    handleDateSelect(value)
                  }}
                  placeholder="Month: No Month Selected"
                  isMandatory={true}
                  handleCustomDateSelect={handleDateSelect}
                  lastGenratedInvoice={lastGenratedInvoice}
                  selectRef={selectRef}
                  setLastGenratedInvoice={setLastGenratedInvoice}
                />
                </div>
                <div className={styles.custominput}>
                <TextField
                 name="customInput"
                 type="text"
                 placeholder="Custom"
                 onChange={handleTextFieldFocus}
                 lastGenratedInvoice={lastGenratedInvoice}
                 handleCustomDateSelect={handleDateSelect}
                 setLastGenratedInvoice={setLastGenratedInvoice}
                />
                </div>
              </div>
                </div>
            {isLoading ? (
            <div className={styles.progressWrapper}>
              <CircularProgressBar progress={progress} isLoading={isLoading}/>
            </div>
          ) : props.isLoading && props.invoiceData.length == 0? (
              <div className={styles.progressWrapper}>
              <CircularProgressBar progress={progress} />
            </div>
             ) : props.invoiceData.length == 0 ? (
              <>
              <div className={`${currentUserType===USER_TYPE.INTERNAL?styles.emptydiv:styles.custemptydiv}`}>
                <div className={styles.noinvoice}>
                  <img className={styles.noinvoiceimage} src={IMAGES.noinvoice} alt="Invoice"/>
                  <div className={styles.noinvoicetext}>{lastGenratedInvoice.lastinvoice?<span>You don't have invoices for last 3 months, use custom <span className={styles.dropdownopen}onClick={() => {selectRef.current.focus();selectRef.current.openMenu();}}>Month</span> to get data.</span>:""}</div>
                </div>
              </div>
              </>
             ) : (
              <div className={styles.subContainer}>
                 <Status statuses={currentUserType===USER_TYPE.INTERNAL?statuses:customerStatuses} onStatusChange={handleStatusChange} infoImage={IMAGES.infoCircle} currentUserType={currentUserType} onResetfilter={resetsecondaryFilter} />
                 {(filterState.selectedFilterOptions.length > 0 || filterState.selectedInvoicingPartyOptions.length>0) && (<AppliedFilter selectedFilterOptions={filterState.selectedFilterOptions} selectedInvoicingPartyOptions={filterState.selectedInvoicingPartyOptions} onReset={resetappliedFilter}setSelectedFilterOptions={setFilterState} handleFilterApply={handleFilterApply} handleInvoicingFilterApply={handleInvoicingFilterApply} firstFilterLabel={"Invoiced Party"} secondFilterLabel={"Invoicing Party"}/>)}
                  <div className={styles.wrapper}>
                    <Table headers={headers} data={mappedData} height={`${mappedData?.length<8&&(filterState?.isFilterPopupOpen||filterState?.isFilterPopupOpenInvoicingParty)?"480px":"auto"}`} width="100%" columnConfig={columnConfig} nodata={mappedData?.length===0?"No invoice found for the selected filters.":""}/>
                  </div>
              </div>
             )}
              {isSidebarOpen && (<InvoiceDetails invoiceDetailProps={invoiceDetailProps}/>)}
            </div>
            </>
            }
          </>
        }
      </ChildContainer>
    </Container>
  )
}

export default FincaInvoices;
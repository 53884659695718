import { useState } from 'react';
import { Popover,ArrowContainer } from 'react-tiny-popover'
import styles from './secondaryToolTip.module.css';
import IMAGES from '../../assets/images/images';

const SecondaryTooltip = ({children,text,copy, positions,backgroundclr,status})=>{
  let [isPopoverOpen, setIsPopoverOpen] = useState(false);
  let [isCopied, setIsCopied] = useState(false);
  let [timeoutId, setTimeoutId] = useState(null);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const handleMouseOver = () => {
    setIsPopoverOpen(true);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const handleMouseLeave = () => {
    if (copy) {
      const id = setTimeout(() => {
        setIsPopoverOpen(false);
        setIsCopied(false);
      }, 800);
      setTimeoutId(id);
    } else {
      setIsPopoverOpen(false);
      setIsCopied(false); 
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      // positions={['bottom', 'right']} // preferred positions by priority
      positions={positions}
      content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#ffffff'}
          arrowSize={10}
            className='popover-arrow-container'
            arrowClassName={`popover-arrow  ${styles.popoversecondary} ${styles[status]}`}
        >
          <span
            className={styles.tooltipBody}
            onClick={(e) => e.stopPropagation()}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            style={{background:`${backgroundclr}`}}
          >
            {text}
            {copy && (
              <span className={styles.copyButton} onClick={handleCopy}>
                <img
                  className={styles.saveImg}
                  src={isCopied ? IMAGES.success : IMAGES.copy}
                  alt="copy button"
                />
              </span>
            )}
          </span>
        </ArrowContainer>
      )}
    >
      <span 
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
      >
        {children}
      </span>
    </Popover>
  );
}

export default SecondaryTooltip;
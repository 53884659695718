
export const MODULE_LABELS_CUSTOMER = {
    DEMANDS_MODULE: 'My Demand',
    INCIDENT: 'My Tickets',
    LIVE_SERVICES:'Live Services',
    SERVICE_REQUEST:'Service Requests',
    FINCA_INVOICE:'My Invoices',
    CUSTOMER_USERS:'User Management',
    CUSTOMER_ONBOARDING_REQUEST:'User Management',
    VF_INTERNAL_USERS:'User Management',
  };
  export const MODULE_LABELS_INTERNAL = {
    DEMANDS_MODULE: 'Demand',
    INCIDENT: 'Tickets',
    LIVE_SERVICES:'Live Services',
    SERVICE_REQUEST:'Service Requests',
    FINCA_INVOICE:'Invoices',
    CUSTOMER_USERS:'User Management',
    CUSTOMER_ONBOARDING_REQUEST:'User Management',
    VF_INTERNAL_USERS:'User Management',
  };

  export const EVENT_CONSTANTS = {
    demand_raised: 'New Demand Raised',
    comment_added: 'Comment Added',
    status_changed: 'Status Changed',
    onboarding_request_raised: 'Onboarding Request Raised',
    onboarding_request_comment_added: 'Comment Added',
    onboarding_request_status_changed: 'Status Changed',
  }
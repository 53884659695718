import {REMIND_ME} from "../actionTypes"
import { remindMe } from "../../../api/notification/remindeme.service"

const request = () => ({ type: REMIND_ME.REQUEST })
const failure = (error) => ({ type: REMIND_ME.FAILURE, error })
const success = () => ({ type: REMIND_ME.SUCCESS })

export const RemindMe = (payload) => {
  return (dispatch, getState) => {
    dispatch(request());
    return remindMe(payload)
      .then((res) => {
        dispatch(success(res));
        return Promise.resolve({res:res,status:true});

      })
      .catch((error) => {
        dispatch(failure(error?.message));
        return Promise.resolve({error:error?.message,status:false});
      })
  }
}
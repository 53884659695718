export const isString = (value) => { // CUP - 489 Added this method to check value is string or object
    if (typeof value === 'string') {
        return true;
    } else {
        return false;
    }
}

export const capitalizeWords = (str) => {
    if (!str) return '';
    return str.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
  };

export const allCount=(count)=> {return Object?.values(count)?.reduce((acc, value) => acc + value, 0);}
export const checkAllFalse = (statusObj) => {return Object.values(statusObj).every(value => value === false);}
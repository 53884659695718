import { FETCH_SERVICES_LIST, DOWNLOAD_SERVICES_LIST } from "../actionTypes";
import { fetchServicesList, downloadServiceList } from "../../../api/newService/servicesList.service";

const servicesListRequest = ()=>({type: FETCH_SERVICES_LIST.REQUEST})
const servicesListFailure = (error)=>({type: FETCH_SERVICES_LIST.FAILURE, error})
const servicesListSuccess = (obj)=>({type: FETCH_SERVICES_LIST.SUCCESS, obj})

const downloadServicesListRequest = ()=>({type: DOWNLOAD_SERVICES_LIST.REQUEST})
const downloadServicesListFailure = (error)=>({type: DOWNLOAD_SERVICES_LIST.FAILURE, error})
const downloadServicesListSuccess = (obj)=>({type: DOWNLOAD_SERVICES_LIST.SUCCESS, obj})

export const getServicesList = (filter, searchKey,next=null,status,customer,serviceType,orderBy)=>{
    return (dispatch, getState)=>{        
        dispatch(servicesListRequest())
        return fetchServicesList(filter, searchKey,next,status,customer,serviceType,orderBy)
        .then(response => {                  
            let obj={
                'count':response.statusCount,
                'data':response.services,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(servicesListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(servicesListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}

export const downloadServicesList = (filter, searchKey,status,customer,serviceType,orderBy)=>{
    return (dispatch, getState)=>{        
        dispatch(downloadServicesListRequest())
        return downloadServiceList(filter, searchKey,status,customer,serviceType,orderBy)
        .then(response => {                  
            let obj={
                'downloadedData':response.services               
            }    
            dispatch(downloadServicesListSuccess(obj))
            return Promise.resolve(obj)
        })
        .catch(error => {
            dispatch(downloadServicesListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
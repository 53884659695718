import React, { useEffect,useState } from "react";
import styles from "./myIncidentViewer.module.css";
import IMAGES from "../../../../assets/images/images";
import { useNavigate } from "react-router-dom";
import AddComment from "../../addComment/addComment.container";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../components/loader/Loader";
import { incidentColumnConfig, STATUS_ENUM } from "../../../../utils/constants/incidentConstants";
import moment from 'moment';
import { formatDate, futureDate } from "../../../../utils/arrayUtils";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import Table from "../../../../components/table/Table"
import SecondaryTooltip from "../../../../components/secondaryToolTip/secondaryToolTip";
import { allCount, checkAllFalse } from "../../../../utils/utils";
import Status from "../../../../components/status/Status";

const MyIncidentViewer = (props) => {
  const navigate = useNavigate();
  const data = props.incidentList.data;
  const statuses = [
    { id: 'all', label: 'All', count: allCount(props?.statusCount), className: 'statusAll',color: '#CCCCCC',outline:"#9E9E9E" },
    { id: 'new', label: 'New', count: props?.statusCount?.new?props?.statusCount?.new:0,color: '#FFF1BB',infoMessage : "New",outline:"#F4D55B" },
    { id: 'in_progress', label: 'In Progress', count: props?.statusCount?.in_progress?props?.statusCount?.in_progress:0, color: '#FFE1AB', infoMessage: "In Progress",outline:"#F4B035" },
    { id: 'need_more_information', label: 'Need More Information', count: props?.statusCount?.need_more_information?props?.statusCount?.need_more_information:0, color: '#D5F9FF', infoMessage: "Need More Information",outline:"#89DBE9" },
    { id: 'customer_feedback_awaited', label: 'Customer Feedback Awaited', count: props?.statusCount?.customer_feedback_awaited?props?.statusCount?.customer_feedback_awaited:0, color: '#EEECFF', infoMessage: "Customer Feedback Awaited",outline:"#9188E0" },
    { id: 'resolved', label: 'Resolved', count: props?.statusCount?.resolved?props?.statusCount?.resolved:0, color: '#C7FFE6', infoMessage: "Resolved",outline:"#80D8B0" },
    { id: 'closed', label: 'Closed', count: props?.statusCount?.closed?props?.statusCount?.closed:0, color: '#E8E8E8', infoMessage: "Closed",outline:"#C2C2C2" },
  ];
  const [checkedStatuses, setCheckedStatuses] = useState(statuses.reduce((acc, status) => ({ ...acc, [status.id]: true }), {}));
  const [sortOrder, setSortOrder] = useState("desc");
  const handleStatusChange = (newCheckedStatuses) => {
    setCheckedStatuses(newCheckedStatuses);
  };
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "desc" ? "asc" : "desc"));
  };
  const resetsecondaryFilter = () => {};
  useEffect(() => {
    let statusFilter = Object.keys(checkedStatuses)?.filter((key) => checkedStatuses[key]).join(",")
    if(!checkAllFalse(checkedStatuses)){
      props.getIncidentList(null,statusFilter,sortOrder);
    }
  }, [checkedStatuses,sortOrder]);

  const fetchData = () => {
    if (props.incidentList.next) {
      let statusFilter = Object.keys(checkedStatuses)?.filter((key) => checkedStatuses[key]).join(",")
      props.getIncidentList(props.incidentList.next,statusFilter,sortOrder);
    }
  };

  const gotoDetail = (requestId,notificationId) => {
    navigate(`/incidentDetail/${requestId}`);
    // const payload = {
    //   read: true,
    // };
    // if(notificationId){
    //   props.ReadNotification(payload, notificationId);
    // }
  }
  
  const getStatusLegendColorClass = (status) => {
    switch (true) {
      case status === STATUS_ENUM.NEW:
        return "new";
      case status === STATUS_ENUM.PENDING:
        return "pending";
      case status === STATUS_ENUM.IN_PROGRESS:
        return "in_progress";
      case status === STATUS_ENUM.ASSIGNED:
        return "assigned";
      case status === STATUS_ENUM.RESOLVED:
        return "resolved";
      case status === STATUS_ENUM.CLOSED:
        return "closed";
      case status === STATUS_ENUM.NEED_MORE_INFORMATION:
        return "need_more_information";
      case status === STATUS_ENUM.CUSTOMER_FEEDBACK_AWAITED:
        return "customer_feedback_awaited";
      default:
        return status;
    }
  };

  const headers = props?.myRights?.userType===USER_TYPE.INTERNAL?[
    <div className={styles.organisationName} key="id"><div className={styles.orgname}>Incident ID</div></div>,
    <div className={styles.organisationName} key="oneItsmId"><div className={styles.orgname}>OneITSM ID</div></div>,
    <div className={styles.organisationName} key="customerName"><div className={styles.orgname}>Customer Name</div></div>,
    <div className={styles.organisationName} key="serviceType"><div className={styles.orgname}>Service Type</div></div>, 
    <div className={styles.organisationName} key="faultType"><div className={styles.orgname}>Fault Type</div></div>,
    <div className={styles.organisationName} key="createdOn"><div className={styles.orgname}>Created On</div></div>,
    <div className={styles.organisationName} key="lastUpdatedOn"><div className={styles.orgname}>Last Updated On</div><img className={`${styles.sorting} ${sortOrder==='desc'?styles.desc:styles.asc}`} src={IMAGES.sorticon} onClick={toggleSortOrder} alt="sort"/></div>,
  ]:[
    <div className={styles.organisationName} key="id"><div className={styles.orgname}>Incident ID</div></div>,
    <div className={styles.organisationName} key="oneItsmId"><div className={styles.orgname}>OneITSM ID</div></div>,
    <div className={styles.organisationName} key="serviceType"><div className={styles.orgname}>Service Type</div></div>, 
    <div className={styles.organisationName} key="faultType"><div className={styles.orgname}>Fault Type</div></div>,
    <div className={styles.organisationName} key="createdOn"><div className={styles.orgname}>Created On</div></div>,
    <div className={styles.organisationName} key="lastUpdatedOn"><div className={styles.orgname}>Last Updated On</div><img className={`${styles.sorting} ${sortOrder==='desc'?styles.desc:styles.asc}`} src={IMAGES.sorticon} onClick={toggleSortOrder} alt="sort"/></div>,
  ];
  
  const mappedData = data?.map((item) => {
    const colorClass = getStatusLegendColorClass(item?.status?.value);
    const match = props.notificationdata?.find(row => row.detailsId === item.id);
    const highlightClass = match ? styles.highlightedRow : '';
    return {
      id: <span className={`${styles.orglgname} ${styles.posrelative}`}><span className={`${highlightClass}`}></span><SecondaryTooltip text={colorClass?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char?.toUpperCase())} positions={['bottom', 'left']}><span className={`${styles.statusLegendsIcon} ${styles[colorClass]} ${match ? styles.margingleft:""}`} /></SecondaryTooltip><SecondaryTooltip text={item?.incidentId} positions={['bottom', 'right']} ><span onClick={()=>gotoDetail(item?.id,match?.id)}>{item?.incidentId}</span></SecondaryTooltip></span>,
      customerName: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={item?.createdBy?.email} copy={true} positions={['bottom', 'right']}>{item?.createdBy?.name}</SecondaryTooltip></span>,
      oneItsmId: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={item?.ticketIdServiceDesk?item?.ticketIdServiceDesk:"---"} positions={['bottom', 'right']}>{item?.ticketIdServiceDesk?item?.ticketIdServiceDesk:"---"}</SecondaryTooltip></span>,
      serviceType: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={item?.incidentServiceType?.label} positions={['bottom', 'right']}>{item?.incidentServiceType?.label}</SecondaryTooltip></span>,
      faultType: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={item?.incidentFaultType?.label} positions={['bottom', 'right']}>{item?.incidentFaultType?.label}</SecondaryTooltip></span>,
      createdOn: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={formatDate(item?.createdAt)} positions={['bottom', 'right']}>{formatDate(item?.createdAt)}</SecondaryTooltip></span>,
      lastUpdatedOn: <div className={styles.modifiedonwrapper}><span className={`${styles.customDataContent}`}><SecondaryTooltip text={formatDate(item.modifiedAt)} positions={['bottom', 'right']}>{formatDate(item.modifiedAt)}</SecondaryTooltip></span>
        <span className={styles.parentnoti}>
          {props?.myRights?.userType===USER_TYPE.CUSTOMER &&(<>
          {item.status.value===STATUS_ENUM.CUSTOMER_FEEDBACK_AWAITED&&(<SecondaryTooltip text={`Please respond by ${futureDate(item?.modifiedAt)} to accept or reject the ticket resolution.`} positions={['bottom', 'left']} backgroundclr={"#FFEECC"} status={"dueby"}><img className={styles.infocircle} src={IMAGES.infoCircle}/></SecondaryTooltip>)}
          {item.status.value===STATUS_ENUM.NEED_MORE_INFORMATION&&(<SecondaryTooltip text={`Respond by ${futureDate(item?.modifiedAt)}, or the ticket will be auto closed.`} positions={['bottom', 'left']} backgroundclr={"#FFEECC"} status={"dueby"}><img className={styles.infocircle} src={IMAGES.infoCircle}/></SecondaryTooltip>)}</>)}
          <span className={styles.notificationmain}>{item.isServiceDeskCommentReceived && props?.myRights?.userType===USER_TYPE.CUSTOMER &&(<div className=""><span className={styles.commentnotification}></span> <img className={styles.newcomment}src={IMAGES.newcomment} alt="new comment"/></div>)}</span>
        </span></div>};
  });

  return (
    <div className={styles.wrapperchild}>
      {props.incidentList.isLoading&& (
        <Loader />
      )}
        <InfiniteScroll
          dataLength={props.incidentList.data?.length} //This is important field to render the next data
          next={() => {
            if(!checkAllFalse(checkedStatuses)){
              fetchData();
            }
          }}
          hasMore={!checkAllFalse(checkedStatuses) ? props.incidentList.next :null}
          loader={<Loader />}
        >
          <div className={styles.statussection}>
            <Status statuses={statuses} onStatusChange={handleStatusChange} infoImage={IMAGES.infoCircle} onResetfilter={resetsecondaryFilter} />
          </div>
          <Table headers={headers} data={checkAllFalse(checkedStatuses)?[]:mappedData} height="auto" width="100%" columnConfig={incidentColumnConfig} nodata={!props.incidentList.isLoading&&mappedData?.length===0?"No Data.":"No Data."}/>
        </InfiniteScroll>
    </div>
  );
};

export default MyIncidentViewer;

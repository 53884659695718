import { FETCH_EVENT_NOTIFICATION_LIST,READ_EVENT_NOTIFICATION_LIST,NOTIFICATION_OPTION_LIST } from "../actionTypes"
import { fetchEventNotificationList,ReadEventNotificationList,fetchNotificationDropdown } from "../../../api/notification/getEventNotificationList.service"

const notificationRequest = ()=>({type: FETCH_EVENT_NOTIFICATION_LIST.REQUEST})
const notificationFailure = (error)=>({type: FETCH_EVENT_NOTIFICATION_LIST.FAILURE, error})
const notificationSuccess = (obj)=>({type: FETCH_EVENT_NOTIFICATION_LIST.SUCCESS, obj})


export const getEventNotificationList = (next=null,option)=>{

    return (dispatch, getState)=>{        
        dispatch(notificationRequest())
        return fetchEventNotificationList(next,option)
        .then(response => {         
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false
            }    
            dispatch(notificationSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(notificationFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}

const readnotificationRequest = ()=>({type: READ_EVENT_NOTIFICATION_LIST.REQUEST})
const readnotificationFailure = (error)=>({type: READ_EVENT_NOTIFICATION_LIST.FAILURE, error})
const readnotificationSuccess = (obj)=>({type: READ_EVENT_NOTIFICATION_LIST.SUCCESS, obj})

export const ReadNotification = (payload,requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(readnotificationRequest())
        return ReadEventNotificationList(payload,requestId)
        .then(response => {
            dispatch( readnotificationSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(readnotificationFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}

const notificationOptionsRequest = ()=>({type: NOTIFICATION_OPTION_LIST.REQUEST})
const notificationOptionsFailure = (error)=>({type: NOTIFICATION_OPTION_LIST.FAILURE, error})
const notificationOptionsSuccess = (obj)=>({type: NOTIFICATION_OPTION_LIST.SUCCESS, obj})

export const NotificationOptions = (payload,requestId)=>{
    return (dispatch, getState)=>{        
        dispatch(notificationOptionsRequest())
        return fetchNotificationDropdown(payload,requestId)
        .then(response => {
            dispatch( notificationOptionsSuccess(response))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(notificationOptionsFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}
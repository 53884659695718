import React, { useState,useEffect } from "react";
import styles from "./search.module.css";
import searchIcon from "../../assets/images/search.png";

export function Search({ style = "",onButtonClick,searchKeyValue, ...rest }) {
  const [inputValue, setInputValue] = useState("");

  const handleClick = () => {
    onButtonClick(inputValue); // Pass the input value to the parent
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onButtonClick(inputValue); // Pass the input value to the parent on Enter key
    }
  };
  useEffect(() => {
    if (searchKeyValue === "") {
      setInputValue(""); 
    }
  }, [searchKeyValue]);

  return (
    <div className={styles.searchContainer}>
      <input
        className={`${styles.input} ${style}`} 
        type="search"
        placeholder={rest.placeholder}
        value={inputValue}
        onKeyDown={handleKeyDown} // Handle the "Enter" key press
        onChange={(e) => setInputValue(e.target.value)} // Update local state
        {...rest} 
      />
      <img className={styles.imageIcon} src={searchIcon} alt="searchIcon"  onClick={handleClick} />
    </div>
  );
}

// search for Forms only 
export function SearchField({ style = "", ...rest }) {
  return (
    <div className={styles.searchContainerform}>
      <input
        className={`${styles.inputform} ${style}`} 
        type="search"
        placeholder={rest.placeholder}
        value={rest.value}
        onChange={rest.getSearchKeyValue }      
        {...rest} 
      />
    </div>
  );
}
import { FETCH_MODULE_NOTIFICATION } from "../actionTypes"
import { fetchModuleNotification } from "../../../api/notification/getmoduleNotificaton.service"

const requestListRequest = () => ({ type: FETCH_MODULE_NOTIFICATION.REQUEST })
const requestListFailure = (error) => ({type: FETCH_MODULE_NOTIFICATION.FAILURE,error})
const requestListSuccess = (obj) => ({ type: FETCH_MODULE_NOTIFICATION.SUCCESS, obj })

export const getModuleNotification = (moduleName) => {
  return (dispatch, getState) => {
    dispatch(requestListRequest())
    return fetchModuleNotification(moduleName)
      .then((response) => {
        let obj = {
          data: response.data
        }
        dispatch(requestListSuccess(obj))
        return Promise.resolve(true)
      })
      .catch((error) => {
        dispatch(requestListFailure(error?.message))
        return Promise.resolve(false)
      })
  }
}
import FincaInvoices from "./FincaInvoices"
import { connect } from "react-redux"
import { clearInvoiceList, getAllInvoicesList } from "../../../redux/actions/finca/fincaInvoices.action"
import { getMissingFincaCustomers } from "../../../redux/actions/finca/missingFincaCustomers.action"

const mapStateToProps = (state) => {
  return {
    invoiceData: state.fincaInvoicesReducer.data,
    myRights: state.myRights.myRights,
    isLoading: state.fincaInvoicesReducer.isLoading,
    missingFincaCustomers:state?.missingFincaCustomers?.data,
    totalCount:state?.missingFincaCustomers?.totalcount,
    loadingMissingFinca:state?.missingFincaCustomers?.isLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllInvoicesList: (invoicePeriod) => {
      return dispatch(getAllInvoicesList(invoicePeriod))
    },
    clearInvoiceList:()=>{
      return dispatch(clearInvoiceList())
  },
  getMissingFincaCustomers: () => {
    return dispatch(getMissingFincaCustomers())
  },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FincaInvoices)

import { Link, useParams, useSearchParams } from "react-router-dom";
import IMAGES from "../../../../../../assets/images/images";
import {
  ChildContainer,
  Container,
} from "../../../../../../components/Container/Containers";
import { FormFieldLabel as Label, FormErrorLabel as ErrorLabel, FormFieldLabel } from "../../../../../../components/label/Labels";
import { Search } from "../../../../../../components/search/search";
import styles from "./onboardingRequestListViewer.module.css";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { formatSecondsToDate } from "../../../../../../utils/dateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../../../../../components/loader/Loader";
import { EmptyContainer } from "../../../../../dashboard/graphs/component/component";
import { useNavigate } from "react-router-dom";
import DropDown from "../../../../../../components/dropDown/DropDown";
import { useField } from "formik";
import {formatDate, getLabelFromValue} from "../../../../../../utils/arrayUtils";
import Table from "../../../../../../components/table/Table";
import {ON_BOARDING_STATUS, onboardingRequestcolumnConfig } from "../../../../../../utils/constants/userManagementConstant";
import SecondaryTooltip from "../../../../../../components/secondaryToolTip/secondaryToolTip";
import Status from "../../../../../../components/status/Status";
import { allCount, checkAllFalse } from "../../../../../../utils/utils";

// const OnboardingRequestList = ({data}) => {
//     const navigate = useNavigate()

//     return (
//   <> 
//        <div className={styles.container}>
//          <div className={styles.head}>
//               <div className={styles.subhead}>
//                     <div className={styles.orgname} onClick={()=>gotoDetail(data.id)}>{data?.requestId}</div>
//                     <div className={`${styles.status}  ${styles[data?.requestStatus?.value]}`}>{data?.requestStatus?.label}</div>
//               </div>
//               <div className={styles.indicator}>
//               {/* <div className={styles.modifiedOn}>Modified On: <span className={styles.value}>{formatDate(data.modifiedAt)}</span></div> */}
//               <span className={styles.notificationmain}>
//                 {(data.commentFlag.forRequestor)&&(
//                   <div className=""><span className={styles.commentnotification}></span>
//                   <img className={styles.newcomment}src={IMAGES.newcomment} alt="new comment"/>
//                   <img className={styles.notificationicon}src={IMAGES.vfPops} alt="notification"/> 
//                   </div>
//                 )}
//                </span>
//                 {/* <img onClick={()=>gotoDetail(data.id)} className={styles.gotologo} src={IMAGES.chevronredright} alt="" /> */}
//               </div>
//          </div>
//          <div className={styles.listContent}>
//              <div className={styles.list}>
//                 <div className={styles.label}>Organisation Legal name</div>
//                 <div className={styles.value}>{data?.displayName}</div>
//              </div>
//              <div className={styles.list}>
//                 <div className={styles.label}>Service Type</div>
//                 <div className={styles.value}>{data?.requestDetails?.map((item)=>item?.serviceType?.label).join(' & ')}</div>
//              </div>
//              <div className={styles.list}>
//                 <div className={styles.label}>Request Assigned To</div>
//                 <div className={styles.value}>{data?.requestAssignedTo?.label}</div>
//              </div>
//              <div className={styles.list}>
//                 <div className={styles.label}>Requested On</div>
//                 <div className={styles.value}>{formatDate(data?.createdAt)}</div>
//              </div>
//              <div className={styles.list}>
//                 <div className={styles.label}>Modified On</div>
//                 <div className={styles.value}>{formatDate(data.modifiedAt)}</div>
//              </div>
//        </div>           
//        </div>
//   </>
//     );
//   };
  

const OnboardingRequestListViewer = (props) => {
  const navigate = useNavigate()
  const statuses = [
    { id: 'all', label: 'All', count: allCount(props?.statusCount), className: 'statusAll',color: '#CCCCCC',outline:"#9E9E9E" },
    { id: 'pending', label: 'Pending', count: props?.statusCount?.pending?props?.statusCount?.pending:0,color: '#FFEA96',infoMessage : "Pending",outline:"#F4D55B" },
    { id: 'approved', label: 'Approved', count: props?.statusCount?.approved?props?.statusCount?.approved:0, color: '#ACEDD0', infoMessage: "Approved",outline:"#80D8B0" },
    { id: 'rejected', label: 'Rejected', count: props?.statusCount?.rejected?props?.statusCount?.rejected:0, color: '#FFAFAF', infoMessage: "Rejected",outline:"#E60000" },
  ];
  const [checkedStatuses, setCheckedStatuses] = useState(statuses.reduce((acc, status) => ({ ...acc, [status.id]: true }), {}));
  const handleStatusChange = (newCheckedStatuses) => {
    setCheckedStatuses(newCheckedStatuses);
  };
    // const FieldWrapper = ({ children }) => {
    //     return <div className={styles.fieldWrapper}>{children}</div>;
    //   };

    // const SelectField = ({ title, options, ...props }) => {
    //     // let _options = options.map((item) => ({
    //     //   value: item.value,
    //     //   label: item.label,
    //     // }));
    //    // const [field, meta, helpers] = useField(props);
    
    
    //     //const hasError = meta.touched && meta.error ? true : false;
    //     const obj = {
    //         width: "249px",
    //     }
    //     return (

    //       <FieldWrapper>
    //         {/* <div>
    //           <Label text={title} style={styles.labelcompLevel}/>
    //         </div> */}
    //         <DropDown
    //           //options={_options}
    //         //   customStyles={props.customStyles}
    //         //   onChange={({ label, value }) => {
    //         //     helpers.setValue(value);
    //         //   }}
    //         //   value={
    //         //     _options && field.value
    //         //       ? _options.find((option) => option.value === field.value)
    //         //       : ""
    //         //   }
    //         //   hasError={hasError}
    //         customStyles ={obj}
    //         //   isDisabled={props.isDisabled}
    //         />
    //         {/* {hasError ? <ErrorLabel text={meta.error} /> : null} */}
    //       </FieldWrapper>
    //     );
    // };

      const getStatusLegendColorClass = (status) => {
        switch (true) {
          case status === ON_BOARDING_STATUS.PENDING:
            return "statusPending";
          case status === ON_BOARDING_STATUS.APPROVED:
            return "statusApproved";
          case status === ON_BOARDING_STATUS.REJECTED:
            return "statusRejected";
          default:
            return status;
        }
      };

      const headers =[
        <div className={styles.organisationName} key="requestId"><div className={styles.orgname}>Request ID</div></div>,
        <div className={styles.organisationName} key="organisationLegalName"><div className={styles.orgname}>Organisation Legal Name</div>
        {/* <img className={styles.filter} src={IMAGES.filtericon} onClick={() => setFilterState(prevState => ({ ...prevState, isOrgnameFilter: true }))} alt="Filter"/> */}
        </div>,   
        <div className={styles.organisationName} key="serviceType"><div className={styles.orgname}>Service Type</div>
        {/* <img className={styles.filter} src={IMAGES.filtericon} onClick={() => setFilterState(prevState => ({ ...prevState, isServiceTypeFilter: true }))} alt="Filter"/> */}
        </div>,
        <div className={styles.organisationName} key="requestAssignTo"><div className={styles.orgname}>Request Assigned To</div></div>,
        <div className={styles.organisationName} key="requestedOn"><div className={styles.orgname}>Requested On</div>
        {/* <img className={`${styles.sorting} ${sortOrder==='desc'?styles.desc:styles.asc}`} src={IMAGES.sorticon} onClick={toggleSortOrder} alt="sort"/> */}
        </div>,
        <div className={styles.organisationName} key="modifiedOn"><div className={styles.orgname}>Modified On</div>
        {/* <img className={`${styles.sorting} ${sortOrder==='desc'?styles.desc:styles.asc}`} src={IMAGES.sorticon} onClick={toggleSortOrder} alt="sort"/> */}
        </div>
    ];

    const gotoDetail = (requestId,notificationId) => {
      navigate(`/OnboardingRequestDetail/${requestId}`);
      const payload = {
        read: true,
      };
      if(notificationId){
        props.ReadNotification(payload, notificationId);
      }
    }
  
    const mappedData = props?.requestList?.map((item) => {
      const serviceType = item?.requestDetails?.map((item)=>item?.serviceType?.label).join(' & ')
      const colorClass = getStatusLegendColorClass(item?.requestStatus?.value);
      const match = props.notificationdata?.find(row => row.detailsId === item.id);
      const highlightClass = match ? styles.highlightedRow : '';
      return {
        requestId: <span className={`${styles.orglgname} ${styles.posrelative}`}><span className={`${highlightClass}`}></span><SecondaryTooltip text={colorClass==="statusApproved"?"Approved":colorClass==="statusRejected"?"Rejected":"Pending"} positions={['bottom', 'left']}><span className={`${styles.statusLegendsIcon} ${styles[colorClass]} ${match ? styles.margingleft:""}`} /></SecondaryTooltip><SecondaryTooltip text={item.requestId} positions={['bottom', 'right']} ><span onClick={()=>gotoDetail(item?.id,match?.id)}>{item?.requestId}</span></SecondaryTooltip></span>,
        organisationLegalName: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={item.displayName} positions={['bottom', 'right']}>{item.displayName}</SecondaryTooltip></span>,
        serviceType: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={serviceType} positions={['bottom', 'right']}>{serviceType}</SecondaryTooltip></span>,
        requestAssignTo: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={item.requestAssignedTo.label} positions={['bottom', 'right']}>{item?.requestAssignedTo?.label}</SecondaryTooltip></span>,
        requestedOn: <span className={`${styles.customDataContent}`}><SecondaryTooltip text={formatDate(item.createdAt)} positions={['bottom', 'right']}>{formatDate(item?.createdAt)}</SecondaryTooltip></span>,
        modifiedOn: <div className={styles.modifiedonwrapper}><span className={`${styles.customDataContent}`}><SecondaryTooltip text={formatDate(item.createdAt)} positions={['bottom', 'right']}>{formatDate(item.modifiedAt)}</SecondaryTooltip></span><div className={styles.indicator}>
        <span className={styles.notificationmain}>{(item.commentFlag.forRequestor)&&(<div className=""><span className={styles.commentnotification}></span><img className={styles.newcomment}src={IMAGES.newcomment} alt="new comment"/><img className={styles.notificationicon}src={IMAGES.vfPops} alt="notification"/> </div>
          )}</span></div></div>
      };
    });

    const resetsecondaryFilter = () => {
    };
      useEffect(() => {
        const statuses = Object.keys(checkedStatuses)?.filter((key) => checkedStatuses[key])?.join(",")
        if(checkedStatuses.pending||checkedStatuses.approved||checkedStatuses.rejected){
          props.getRequestList(null,statuses);
        }
      }, [checkedStatuses]);

      const fetchData = () => {
        if (props?.next) {
          props.getRequestList(props?.next);
        }
      };
  return (
<ChildContainer> 
    {/* {props?.requestList?.length > 0 &&(
     <div className={styles.headnote}><img className={styles.infocircle}src={IMAGES.infoCircle}/>Onboarding requests description</div>
    )} */}
      {/* <div className={styles.searchhead}>
        <div className={styles.search}>
            <Search 
              //  filterData={filterData}
              //  getSearchKeyValue={getSearchKeyValue}
              //  placeholder="Search"
              //  onKeyDown={onKeyDownHandler}
            />
        </div>
        <div className={styles.statussearch}>
          <SelectField
            title="Status" 
          />
        </div>
      </div> */}
      <div className={styles.fincainfo}><img className={styles.circleinfo} src={IMAGES.infoCircle}/>This section provides an overview of all demands raised by customers. Additionally, you can add comments and update the status as needed.</div>
      <div className={styles.wrapperchild}>
      <div className={styles.boardingrequestlabel}>
            <div className={styles.sublabel}>Onboarding Requests</div>
          </div>
          <div className={styles.statussection}>
          <Status statuses={statuses} onStatusChange={handleStatusChange} infoImage={IMAGES.infoCircle} onResetfilter={resetsecondaryFilter} />
          </div>
      {/* {props?.loading && props?.requestList?.length !== 0 && <Loader />} */}
      <Table headers={headers} data={checkAllFalse(checkedStatuses)||props?.loading?[]:props?.loading===false&&mappedData} height="auto" width="100%" columnConfig={onboardingRequestcolumnConfig} nodata={!props?.loading&&mappedData?.length===0?"No Data.":checkAllFalse(checkedStatuses)?"Select Filter for data.":<Loader/>}/>
      {/* {props?.loading && props?.requestList?.length == 0 ? (
        <Loader />
      ) : props?.requestList?.length == 0 ? (
        <div className={styles.Emptydiv}>No Records Found</div> 
      ) : (
        // <InfiniteScroll
        //   dataLength={props?.requestList?.length} //This is important field to render the next data
        //   next={() => {
        //     fetchData();
        //   }}
        //   hasMore={props.next}
        //   loader={<Loader />}
        // >
        //   {
          props?.requestList?.map((item) => (
            <OnboardingRequestList  data={item}/>
          ))
        //   }
        // </InfiniteScroll>
      )} */}
    </div>
</ChildContainer>
  );
};

export default OnboardingRequestListViewer;

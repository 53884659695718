import React, { useEffect, useState, useRef } from "react";
import styles from "./liveServices.module.css";
import { ServiceName } from "../../components/list/list";
import IMAGES from "../../assets/images/images";
import { Search } from "../../components/search/search";
import { Loader } from "../../components/loader/Loader";
import { filter } from "@ucast/mongo2js";
import { Link } from "react-router-dom";
import CAN from '../../casl/can';  
import {Action , Resource} from '../../casl/constants';  
import { Banner } from "../../components/banner/Banners";
import {USER_TYPE} from "../../utils/constants/userConstants";
import { SERVICE_STATUS_OPTIONS } from "../../utils/constants/serviceConstants";
import { Graphs } from "../../utils/constants/insightsConstants";
import NewService from "../../modules/newService/add/newService.container";
import { openNewServiceForm,closeNewServiceForm,setServiceActiveTab } from "../../redux/actions/newService/service.actions";
//import { use } from "echarts/types/src/extension.js";
import * as XLSX from 'xlsx';
import Overlay from "../dashboard/graphs/overlay/Overlay";
import { Formik, useField, Form, Field } from 'formik';
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../components/label/Labels";
import DropDown from "../../components/dropDown/DropDown";
import { SERVICE } from "../../api/constants";
import SectionGuard from "../../hoc/guards/SectionGuard";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { checkAllFalse, LIVE_SERVICE_STATUS } from "../../utils/constants/liveServiceConstants";
import Status from "../../components/status/Status";
import { AppliedFilter, Filter } from "../../components/filter/filter";
import SecondaryTooltip from "../../components/secondaryToolTip/secondaryToolTip";
import Table from "../../components/table/Table";
import { formatDate } from "../../utils/arrayUtils";
import { columnConfig } from "../../utils/constants/fincaConstant";
import moment from "moment";

const exportToExcel = (data, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${fileName}.xlsx`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(url);
};


const AmmendService = ({data, upgradeServiceRequest,closeForm}) => {   
  const handleClick = (data) => {
    upgradeServiceRequest(data);
    closeForm();
  };
  return (
    <button onClick={() => handleClick(data)} className={`${styles.amendservice}`}>Amend Service</button>
  );
}


const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const SelectField = ({ title, options, ...props }) => {  
  let _options = options.map((item) => ({ value: item.value, label: item.name }));
  const [field, meta, helpers] = useField(props);
  
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
      <Label text={title} />
      </div>
      <DropDown
        options={_options}   
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          helpers.setValue(value);             
        }}
        value={
          (_options && field.value)
            ? _options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const SelectedFilters=({selectedFilters,...props})=>{ 
  let statusName = selectedFilters?.status?(selectedFilters?.status == 'No Shutdown')?'Live':selectedFilters?.status:'NOT SELECTED';
  return(
      <div className={styles.selectedFilters}> 
      <div className={styles.labelShowFilter}>
            <span>Showing results for &nbsp;</span>
      </div>       
      {
        selectedFilters?.user_type == USER_TYPE.INTERNAL ?
          <div className={styles.selectedItem}>
            <span className={styles.label}>Customer</span><span className={styles.value}>{selectedFilters?.customer ? selectedFilters?.customer.toUpperCase() : "NOT SELECTED"}</span>
          </div> : <></>
      }         
      <React.Fragment>             
          <div className={styles.selectedItem}>
              <span className={styles.label}>Service</span><span className={styles.value}>{selectedFilters?.serviceType ? selectedFilters?.serviceType.toUpperCase() : "NOT SELECTED"}</span>
          </div> 
          <div className={styles.selectedItem}>
              <span className={styles.label}>Status</span><span className={styles.value}>{statusName?.toUpperCase()}</span>
          </div>
      </React.Fragment>
         
    </div>
  )
}

const SearchBarLiveServices = ({
  searchKeyValue,
  setSearchKeyValue,
  handleExportToExcel,
  usertype,
  customerName,
  setIsQuickSearch
}) => {
  const inputSearchStyle = `${styles.searchLiveServices} `;

  const filterByQuickSearch=(value)=>{
    setSearchKeyValue(value)
    setIsQuickSearch(true);
  }

  return (
    <div>
    <div className={styles.livesearchBar}>
      <div className={styles.orgnamelable}>{usertype === USER_TYPE.INTERNAL ? "Live Services" : `${customerName} - Live Services`}</div>
      <div className={styles.livesearch}>
      <Search
        placeholder={"Search"}
        style={inputSearchStyle}
        onButtonClick={filterByQuickSearch}
        searchKeyValue={searchKeyValue}
      />
      
      <div >
        <SectionGuard canAccess={CAN(Action.DOWNLOAD, Resource.LIVE_SERVICES)}>
          <button className={styles.download} onClick={handleExportToExcel}> <SecondaryTooltip text={"Download Excel"}  positions={['bottom', 'right']} > <span><img className={styles.downloadlogo} src={IMAGES.downloadxlxs}/></span></SecondaryTooltip></button>  
        </SectionGuard>
      </div>
        {/* } */}
        </div>
    </div>
    </div>
  );
};

const LiveServiceItem = ({setSearchKeyValue,statusCount,isQuickSearch, setIsQuickSearch,columnSecondFilter,columnFilter,sortFilter,sendStatusData,customerList,services,UserType,liveServicesData,isLoading, isInternalUser,PopsLocation, upgradeServiceRequest, serviceList }) => {

  const [showDetail, setShowDetail] = useState(false); 

  const statuses = [
    { id: 'all', label: 'All', count: statusCount?.live + statusCount?.shutdown ?statusCount?.live + statusCount?.shutdown:0 , className: 'statusAll',color: '#CCCCCC',outline:"#9E9E9E" },
    { id: 'No Shutdown', label: 'Live', count: statusCount?.live?statusCount?.live:0,color: '#00990059',infoMessage : "",outline:"#547F54" },
    { id: 'Shutdown', label: 'Shutdown', count: statusCount?.shutdown?statusCount?.shutdown:0, color: '#C4C4C4', infoMessage: "",outline:"#747474" },
   ];
    const [checkedStatuses, setCheckedStatuses] = useState(statuses.reduce((acc, status) => ({ ...acc, [status.id]: true }), {}));
     const [filterState, setFilterState] = useState({
       isFilterPopupOpen: false,
       selectedFilterOptions: [],
       isFilterPopupOpenService: false,
       selectedInvoicingPartyOptions: []
     });
     const [sortOrder, setSortOrder] = useState("desc"); 
    const [isSortedAscending, setIsSortedAscending] = useState(true);
     const handleSort = () => {
       const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
       setSortOrder(newSortOrder);
       setIsSortedAscending(!isSortedAscending);
     };
   
     const handleStatusChange = (newCheckedStatuses) => {
      setCheckedStatuses(newCheckedStatuses);
      sendStatusData(newCheckedStatuses);
    };
    
      const resetsecondaryFilter = () => {
        setIsSortedAscending(true);
        setFilterState(prevState => ({
          ...prevState,
          selectedFilterOptions: [],
          selectedInvoicingPartyOptions:[]
        }));
      };

      const handleFilterApply = (selectedOptions,lastremoved) => {
      setSearchKeyValue('')
      setIsQuickSearch(false)
       setFilterState(prevState => ({
        ...prevState,
        selectedFilterOptions: selectedOptions
      }));
      columnFilter(selectedOptions)

    };
    
    const handleInvoicingFilterApply = (options,lastremoved) => {
      setSearchKeyValue('')
      setIsQuickSearch(false)
      setFilterState(prevState => ({
        ...prevState,
        selectedInvoicingPartyOptions: options
      }));
      columnSecondFilter(options)

    };
    
    const handleFilterReset = () => {
      setFilterState(prevState => ({
        ...prevState,
        selectedFilterOptions: [],
      }));
      columnFilter([]);
    };
    
    const handleServiceFilterReset = () => {
      setFilterState(prevState => ({
        ...prevState,
        selectedInvoicingPartyOptions: [],
      }));
      columnSecondFilter([])
    };
    
    const resetappliedFilter = () =>{
      handleFilterReset();
      handleServiceFilterReset();
      columnFilter([]);
      columnSecondFilter([])
      setIsQuickSearch(false)
    }

    useEffect(() => {
      if (isQuickSearch) {
     resetappliedFilter();
      }
    }, [isQuickSearch]);


      const getLocation=(pe_ref)=>{
        const obj = PopsLocation.find(({ hostName }) => hostName?.toLowerCase() === pe_ref?.toLowerCase());
        return obj?.city;
      }
      let weatherMapParams={
        tab:Graphs.WEATHERMAPS,
        service_types:liveServicesData?.ipx_service,
        cp_names:liveServicesData?.cp_name_region
      }
     let _weatherMapParams=JSON.stringify(weatherMapParams)
    
      const headers = UserType === USER_TYPE.INTERNAL
      ? [<div className={styles.organisationName} key="organisationlegalname"><div className={`${styles.orgname} ${styles.orgnwidth}`}>Organisation TADIG Code</div>
            <img className={styles.filter} src={IMAGES.filtericon}  onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpen: true }))} alt="Filter"/>
            {filterState.isFilterPopupOpen && (
              <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpen} options={customerList?.map(values => ({ value: values, label: values }))} onApply={handleFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpen: false }))} label={"Organisation Legal Name"} initialSelectedOptions={filterState.selectedFilterOptions} onReset={handleFilterReset}/>
            </div>)}
          </div>,
          <div className={styles.organisationName} key="service"><div className={styles.orgname}>Service</div>
            <img className={styles.filter} src={IMAGES.filtericon}  onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenInvoicingParty: true }))} alt="Filter"/>
            {filterState.isFilterPopupOpenInvoicingParty && (
              <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpenInvoicingParty} options={services?.map(values => ({ value: values, label:values.toUpperCase() }))} onApply={handleInvoicingFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenInvoicingParty: false }))} label={"Service"} initialSelectedOptions={filterState.selectedInvoicingPartyOptions} onReset={handleServiceFilterReset}/>
            </div>)}
          </div>,
           <div className={styles.organisationName} key="vlanId"><div className={styles.orgname}>VLAN ID</div></div>,
           <div className={styles.organisationName} key="region"><div className={styles.orgname}>Region</div></div>,
           <div className={styles.organisationName} key="routerCity"><div className={styles.orgname}>Router City</div></div>,
           <div className={styles.organisationName} key="routerName"><div className={styles.orgname}>Router Name</div></div>,
           <div className={styles.organisationName} key="linkUtilisation"><div className={styles.orgname}>Link Utilisation</div></div>,]
        : [<div className={styles.organisationName} key="service"><div className={`${styles.orgname} ${styles.orgncustomerwidth}`}>Service</div>
           <img className={styles.filter} src={IMAGES.filtericon}  onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenInvoicingParty: true }))} alt="Filter"/>
           {filterState.isFilterPopupOpenInvoicingParty && (
              <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpenInvoicingParty} options={services?.map(values => ({ value: values, label:values.toUpperCase() }))} onApply={handleInvoicingFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenInvoicingParty: false }))} label={"Service"} initialSelectedOptions={filterState.selectedInvoicingPartyOptions} onReset={handleServiceFilterReset}/>
            </div>)}
           </div>,
           <div className={styles.organisationName} key="vlanId"><div className={styles.orgname}>VLAN ID</div></div>,
           <div className={styles.organisationName} key="region"><div className={styles.orgname}>Region</div></div>,
           <div className={styles.organisationName} key="routerCity"><div className={styles.orgname}>Router City</div></div>,
           <div className={styles.organisationName} key="routerName"><div className={styles.orgname}>Router Name</div></div>,
           <div className={styles.organisationName} key="linkUtilisation"><div className={styles.orgname}>Link Utilisation</div></div>,]
  
  
     const getStatusLegendColorClass = (status, userType) => {
        switch (true) {
          case status === LIVE_SERVICE_STATUS.live && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
            return "statusLegendsIconGreen";
          case status === LIVE_SERVICE_STATUS.shutdown && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
            return "statusLegendsIconAmber";
          
          default:
            return status;
        }
      };
      const [selectedService, setSelectedService] = useState(null);
      const closeForm = () => {
        setShowDetail(false); 
      };
      const handleServiceClick = (service) => {
        setSelectedService(service);
        setShowDetail(true); 
      };
      const currentUserType = UserType;
      const mappedData = liveServicesData?.map((liveService) => {
      const colorClass = getStatusLegendColorClass(liveService.bgp_status, currentUserType);
        return {
       
          organisationlegalname: (
            <span className={`${styles.customDataContent}`}>       
            <SecondaryTooltip text={colorClass==="statusLegendsIconGreen"?"Live":"Shutdown"} positions={['bottom', 'left']}><span className={`${styles.statusLegendsIcon} ${styles[colorClass]} ${styles.spanSpaceing}`} />
              </SecondaryTooltip>
            <span className={`${styles.invoiceId}`} 
           onClick={()=>{handleServiceClick(liveService)}}
            >
              <SecondaryTooltip text={liveService?.cp_name } positions={['bottom', 'right']}>{liveService?.cp_name}</SecondaryTooltip>
              </span>
          </span>
          
          ),
          service:
    currentUserType === USER_TYPE.INTERNAL ? (
      <span className={`${styles.customDataContent}`}>
        <span className={`${styles.organame} ${styles.texttruncate}`}>
          <SecondaryTooltip
            text={liveService?.ipx_service?.toUpperCase()}
            positions={["bottom", "right"]}
          >
            {liveService?.ipx_service?.toUpperCase()}
          </SecondaryTooltip>
        </span>
      </span>
    ) : (
      <span className={`${styles.customDataContent}`}>
        <SecondaryTooltip
          text={colorClass === "statusLegendsIconGreen" ? "Live" : "Shutdown"}
          positions={["bottom", "left"]}
        >
          <span
            className={`${styles.statusLegendsIcon} ${styles[colorClass]} ${styles.spanSpaceing}`}
          />
        </SecondaryTooltip>
        <span
          className={`${styles.invoiceId}`}
          onClick={() => {
            handleServiceClick(liveService);
          }}
        >
          <SecondaryTooltip
            text= {liveService?.ipx_service?.toUpperCase()}
            positions={["bottom", "right"]}
          >
            {liveService?.ipx_service?.toUpperCase()}
          </SecondaryTooltip>
        </span>
      </span>
         ),
          "vlanId": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={liveService?.vlan} positions={['bottom', 'right']}>{liveService?.vlan}</SecondaryTooltip></span>,
          "region": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={liveService?.cp_name_region} positions={['bottom', 'right']}>{liveService?.cp_name_region}</SecondaryTooltip></span>,  
          "routerCity": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={getLocation(liveService?.pe_ref)?getLocation(liveService?.pe_ref):"No Data" } positions={['bottom', 'right']}>{getLocation(liveService?.pe_ref) ?getLocation(liveService?.pe_ref):"---"}</SecondaryTooltip></span>,
          "routerName": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={liveService?.pe_ref} positions={['bottom', 'right']}>{liveService?.pe_ref}</SecondaryTooltip></span>,
          "linkUtilisation": <span className={`${styles.customDataContent}`}>
              <div className={styles.sidedetail}>
              <Link to={`/insights/${_weatherMapParams}`} target="_blank"  className={styles.link}>
                View Link
              </Link> 
             </div>
             </span>,  
              
      };

      });
    

  return (
    <div className={styles.collapsibleContainer}>
      <div className={styles.collapsible}>
       <div>
         {showDetail ? (
        <Sidebar isOpen={showDetail} sideNavStyle={styles.sideNavStyle}upgradeServiceRequest={upgradeServiceRequest} serviceList={serviceList} headerTxt={<div className={styles.headertxt}>
          <span>Service Detail</span>
          <span className={styles.statusContainer}>
            <label 
              className={`${styles.serviceStatus} ${
                selectedService?.bgp_status === "No Shutdown"
                  ? styles.noShutdown
                  : selectedService?.bgp_status === "Value is missing"
                  ? styles.valueIsMissing
                  : styles.shutdown
              }`}
            >
              {selectedService?.bgp_status === "No Shutdown" ? (<span>Live</span>) : (<span>{selectedService?.bgp_status}</span>)}
            </label>
          </span>
        </div>} data={selectedService} onClose={closeForm}>
         <div className={styles.liveDetail}>
          <div className={styles.parent}>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>Service</div>
             <div className={styles.sidedetail}>{selectedService?.ipx_service?.toUpperCase()}</div>
            </div>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>VLAN ID</div>
             <div className={styles.sidedetail}>{selectedService.vlan}</div>
            </div>
          </div>
          <div className={styles.parent}>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>Router City</div>
             <div className={styles.sidedetail}>{getLocation(selectedService.pe_ref)}</div>
            </div>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>Router Name</div>
             <div className={styles.sidedetail}>{selectedService.pe_ref}</div>
            </div>
          </div>
          <div className={styles.parent}>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>Allocated Capacity (Mbps)</div>
             <div className={styles.sidedetail}>{selectedService.logical_capacity_mbps}</div>
            </div>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>Physical Capacity (Gbps)</div>
             <div className={styles.sidedetail}>{selectedService.physical_capacity_gbps}</div>
            </div>
          </div>
          <div className={styles.parent}>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>VDF Internal Ref.</div>
             <div className={styles.sidedetail}>{selectedService.ref3c}</div>
            </div>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>BGP IP Address Customer</div>
             <div className={styles.sidedetail}>{selectedService.bgp_neighbor}</div>
            </div>
          </div>
          <div className={styles.parent}>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>BGP IP Address VDF</div>
             <div className={styles.sidedetail}>{selectedService.vf_bgp_ip}</div>
            </div>
            <div className={styles.liveDetailsDiv}>
             <div className={styles.sidelabel}>Link Utilisation</div>
             <div className={styles.sidedetail}>
              <Link to={`/insights/${_weatherMapParams}`} target="_blank"  className={styles.link}>
                View Link
              </Link> 
             </div>
            </div>
          </div>
         </div>
         <SectionGuard canAccess={CAN(Action.LIST,Resource.LIVE_SERVICES) && CAN(Action.CREATE,Resource.DEMANDS) && !isInternalUser}>
         <div className={styles.footer}>
          <hr className={styles.separator} />
          <div className={styles.container} >
          <button  onClick={(e)=>setShowDetail(false)} className={`${styles.Backbtn}`}>Back</button>
           <AmmendService data={selectedService} upgradeServiceRequest={upgradeServiceRequest} closeForm={closeForm} serviceList={serviceList} />
          </div>
         </div>
         </SectionGuard>
        </Sidebar>
      ) : null}
         </div>
        <div className={styles.titleContainer}>
                <div className={styles.subContainer}>
                 <Status statuses={statuses}  isQuickSearch={isQuickSearch} onStatusChange={handleStatusChange} infoImage={IMAGES.infoCircle} currentUserType={currentUserType}  />
                 {(filterState.selectedFilterOptions.length > 0 || filterState.selectedInvoicingPartyOptions.length>0) && (<AppliedFilter selectedFilterOptions={filterState.selectedFilterOptions} selectedInvoicingPartyOptions={filterState.selectedInvoicingPartyOptions} onReset={resetappliedFilter}setSelectedFilterOptions={setFilterState} handleFilterApply={handleFilterApply} handleInvoicingFilterApply={handleInvoicingFilterApply} firstFilterLabel={"Organisation Legal Name"} secondFilterLabel={"Service"}/>)}
                  <div className={styles.wrapper}>
                    <Table headers={headers} data={checkAllFalse(checkedStatuses)?[]:mappedData} height={`${mappedData?.length<8 &&(filterState?.isFilterPopupOpen||filterState?.isFilterPopupOpenInvoicingParty)?"480px":"auto"}`} width="100%" columnConfig={columnConfig} 
                    nodata={!isLoading && mappedData?.length===0?"No Data.":checkAllFalse(checkedStatuses)?"Select Filter for data.": <Loader/>} />
                  </div>
          </div>
        </div>
      </div>
     
    </div>
  );
};

const LiveServices = (props) => {
  const { liveServicesData, isLoading,statusCount} = props;  
  let [searchKeyValue, setSearchKeyValue] = useState(""); 
  let [initialValues, setInitialValues] = useState({});  
  const [status, setStatus] = useState('');
  const [orgLegal, setOrgLegal] = useState([]);
  const [serviceName, setService] = useState([]);
  const [isQuickSearch, setIsQuickSearch] = useState(false);
console.log("searchKeyValue",searchKeyValue);

 
  const upgradeServiceRequest = (data) => {  
    setInitialValues(data); // open form with data
    props.openNewServiceForm(data);
    props.setIsAmendMode(true);
  }

  
    useEffect(() => {
      props.getLiveServicesFiltersValue(); 
    }, []);

    useEffect(()=>{
      filterData();  
    },[status,orgLegal,serviceName])

    const filterData = () => {    
      let searchKey = searchKeyValue?.trim();
      let statusFilter = Object.keys(status)?.filter((key) => status[key]).join(",")
      const orgName = orgLegal.map((item) => item.value).join(",");
      const service = serviceName.map((item) => item.value).join(",");
      let cp_name = props.myRights?.cpName;
      props.getLiveServices(cp_name, searchKey,'',orgName,service,statusFilter) 
  }
  
  
  const handleExportToExcel = () => {
   const PopsLocation = props.pops;
   const service = serviceName.map((item) => item.value).join(",");
   let statusFilter = Object.keys(status)?.filter((key) => status[key]).join(",")
   const orgName = orgLegal.map((item) => item.value).join(",");

    props.downloadLiveServicesList(orgName,searchKeyValue,orgName,service,statusFilter).then((result) => {

      const modifyliveserviceData = result.downloadedData.map((data) => {
        const getLocation=(pe_ref)=>{
          const obj = PopsLocation.find(({ hostName }) => hostName.toLowerCase() === pe_ref.toLowerCase());
          return obj?.city;
        }
        return {
          "Customer": data.cp_name,
          "Service": data.ipx_service.toUpperCase(),
          "VLAN ID": data.vlan,
          "Router City":getLocation(data.pe_ref),
          "Router Name": data.pe_ref,
          "Allocated Capacity (Mbps)": data.logical_capacity_mbps,
          "Physical Capacity (Gbps)": data.physical_capacity_gbps,
          "VDF Internal Ref.": data.ref3c,
          "BGP IP Address Customer": data.bgp_neighbor,
          "BGP IP Address VDF": data.vf_bgp_ip,
          "Status": data.bgp_status === "No Shutdown" ? "Live" : data.bgp_status,
        }; 
      });
      
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear().toString().slice(-2)}`;
    exportToExcel(modifyliveserviceData, `LiveServices-${formattedDate}`);
  })
  .catch((error) => {   
  }); 
};

const handleStatusData = (data) => {
    setStatus(data);

};

const handleOrglegal = (data) =>{
  setOrgLegal(data);

}

const handleServiceName=(data)=>{
  setService(data)

}

  const tabsInfoCustomer="This section outlines all your available services, including their status, router name, and additional details."
  const tabsInfoInternal="This section outlines all services availed by the customer, along with their status, router name, and additional details."

 
  return (
    <div>
        <div className={`${styles.moduledescription} ${CAN(Action.LIST, Resource.DEMANDS) && CAN(Action.LIST, Resource.LIVE_SERVICES)?"":styles.marginfromtop}`}> <img  className={styles.descriptioncircle} src={IMAGES.infoCircle}/>{props.myRights?.userType === USER_TYPE.INTERNAL ? tabsInfoInternal : tabsInfoCustomer }</div>
    <div className={styles.collapsibleContainer}>
      <SearchBarLiveServices
        searchKeyValue={searchKeyValue}
        handleExportToExcel={handleExportToExcel}
        usertype={props?.myRights?.userType}
        customerName={props?.myRights?.customerUserMetaInfo?.customerName}
        setIsQuickSearch={setIsQuickSearch}
        setSearchKeyValue={setSearchKeyValue}

      />
          <React.Fragment>
              <LiveServiceItem
                isLoading={isLoading}
                isQuickSearch={isQuickSearch} 
                setIsQuickSearch={setIsQuickSearch}
                liveServicesData={liveServicesData}
                UserType={props.myRights?.userType}
                sendStatusData={handleStatusData} 
                columnSecondFilter={handleServiceName}
                columnFilter={handleOrglegal}
                statusCount={statusCount}
                services={props.serviceTypesDrodownOptions} 
                customerList={props.customerDrodownOptions} 
                statusOptions = {props.statusDrodownOptions}  
                isInternalUser={props.myRights?.userType === USER_TYPE.INTERNAL ? true : false}
                PopsLocation={props.pops} upgradeServiceRequest={upgradeServiceRequest}
                setSearchKeyValue={setSearchKeyValue}
              />
          </React.Fragment>
     <NewService initialValue={initialValues} fromLiveService={true}></NewService>
    </div>
    </div>
  );
};

export default LiveServices;

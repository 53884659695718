import React, { useId, useState, useEffect } from "react";
import {
  ChildContainer,
  Container,
} from "../../../../components/Container/Containers";
import styles from "./accessApprovalDetail.module.css";
// import NewCustomer from "../add/NewVFUser";
import IMAGES from "../../../../assets/images/images";
import { Banner } from "../../../../components/banner/Banners";
import NewUser from "../addUser/NewUser.container";
import { Field, Form, Formik,ErrorMessage } from "formik";
import * as Yup from "yup";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import { Loader } from "../../../../components/loader/Loader";
// import { Alert } from "../../../../components/alert/alerts";
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";
import { FormFieldLabel as Label ,FormErrorLabel as ErrorLabel} from "../../../../components/label/Labels";
import { useNavigate } from 'react-router-dom';
import { useParams ,useLocation} from 'react-router-dom';
import AddExtensionRequest from "./addExtensionForm/addextension";
import DynamicTable from "../../../../components/dynamicTable/dynamicTable";
import { Incident_Service_Type_Values } from "../../../../utils/constants/incidentConstants";

const Switch = ({ isChecked }) => {
  return (
    <label className={styles.switch}>
      <input type="checkbox" checked={isChecked} onChange={() => {}} />
      <span className={styles.slider}></span>
    </label>
  );
};
const CheckBox = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const UserList = ({ data, functionalRolesData, updateUserPermissions, openAlertPopup }) => {
  let [isAlert, setIsAlert] = useState(false);
  let [isEditPermission, setIsEditPermission] = useState(false);
  let [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success,
  });

  const validationSchema = Yup.object().shape({
    functionalRoles: Yup.array().min(1),
  });
  let initialValues = {
    functionalRoles:
    data?.functionalRoles && data?.functionalRoles.length > 0 ? data?.functionalRoles : [],
  };

  const editPermission = () => {
    setIsEditPermission(!isEditPermission);
  };

  return (
    <div className={styles.CustomerContainer}>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Contact Name</span>
        <p>{data.displayName}</p>
      </div>

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Email Address</span>
        <p>{data.email}</p>
      </div>

      {/* <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Mobile Number</span>
        <p>{data.phoneNumber}</p>
      </div> */}

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Onboarded On</span>
        <p>{formatSecondsToDate(data.createdAt)}</p>
      </div>

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Status </span>
        <p className={styles.switchst}>
          {""}Inactive{" "}
          <span className={styles.spanSwitch}>
            <Switch isChecked={data.status === "active" ? true : false} />
          </span>
          Active
        </p>
      </div>
      {/* <ExpandableArea>
        <div style={{ width: "100%" }}>
          <div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={async (values, { resetForm }) => {
               
                setShowLoader(true);
                updateUserPermissions(values, data.uid)
                  .then((result) => {
                    if (result.status) {
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: "User permissions updated successfully.",
                      //   image: IMAGES.success,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: "User permissions updated successfully!",
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    } else {
                      let error_msg = result?.error;
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: error_msg,
                      //   image: IMAGES.error,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: error_msg,
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    }
                  })
                  .catch((error) => {
                    setIsEditPermission(!isEditPermission);
                    // setMessage({
                    //   message: "Something went wrong.",
                    //   image: IMAGES.error,
                    // });
                    // setIsAlert(true);
                    openAlertPopup({
                      message: "Something went wrong.",
                      image: IMAGES.error
                    });
                    setShowLoader(false);
                  });
              }}
            >
              {({ isValid, dirty, submitForm }) => (
                <Form className={styles.form}>
                  <div className={styles.sectionContainer}>
                    <div className={styles.sectionTitle}>Modules Assigned</div>
                    {showLoader ? (
                      <div className={styles.loaderContainer}>
                        <Loader style={styles.loaderContainer} />
                      </div>
                    ) : isEditPermission ? (
                      <div>
                        <img
                          src={IMAGES.save}
                          className={styles.Icon}
                          onClick={submitForm}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={IMAGES.editIcon}
                          className={styles.Icon}
                          onClick={editPermission}
                        />
                      </div>
                    )}
                  </div>
                  <ul
                    style={{
                      listStyle: "none",
                      paddingInlineStart: 0,
                      display: "grid",
                      gap: 16,
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    {functionalRolesData?.map((role) => (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <fieldset disabled={!isEditPermission}>
                          <CheckBox
                            name="functionalRoles"
                            value={role.id}
                            label={role.name}
                          />
                        </fieldset>
                      </li>
                    ))}
                  <ErrorMessage name ="functionalRoles">
                    {(msg)=><ErrorLabel text={msg} />}
                  </ErrorMessage>
                  </ul>
                  <div></div>
               
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ExpandableArea> */}
    </div>
  );
};


const ExpandableArea = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  if (!expanded) {
    return (
      <div className={styles.expanded}>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(true)}
        >
          <span>View More</span>
          <img src={IMAGES.arrowdown} width={24} height={24} alt="down arrow" />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.expanded}>
      <div>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(false)}
        >
          <span>View Less</span>
          <img src={IMAGES.arrowup} width={24} height={24} alt="up arrow" />
        </button>
      </div>

      {children}
    </div>
  );
};

const VodafoneUserList = ({ vfData }) => {
  return (
    <div className={styles.CustomerContainer}>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>
          {vfData?.pref == 0
            ? "Primary Contact Name"
            : "Additional Contact Name"}
        </span>
        <p>{vfData?.name}</p>
      </div>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Email Address</span>
        <p>{vfData?.email}</p>
      </div>
    </div>
  );
};

const AccessApprovalDetail = ({
  getAllCustomerList,
    customer,
    isLoadingCustomer,
    getDetails,
    myRights,
    next,
    ...props
  }) => {
    const { userList, isLoading, functionalRoles, openAlertPopup } = props;
    const [isSubmit, setIsSubmit] = useState(false);
    const location = useLocation();
    let { requestId } = useParams();
    const queryParams = new URLSearchParams(location.search);
    let customerId = queryParams.get('customerId');
    useEffect(() => {
      let searchKey = "";
      let type = USER_TYPE.CUSTOMER;
      let custID = queryParams.get('customerId');
      let next=null;
      props.getUser(type, custID, searchKey,next);
      props.getPredefinedRoles("customer");
      getDetails(customerId);
      window.scrollTo(0,0);
      if(Object.keys(props.list)?.length === 0){
        getAllCustomerList();
      }
    }, []);

    const filterData = props?.list?.filter((item)=>item.id===requestId);
    const detailData = filterData[0]
    const headerAccountManager = ['Contact Name', 'Email Address'];

    const mappedAccountManager = detailData?.serviceSpecificDetails?.map(poc => ({
      'Contact Name': poc?.accountManagers[0]?.name,
      'Email Address': poc?.accountManagers[0]?.email,
    //   'OnBoarded On': formatSecondsToDate(customer?.createdAt),
    //   'Status':  <p className={styles.active}>               
    //   {""}Inactive{" "}
    //   <span className={styles.spanSwitch}> 
    //     <Switch isChecked={true} />
    //   </span>
    //   Active
    // </p>
    }));

    const navigate = useNavigate();
    const onBack = (requestId) => {
        navigate(`/user-management`);  
        
      }

const handleExtension = (data) => {
    setIsSubmit(true);
       const obj = {
        uniqueCustomerRefId: data?.uniqueCustomerRefId,
        displayName: data?.displayName, 
        serviceType: data?.serviceSpecificDetails[0]?.serviceType?.value === Incident_Service_Type_Values.IPX_Service ? Incident_Service_Type_Values.IVC_Service : Incident_Service_Type_Values.IPX_Service,
    }
        props.AddExtensionAccessRequest(obj)
        .then((result) => {
          if (result.status) {
            props.openAlertPopup({
              message: `Account access request sent successfully!`,
              image: IMAGES.success,
            });
            setIsSubmit(false);
           // resetForm();
        //    close();
            // props.getServiceList();
          } else {
            let error_msg =
              result?.error?.message ?? "Something went wrong.";

            props.openAlertPopup({
              message: error_msg,
              image: IMAGES.error,
              isFormOpen: true,
            });
            setIsSubmit(false);
          //  close();
          }
        })
        .catch((error) => {
          props.openAlertPopup({
            message: "Something went wrong.",
            image: IMAGES.error,
            isFormOpen: true,
          });
          setIsSubmit(false);
        //  close();
        });      
    }
  return (
    <Container>
     <Banner image={IMAGES.banner} text={"Account Details"}></Banner>
      {isLoadingCustomer  ? (
        <Loader />
      ) : (
        <ChildContainer>
          <div className={styles.customer}>
           <div> <span> <img
                className={styles.icon}
                onClick={()=>onBack()} 
                src={IMAGES.backarrow}
                alt=""
              />             
              </span> <span className={styles.textCustomer}>{customer?.displayName}</span>{detailData?.displayName}</div>
            {/* {!isSubmit?
            <div>
                { detailData?.serviceSpecificDetails?.length ===1 &&(
                  detailData?.requestedBy?.some((item) => item?.email === myRights?.email) ? (
                    <span className={styles.raisedreq}>Already request raised</span>
                ) : (
                  <button className={styles.extendbtn} onClick={() =>handleExtension(detailData)}>Extension Access Request</button>
                )
               )}</div> 
            : <div className={`${styles.btnLoaderContainer} `} ><Loader  loaderStyle={styles.btnLoader}style={styles.btnLoader}/></div>} */}
          </div>
          {/* <div className={styles.headnote}><img className={styles.infocircle}src={IMAGES.infoCircle}/>You don’t have an access to edit the customer details. You can request access from existing account manager.</div> */}
          <div>
          <div className={styles.CustomerContainers}>
            <div className={styles.requestDetail}>Customer Details</div>
            <div  className={styles.Customerbox}>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Organisation Legal Name</span>
                <p>{detailData?.displayName}</p>
              </div>
             
              </div>
              <div className={styles.requestDetail}>Availed Service</div>
              {detailData?.serviceSpecificDetails?.map((detail,index, array)=>
      <>
        <div key={index} className={`${styles.Customerbox} ${array.length > 1 && index !== array.length - 1 ? styles.borderbottom : ''}`} >
          <div className={styles.CustomerList}>
              <span className={styles.spanlabel}>Service Type</span>
              <p>{detail?.serviceType?.label}</p>
          </div>
          {detail?.cpName!==null&&(
          <div className={styles.CustomerList}>
              <span className={styles.spanlabel}>TADIG Code</span>
              <p>{detail?.cpName}</p>
          </div>
          )}
           {detail?.serviceType?.value===Incident_Service_Type_Values.IPX_Service&&myRights.userType!==USER_TYPE.CUSTOMER&&(
          <div className={styles.CustomerList}>
              <span className={styles.spanlabel}>FinCA Code</span>
              <p>{detail?.fincaTadigCode !== null ? detail.fincaTadigCode : '---'}</p>
          </div>
        )}
          {detail?.serviceType?.value===Incident_Service_Type_Values.IVC_Service&&myRights.userType!==USER_TYPE.CUSTOMER&&(
          <>
            <div className={styles.CustomerList}>
              <span className={styles.spanlabel}>Account Number</span>
              <p>{detail?.accountNumber}</p>
            </div>
            <div className={styles.CustomerList}>
              <span className={styles.spanlabel}>Account Abbreviation</span>
              <p>{detail?.accountAbbr}</p>
            </div>
                </>
              )}
              {/* <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Onboarding Date</span>
                <p>{formatSecondsToDate(customer?.createdAt)}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Primary Contact Name</span>
                <p spandata>{customer?.primaryContact?.displayName}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Email Address</span>
                <p spandata>{customer?.primaryContact?.email}</p>
              </div> */}
        </div>
      </>
         )}
              {/* <div  className={styles.Customerbox}>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>TADIG ID</span>
                <p>{customer?.cpName}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Onboarding Date</span>
                <p>{formatSecondsToDate(customer?.createdAt)}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Primary Contact Name</span>
                <p spandata>{customer?.primaryContact?.displayName}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Email Address</span>
                <p spandata>{customer?.primaryContact?.email}</p>
              </div>
              </div> */}
              <div className={styles.checkboxmain}>
            
              </div>
  
            </div>
          </div>

          <div
         data-vf-user={
            myRights?.userType === USER_TYPE?.INTERNAL ? true : false
          }
            className={styles.detailsContainer}
          >
            <div>
              <div className={styles.textuser}>Account Manager</div>
              {/* {customer?.vfPOC?.map((vfData) => (
                <VodafoneUserList vfData={vfData} />
              ))} */}
                <DynamicTable headers={headerAccountManager} data={mappedAccountManager} className="custom-table" />
            </div>
            {/* <div>
              <div className={styles.header}>
                <div className={styles.text}>Customer Details</div> */}
                {/* <NewUser cpName={customerId} primaryContact={customer?.primaryContact}/> */}
              {/* </div>
              { userList?.length ? (
                <InfiniteScroll
                  dataLength={userList?.length} //This is important field to render the next data
                  next={() => {
                    return props.getUser(
                     USER_TYPE.CUSTOMER,
                     customerId,
                      "",
                      next
                    );
                  }}
                  hasMore={next}
                  loader={
                      <Loader />
                  }
                > */}
                  {/* {userList?.map((userData) => (
                    <UserList
                      data={userData}
                      functionalRolesData={functionalRoles}
                      updateUserPermissions={props.updateUserPermissions}
                      openAlertPopup= {openAlertPopup}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                // <div className={styles.emptyContainer}>No users found</div>
                <EmptyContainer>No User Found</EmptyContainer>
              )}
            </div>
          </div> */}
        </div>
        </ChildContainer>
      )}
    </Container>
  );
};

export default AccessApprovalDetail;

import { getEventNotificationList, ReadNotification ,NotificationOptions} from "../../redux/actions/notification/getEventNotificationList.action";
import { RemindMe } from "../../redux/actions/notification/remindMe.action";
import Notification from "./Notification";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    myRights:state.myRights.myRights,
    dropdownOptions:state.notifications.notificationOptions.data,
    eventnotification:state.notifications.eventNotification
 }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getEventNotificationList: (next=null,option)=>{
      return dispatch(getEventNotificationList(next,option))
  },
    ReadNotification: (payload,id) => {
      return dispatch(ReadNotification(payload,id))
    },
    NotificationOptions: (payload,id) => {
      return dispatch(NotificationOptions(payload,id))
    },
    RemindMe: (payload,id) => {
      return dispatch(RemindMe(payload,id))
    },

     }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
import LiveServices from "./LiveServices";
import {connect} from "react-redux";
import { getLiveServices, getLiveServicesFiltersValue,downloadLiveServicesList } from "../../redux/actions/liveServies/liveServices.action";
import { openNewServiceForm,closeNewServiceForm, setIsAmendMode } from "../../redux/actions/newService/service.actions";

const mapStateToProps = (state) => { 
    return {
            statusCount:state.liveServices.statusCount,
            pops:state.liveServices.locations,
            liveServicesData:state.liveServices.data,
            isLoading:state.liveServices.isLoading,
            myRights:state.myRights.myRights,
            isAmendMode:state.newService.service.isAmendMode,
            liveServicesFilter: state.liveServices.filters,
            isFilterLoading: state.liveServices.isFilterLoading,
            customerDrodownOptions:state.liveServices.filters?.cpNames,
            serviceTypesDrodownOptions:state.liveServices.filters?.serviceTypes,
            statusDrodownOptions:state.liveServices.filters?.status           
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLiveServices: (cp_name,searchKey, filter,customer="",serviceType="",status="") => {
            return dispatch(getLiveServices(cp_name,searchKey, filter,customer,serviceType,status))
        },
        downloadLiveServicesList: (filter = '', searchKey = '',next=null,customer="",serviceType="",status="")=>{
            return dispatch(downloadLiveServicesList(filter, searchKey,next,customer,serviceType,status))
        },
        openNewServiceForm: (data) => {
            return dispatch(openNewServiceForm(data))
        },
        closeNewServiceForm: () => {
            return dispatch(closeNewServiceForm())
        },
        setIsAmendMode: (mode) => {
            return dispatch(setIsAmendMode(mode))
        },
        getLiveServicesFiltersValue: () => {
            return dispatch(getLiveServicesFiltersValue())
        }
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveServices);

import { FETCH_MISSING_FINCA_CUSTOMERS } from "../actionTypes"
import { fetchMissingFincaCustomers } from "../../../api/finca/fincaInvoices.service"

const requestListRequest = () => ({ type: FETCH_MISSING_FINCA_CUSTOMERS.REQUEST })
const requestListFailure = (error) => ({type: FETCH_MISSING_FINCA_CUSTOMERS.FAILURE,error})
const requestListSuccess = (obj) => ({ type: FETCH_MISSING_FINCA_CUSTOMERS.SUCCESS, obj })

export const getMissingFincaCustomers = (invoicePeriod) => {
  return (dispatch, getState) => {
    dispatch(requestListRequest())
    return fetchMissingFincaCustomers(invoicePeriod)
      .then((response) => {
        let obj = {
          data: response?.data,
          totalcount:response?.totalIpxCount
        }
        dispatch(requestListSuccess(obj))
        return Promise.resolve(true)
      })
      .catch((error) => {
        dispatch(requestListFailure(error?.message))
        return Promise.resolve(false)
      })
  }
}
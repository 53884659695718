import MyIncidentViewer from "./myIncidentViewer";
import { connect } from "react-redux";
import { getIncidentList } from "../../../../redux/actions/incidentManagement/incidentList.action";
import { ReadNotification } from "../../../../redux/actions/notification/getEventNotificationList.action";

const mapStateToProps = (state) => {
  return {
    incidentList: state.incidentList,
    myRights : state.myRights.myRights,
    statusCount: state.incidentList.statusCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIncidentList: (next=null,status="",orderBy="")=>{
      return dispatch(getIncidentList(next,status,orderBy))
  },
  //   ReadNotification: (payload,id) => {
  //   return dispatch(ReadNotification(payload,id))
  // }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyIncidentViewer);

import { NOTIFICATION_OPTION_LIST } from "../../actions/actionTypes";

const initialState = {  
    data: [],
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {    
        case NOTIFICATION_OPTION_LIST.REQUEST:
            return { ...state, loading: true };
        case NOTIFICATION_OPTION_LIST.FAILURE:
            return { ...state, loading: false };
        case NOTIFICATION_OPTION_LIST.SUCCESS:
            return { ...state, data: action.obj, loading: false };
        default:
            return state;
    }
};

export default reducer;
import React, { useState, useEffect, useRef } from "react";
import styles from "./notification.module.css";
import IMAGES from "../../assets/images/images";
import { formatDate } from "../../utils/arrayUtils";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../components/dropDown/Notificationdropdown";
import clock from "../../assets/images/clock.svg"
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader } from "../../components/loader/Loader";
import { EVENT_CONSTANTS, MODULE_LABELS_CUSTOMER,MODULE_LABELS_INTERNAL } from "../../utils/constants/notificationConstants";

const Reminder = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const overlayRef = useRef(null);
  let timer = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(timer.current); 
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timer.current = setTimeout(() => {
      setIsOpen(false);
    }, 700);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current); 
    };
  }, []);

  React.useImperativeHandle(ref, () => ({
    handleMouseEnter,
  }));

  const handlereminder = (data) => { 
    setIsOpen(false);
    const payload = {
      docId: props.data.id,
      reminderHours: data
    };
    
    props.RemindMe(payload)
      .then((result) => {
        if (result.status) {
             props.getEventNotificationList(null,props?.dropDownOption);
        } else {
          // Handle failure
        }
      })
      .catch((error) => {
        // Handle error
      });

  };

  const reminderdata = [
    {
      time: "Remind me Tomorrow",
      value:"24"
    },
    {
      time: "Remind me in 3 Hours",
      value:"3"
    },
  ];

  return (
    <div
      ref={overlayRef}
      className={styles.mainreminder}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.userIcon}>
        <img className={styles.clockIcon} src={props.isReminder ? IMAGES.clock :IMAGES.clockblack } alt="notification" />
      </div>

      {isOpen && (
        <div className={styles.reminderInnerbox}>
          {reminderdata.map((data, index) => (
            <div
              className={styles.reminderoption}
              onClick={() => handlereminder(data.value)}
              key={index}
            >
              {data.time}
            </div>
          ))}
        </div>
      )}
    </div>
  );
});


const Notification = React.forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const overlayRef = useRef(null);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useImperativeHandle(ref, () => ({
    handleClick,
  }));

  let count = props?.dropdownOptions?.data?.length > 0 ? props.dropdownOptions.data[0].count : 0;

  const [selectedOption, setSelectedOption] = useState(() => {
    return props.dropdownOptions?.data?.length > 0 ? props.dropdownOptions.data[0] : null;
    });
  const dropDownOption = selectedOption?.value;
  
    useEffect(() => { 
      props.NotificationOptions();
    }, []);
  
    // useEffect(() => {
    //   if (props.dropdownOptions.data && props.dropdownOptions.data.length > 0) {
    //     setSelectedOption(props.dropdownOptions.data[0]);
    //   }
    // }, [props.dropdownOptions]);
  
    useEffect(() => {
      if(selectedOption){
        props.getEventNotificationList(null, selectedOption?.value);
      }
    }, [selectedOption]);

    useEffect(()=>{
      if (props.dropdownOptions.data && props.dropdownOptions.data.length > 0) {
        setSelectedOption(props.dropdownOptions.data[0]);
      }
      props.getEventNotificationList(null, selectedOption?.value);
    },[isOpen])
  
    const handleChange = (option) => {
      setSelectedOption(option);
    };
  
  const navigate = useNavigate();
  const goToServiceDetails = (requestId, detailType) => {
    switch (detailType) {
      case "DEMANDS":
        navigate(`/demands/${requestId}`);
        break;
      case "INCIDENTS":
        navigate(`/incidentDetail/${requestId}`);
        break;
      case "SERVICE_REQUEST":
        navigate(`/ServiceRequestDetail/${requestId}`);
        break;
      case "CUSTOMER_ONBOARDING":
        navigate(`/OnboardingRequestDetail/${requestId}`);
        break;
      case "CUSTOMER_ONBOARDING_APPROVAL":
        navigate(`/OnboardingDetail/${requestId}`);
        break;
      case "CUSTOMER_USERS":
        navigate(`/user-management/${requestId}`);
        break;
      case "CUSTOMER_DETAILS":
        navigate(`/user-management?customerId=${encodeURIComponent(requestId)}`);
        break;
      default:
        console.error("Invalid detailType");
    }
  };

  const handleReadNotification = (requestId) => {
    const payload = {
      read: true,
    };
    props.ReadNotification(payload, requestId)
      .then((result) => {
        if (result.status) {
          // Handle success
        } else {
          // Handle failure
        }
      })
      .catch((error) => {
        // Handle error
      });
  };

  const handleClicknotification = (data) => {
    if (data) {
      goToServiceDetails(
        data?.additionalInfo?.detailsId,
        data?.additionalInfo.detailsPageName
      );
      handleReadNotification(data.id);
      props.getEventNotificationList(null,null);
      props.NotificationOptions();
       handleClick()
    }
  };
 let userType = props?.myRights?.userType

  const sortedNotifications = Array.isArray(props.eventnotification.data)
    ? [...props.eventnotification.data].sort((a, b) => {
      if (a.read === b.read) {
        return b.createdAt - a.createdAt;
      }
      return a.read - b.read;
    })
    : [];

    const fetchData = () => {
      if (props.eventnotification.next) {
        props.getEventNotificationList(props.eventnotification.next,selectedOption?.value);
      }
    };

  return (
    <div ref={overlayRef}>
      <button className={styles.applybtn} onClick={handleClick}>
        <div className={styles.icon}>
          <div><img src={IMAGES.notification} className={styles.notificationicon} alt="notification" /></div>
          {count !== 0 && <div className={styles.count}>{count}</div>}
        </div>
      </button>

      {isOpen && (
        <div className={styles.overlayInnerbox}>
          <div className={styles.headerContainer}>
            <div className={styles.dropdown}>
              <Dropdown
                options={props?.dropdownOptions?.data}
                value={selectedOption}
                onChange={handleChange}
                userType={userType}
              />
            </div>
          </div>
          <div className={styles.notificationContainer} id="childScroll">
          {props.eventnotification.isLoading&&props.eventnotification.data.length !== 0&&(<Loader />)}
          {props.eventnotification.isLoading && props.eventnotification.data.length == 0 ? (
                  <Loader />
                ) : props.eventnotification.data.length == 0 ? (
                  <div className={styles.Emptydiv}>No New Notifications Found</div>
                ) : (
                  <InfiniteScroll
                    dataLength={props.eventnotification?.data?.length}
                    next={() => {
                      fetchData();
                    }}
                    hasMore={props.eventnotification.next}
                    loader={<Loader />}
                    scrollableTarget="childScroll" 
                  >
                      <div>
                      {sortedNotifications?.map((data) => (
                        <div
                          key={data?.notificationEventId}
                          className={styles.block}
                          style={{ backgroundColor: data?.read ? "#FFFFFF" : "#F9F9F9" }}               
                        >
                          <div className={styles.mainblock}>
                            <div className={styles.newmessage}>
                            {!data?.read &&  <div className={styles.new}></div>}
                            </div>
                            <div className={styles.blockinner}>
                              <div className={styles.blocktop}>
                                <div>
                                  <span className={data.read ?styles.id :styles.idread}   onClick={() => handleClicknotification(data)}>{data?.additionalInfo?.actionId ?data?.additionalInfo?.actionId :data?.additionalInfo?.detailsPageName} </span>
                                  <span className={data.read ?styles.status:styles.statusread}>- {EVENT_CONSTANTS[data.additionalInfo?.eventAction]}</span>
                                </div>
                                <div>
                               <Reminder isReminder={data.isReminder} dropDownOption={dropDownOption} data={data} RemindMe={props.RemindMe} getEventNotificationList={props.getEventNotificationList}/>
                                </div>
                              </div>
                              <div className={data.read ? styles.notificationmessage : styles.notificationread }>{data.message}</div>
                              <div className={styles.modulename}>
                               {dropDownOption == 'all' &&   <div className={styles.module}>{userType === "customer" ? MODULE_LABELS_CUSTOMER[data.moduleName] : MODULE_LABELS_INTERNAL[data.moduleName]} </div>}
                                <div className={styles.module}>{formatDate(data.createdAt)}</div>
                              </div>
          
                            </div>
                          </div>
                        </div>
          
                      ))}
                    </div>
                   
                  </InfiniteScroll>
                )}
                </div>
        </div>
      )}
    </div>
  );
});

export default Notification;
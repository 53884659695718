import { BASE_URL, POST, GET, GET_MODULE_NOTIFICATION,  } from "../constants"
import { statusParsing, jsonParsing } from "../utils"
import { getAccessToken } from "../../authActions/authActions"


  
export const fetchModuleNotification = async (moduleName) => {
  const token = await getAccessToken()
  const url = `${BASE_URL}${GET_MODULE_NOTIFICATION}?module=${moduleName}`
  return fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userauth: `bearer ${token}`
    }
  })
    .then(statusParsing)
    .then(jsonParsing)
}
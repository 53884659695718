import { BASE_URL, GET, ONBOARDING_APPROVAL } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchOnBoardingApprovalList = async (next,status="") => {
  const token = await getAccessToken();
  let url = `${BASE_URL}${ONBOARDING_APPROVAL}`;

let array = [
  {
    key:"next",
    value:next
  },
  {
    key:"status",
    value:encodeURIComponent(status)
  },
]  

let queryString=``;
array.forEach((item)=>{
  if(item.value){
    if(queryString){
      queryString=queryString+`&${item.key}=${item.value}`
    }else{
      queryString=`${item.key}=${item.value}`
    }
  }
})
if(queryString){
  url=`${url}?${queryString}`;
  url = url.replace ("filter=&", "");
}
  return fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      userAuth: `bearer ${token}`,
    },
  })
    .then(statusParsing)
    .then(jsonParsing);
};

import { FETCH_MODULE_NOTIFICATION } from "../../actions/actionTypes";

const initialState = {
    data: [],
    isLoading: false,
    next: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {    
           case FETCH_MODULE_NOTIFICATION.REQUEST:
               return { ...state, loading: true };
           case FETCH_MODULE_NOTIFICATION.FAILURE:
               return { ...state, loading: false };
           case FETCH_MODULE_NOTIFICATION.SUCCESS:
               return { ...state, data: action.obj, loading: false };
           default:
               return state;
       }
};

export default reducer;

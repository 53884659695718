import { getBroadCastNotificationList } from "../../../redux/actions/notification/getBroadCastNotificationList.action";
import BroadCastNotification from "./BroadCastNotification";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
   
  return {
    broadCastnotification:state.notifications.broadcastNotification.broadCastnotification.data
 }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBroadCastNotificationList: (id) => {
      return dispatch(getBroadCastNotificationList(id))
    }, 
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadCastNotification);

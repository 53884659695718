import React, { useState } from 'react'
import styles from "./missingFincalist.module.css"
import IMAGES from '../../../assets/images/images'
import SecondaryTooltip from '../../../components/secondaryToolTip/secondaryToolTip'
import { useNavigate } from 'react-router-dom';
import CAN from "../../../casl/can"
import { Action, Resource } from "../../../casl/constants";
import SectionGuard from "../../../hoc/guards/SectionGuard";

function MissingFincalist(props) {
    const [isShowMissingFincaCust, setIsShowMissingFincaCust] = useState(false);
    const navigate = useNavigate();

    const toggleMissingFincaCust=()=>{
        setIsShowMissingFincaCust(!isShowMissingFincaCust)
    }

    const handleCustomerClick = (customerId) => {
        navigate(`/user-management/${customerId}`);
    };
    return (
        <div className={styles.missingfincacust}>
            <div className={styles.missingcusttext}>
                <img className={styles.Vector} src={IMAGES.Vector} alt=""/>
                FinCA code not added for Customers 
                <SecondaryTooltip text={"Click to view pending customers"} positions={['bottom', 'right']} >
                    <img className={styles.missingfincainfo} src={IMAGES.infoCircle} onClick={toggleMissingFincaCust}/>
                </SecondaryTooltip>
            </div>
            {isShowMissingFincaCust && ( 
                <div className={styles.alertmessage}>
                    <div className={styles.alertcontent}>
                        <div className={`${styles.header}`}> 
                          <div className={styles.headertext}>FinCA Code Pending</div>
                          <img className={styles.close} src={IMAGES.close} alt="Close" onClick={toggleMissingFincaCust}/> 
                        </div>
                        <div className={styles.infomsg}>Add FinCA code to view invoices</div>
                        <div className={styles.tableparent}>
                        <div className={`${styles.tableContainer} table-responsive`}>
                          <table className={`table ${styles.customTable}`} style={{margin:0}}>
                            <thead className={styles.tableHeader}>
                              <tr>
                                <th className={styles.tableHeaderCell}><div className={styles.headerContent}>Organisation Legal Name</div></th>
                              </tr>
                            </thead>
                            <tbody className={styles.tabledata}>
                              {props?.missingFincaCustomers?.length===0?(<tr className={styles.nodata}>
                                 <td colSpan={1} className={styles.filterdata}>No Customers Found</td>
                              </tr>):
                              props?.missingFincaCustomers?.sort((a, b) => a?.displayName?.localeCompare(b?.displayName))?.map((item,rowIndex)=>{
                                // const customerId = encodeURIComponent(item.uniqueCustomerRefId);
                                return(
                                  <tr className={`${rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow}`}>
                                   <td  className={`${styles.tableCell}`}>{item.displayName}
                                   {/* <Link className={styles.link} to={`/user-management?customerId=${customerId}`}> */}
                                   <SectionGuard canAccess={CAN(Action.UPDATE,Resource.CUSTOMERS)}>
                                     <img className={styles.arrowdown} src={IMAGES.arrows} alt="arrow" onClick={() => handleCustomerClick(item.uniqueCustomerRefId)}/>
                                   </SectionGuard>
                                   {/* </Link> */}
                                   </td>
                                  </tr>
                                );
                                })}
                            </tbody>
                           </table>
                        </div>
                        </div>
                    </div> 
                </div>
            )}
        </div>
    )
}

export default MissingFincalist

import {combineReducers} from "redux";
import  eventNotification from "./eventNotificationList.reducer";
import  broadcastNotification from "./broadcastNotificationList.reducer";
import  notificationOptions from "./notificationDropdown.reducer";
import  moduleNotification from "./moduleNotification.reducer";
export default combineReducers({
    eventNotification,
    broadcastNotification,
    notificationOptions,
    moduleNotification
})
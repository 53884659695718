import { Sidebar } from "../../../../components/sidebar/Sidebar";
import styles from "./NewCustomer.module.css";

import React, { useEffect, useId, useState } from "react";
import { Formik, Field, Form, ErrorMessage, useField } from "formik";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import { FormTextArea, TextInput } from "../../../../components/input/inputs";
import * as Yup from "yup";
import { Submit, BackButton } from "../../../../components/button/buttons";
import FileAttachment from "../../../../components/fileAttachment/fileAttachment.container";
import { DetailContainer } from "../../../../components/comment/comment";
import Asterisk from "../../../../components/asterisk/Asterisk";
import IMAGES from "../../../../assets/images/images";
import * as DOMPurify from "dompurify";
import Tooltip from "../../../../components/toolTip/toolTip";
import DropDown from "../../../../components/dropDown/DropDown";
import { SearchField } from "../../../../components/search/search";
import CustomerViewer from "../list/CustomerViewer";
import { useNavigate, Link } from "react-router-dom";
import { Incident_Service_Type_Values } from "../../../../utils/constants/incidentConstants";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import { FUNCTIONAL_ROLES_CONSTANTS } from "../../../../utils/constants/userConstants";
import { capitalizeWords } from "../../../../utils/utils";
import { columnConfig, headers, UserManagementTab } from "../../../../utils/constants/userManagementConstant";
import CAN from "../../../../casl/can";
import { Action, Resource } from "../../../../casl/constants";
import { formatDate } from "../../../../utils/arrayUtils";
import Table from "../../../../components/table/Table"
import { Loader } from "../../../../components/loader/Loader";

const ButtonWrapper = ({ children }) => {
  return <div className={styles.buttonContainer}>{children}</div>;
};
export const Wrapper = ({ children }) => {
  return <div className={styles.wrapperContainer}>{children}</div>;
};

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldWrapper}>{children}</div>;
};

const RadioField = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="radio"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const CustomerNotFound = () => {
  return (
    <div className={styles.customerNotFound}>
      <div className={styles.customerNottxt}> No Customer Found</div>
      <div className={styles.customerNotFoundtxt}>
        Would you like to onboard a new customer?
      </div>
    </div>
  );
};
const CustomerList = (props) => {
  const navigate = useNavigate();

  function keyValueExistsInArray(arr, keyToCheck, valueToCheck) {
    for (const element of arr) {
      if (element[keyToCheck] === valueToCheck) {
        return true;
      }
      if (Array.isArray(element.accountManagers)) {
        if (keyValueExistsInArray(element.accountManagers, keyToCheck, valueToCheck)) {
          return true;
        }
      }
    }
    return false;
  }

  const gotoDetail = (item) => {
    let super_admin = props?.myRights?.functionalRoles[0]===FUNCTIONAL_ROLES_CONSTANTS["super_admin_internal"]?.value
    const result = keyValueExistsInArray(item.serviceSpecificDetails, "email", props.myEmail)
    if (result || super_admin) {
      navigate(`/user-management?customerId=${encodeURIComponent(item.uniqueCustomerRefId)}`)
    } else {
      navigate(`/AccessApprovalDetail/${item.id}`)
    }
  };

  return (
    <div>
      <div className={styles.containerheader}>Existing Onboarded Customers</div>
      {/* <div className={styles.headnote}>
        <img className={styles.infocircle} src={IMAGES.infoCircle} />
        Select the customer for whom you would like to add a service.
      </div> */}
      {props.list?.map((item, key) => (
        <div className={styles.containers} key={key}>
          <div className={styles.headsearch}>
            <div className={styles.orgnamehead}>{item?.displayName}</div>
            <div className={styles.subhead}>
              <img onClick={() => gotoDetail(item,props?.myRights)} src={IMAGES.chevronredright} />
              {/* <Link
                to={`/AccessApprovalDetail
               `}
              >
                <img src={IMAGES.chevronredright} alt="" />
              </Link> */}
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Service Type</div>
                <div className={styles.sub}>
                  {item.serviceSpecificDetails
                    ?.map((detail) => detail.serviceType.label)
                    .join(" & ")}
                </div>
              </div>
            </div>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Organisation Legal Name</div>
                <div className={styles.sub}>{item?.displayName}</div>
              </div>
            </div>
            <div className={styles.parentDiv}>
              <div className={styles.headData}>
                <div className={styles.headSub}>Onboarded On</div>
                <div className={styles.sub}>
                  {formatDate(item?.createdAt)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const Search = ({ label, placeholder, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? { ...styles.inputError, width: "250px" }
    : { width: "250px" };

  return (
    <FieldWrapper>
      <div>
        <Label text={label} style={styles.labelcompLevel} /> <Asterisk />
      </div>
      <div className={styles.parent}>
      <SearchField
        {...field}
        {...props}
        placeholder={placeholder}
        style={styles.additionalStyle}
        onChange={(e) => {
          helpers.setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
        <img className={styles.serchicon} src={IMAGES.searchicon} alt="" />
      </div>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const CheckBox = ({ name, value, label, onChange, checked }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const ImageUpload = ({
  setFieldValue,
  value,
  label = "Supporting Attachments",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={false} style={styles.labelcompLevel} />
      </div>
      <FileAttachment
        name={props.name}
        value={value}
        setFieldValue={setFieldValue}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextField = ({ label, placeholder,fincaTadig, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError ? styles.inputError : null;
  const [showFinca, setShowFinca] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOperatorCode, setSelectedOperatorCode] = useState('');
  const showFincaDetails = () => {
    setShowFinca(!showFinca);
    setSelectedOperatorCode('');
  }
  const handleSearchChange = (event) => {
    event.preventDefault()
    setSearchTerm(event.target.value);
  };
  const handleRowClick = (operatorCode) => {
    setSelectedOperatorCode(operatorCode)
  };
  const filteredData = fincaTadig?.filter((item) =>
    item?.fincaTADIG?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    item?.orgName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const mapdata = filteredData?.map((item)=>{
    return{
      "Operator Code":(item.fincaTADIG),
      "Operator Name":(item.orgName)
    };
})
  const addCode = (e) => {
    e.preventDefault();
    helpers.setValue(selectedOperatorCode);
    setShowFinca(false);
  }
  return (
    <FieldWrapper>
      <div>
        {props.name==="fincaId"&&<span className={styles.statusLegendsIcon}></span>}<Label text={label} style={styles.labelcompLevel} />
        {showFinca && ( 
        <div className={styles.alertmessage}> 
         <div className={styles.alertcontent}> 
          <div className={`${styles.header}`}> 
            <div className={styles.headertext}>Add FinCA Code</div>
            <img className={styles.close} src={IMAGES.close} alt="Close"   onClick={showFincaDetails}/> 
          </div>
          <div className={styles.infomsg}>FinCA codes has been generated by FinCA team. Kindly align with the team if the code is not right.</div>
          <div className={styles.inputfieldcontainer}>
            <input
              type="text"
              placeholder="Search by Operator Code or Name..."
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchInput}
            />
            <div className={styles.searchwrapper}>
              <img className={styles.serchimage} src={IMAGES.searchimage} alt="Search" />
            </div>
          </div>
          <div className={styles.tableparent}>
              <Table
                headers={headers}
                data={mapdata}
                maxHeight="300px"
                width="100%"
                columnConfig={columnConfig}
                nodata="No Code Found"
                onRowClick={handleRowClick}
                isClisk = {true}
              />
          </div>
          <div className={styles.footerfinca}>
            <button className={`${styles.addcode} ${selectedOperatorCode?.trim()===""?styles.disablebgtn:""}`} disabled={selectedOperatorCode?.trim()===""} onClick={(e)=>{addCode(e)}}>Add Code</button>
          </div>
         </div> 
        </div> )}
        {props.isMandatory ? <Asterisk /> : <></>}  
        {props.toltip ? (
          <span className={styles.toltip}>
              <Tooltip text={props.toltipText}  customstyle={styles.toltip}/>
          </span>
        ) : (
          <></>
        )}
      </div>
      <TextInput
        {...field}
        {...props}
        placeholder={placeholder}
        style={inputStyle}
      //   onChange={props.onChange}
      />
      {props.name==="fincaId"&&(
        <div className={styles.icon}>
         <span className={styles.iconparent} onClick={showFincaDetails}>
           <img className={styles.questionicon} src={IMAGES.questionicon} alt="" />
         </span>
        </div>
      )}
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const SelectField = ({ title, options, ...props }) => {
  let _options = options.map((item) => ({
    value: item.value,
    label: item.label,
  }));
  const [field, meta, helpers] = useField(props);

  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
        <Label
          text={title}
          style={`${styles.labelcompLevel} ${props.additionalStyle}`}
        />
        <span className={styles.circlered}>
          <span className={styles.tooltip}>
            <Asterisk />
          </span>
        </span>
      </div>
      <DropDown
        options={_options}
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          props.onChange(value);
          helpers.setValue(value);
        }}
        value={
          _options && field.value
            ? _options.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const TextArea = ({ label, optional = false, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && meta.error ? true : false;
  const inputStyle = hasError
    ? `${styles.input} ${styles.inputError}`
    : styles.input;
  return (
    <FieldWrapper>
      <div className={styles.labelcomment}>
        <Label text={label} optional={optional} style={styles.labelcompLevel} />
      </div>
      <FormTextArea {...field} {...props} style={styles.additionalStyle} />
      <span className={styles.textLimitInfo}>{props.textLimit}</span>
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};

const NewCustomer = (props) => {
  const [show, setShow] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [continueClicked, setContinueClicked] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formValues, setFormValues] = useState(null);

  let failuerTypeDropdownDropObj = {
    width: "300px",
  };

  const YesContinue = (resetForm, values, preserveFields = []) => {
    resetFormValues(resetForm, values, preserveFields);
    setContinueClicked(true);
  };

  const resetFormValues = (resetForm, values, preserveFields = []) => {
    resetForm({
      values: {
        organisationLegalName: preserveFields.includes("organisationLegalName") ? values.organisationLegalName : "",
        serviceType: preserveFields.includes("serviceType") ? values.serviceType : "",
        tadigCode: "",
        fincaId: null,
        primaryContactName: "",
        primaryEmailAddress: "",
        accountManager: "",
        accountManagerEmail: "",
        primaryContactNameivc: "",
        primaryEmailAddressivc: "",
        accountManagerivc: "",
        accountManagerEmailivc: "",
        accountAbbr: "",
        accountNumber: "",
        additionalInformation: "",
        files: [],
      }
    });
  };

  const filteredList = props?.list?.filter((item) => {
    return item?.displayName?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const accountManager = props?.accountManagerList.map((item) => ({
    value: item.email,
    label: item.displayName,
  }));

  useEffect(() => {
    props.getAccountManagerList();
    if(CAN(Action.LIST, Resource.FINCA_TADIG)){
      props.getFincaTadig()
    }
  }, []);

  useEffect(() => {
    setContinueClicked(false)
  }, [searchQuery]);

  const close = () => {
    setShow(false);
    setSearchQuery("");
    setContinueClicked(false);
  };
  
  const back = () =>{
    setContinueClicked(false);
  }

  const validationSchema = Yup.object().shape({
    organisationLegalName: Yup.string().required(" Organisation Legal Name is Required"),
    serviceType: Yup.string().required("Kindly choose service type."),
    tadigCode: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("TADIG Code is required"),
    }),
    primaryContactName: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("Customer user name is required"),
    }),
    primaryEmailAddress: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("Customer Email address is required"),
    }),
    accountManager: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IPX_Service,
      then: () => Yup.string().required("Account Manager is required"),
    }),

    primaryContactNameivc: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Customer user name is required"),
    }),
    primaryEmailAddressivc: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Customer Email address is required"),
    }),
    accountManagerivc: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Account Manager is required"),
    }),
    accountAbbr: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("accountAbbr is required"),
    }),
    accountNumber: Yup.string().when("serviceType", {
      is: (val) => val && val == Incident_Service_Type_Values.IVC_Service,
      then: () => Yup.string().required("Account Number is required"),
    }),
    additionalInformation: Yup.string().max(1000, "Text must be at most 1000 characters"),
    files: Yup.array().max(3, "You can upload up to 3 files"),
  });


  const getAccountManagerName = (email) => {
    let accountManagerName = accountManager.filter(
      (item) => item.value == email
    );
    return accountManagerName[0].label;
  };

  const AccountManagerNote =({myRights})=>{
    const handleClick = ()=>{
      props.setActivePrimaryTab(UserManagementTab.VF_GROUP_USER)
      props.redirectTab(UserManagementTab.VF_GROUP_USER)
      close();
    }
    return(
      <>
         {myRights?.functionalRoles[0]===FUNCTIONAL_ROLES_CONSTANTS["super_admin_internal"]?.value &&(
            <span className={styles.amnote}>If you don't find the Account Manager you want to assign, please onboard them first on the platform from <span className={styles.navigatevf} onClick={handleClick}>here.</span></span>
         )}
         {myRights?.functionalRoles[0]===FUNCTIONAL_ROLES_CONSTANTS["account_manager_internal"]?.value &&(
           <span className={styles.amnote}>If you don't find the Account Manager you want to assign, please contact the Super Admin.</span>
         )}
      </>
    )
  }

  const handleOkClick = () => {
    setIsSubmit(true);
    const values = formValues; // Use stored form values
    let obj = {
      displayName: values.organisationLegalName,
      uniqueCustomerRefId: null,
      requestType: "new_onboarding",
      requestDetails: [],
      additionalInfoText: values.additionalInformation,
      additionalInfoAttachments: values.files,
    };

    if (values.serviceType == Incident_Service_Type_Values.IVC_Service) {
      obj.requestDetails.push({
        cpName: null,
        serviceType: values.serviceType,
        accountAbbr: values.accountAbbr,
        accountNumber: values.accountNumber,
        customerPrimaryContact: [
          {
            name: values.primaryContactNameivc,
            email: values.primaryEmailAddressivc,
          },
        ],
        customerAccountManagers: [
          {
            name: getAccountManagerName(values.accountManagerivc),
            email: values.accountManagerEmailivc,
          },
        ],
      });
    } else if (values.serviceType == Incident_Service_Type_Values.IPX_Service) {
      obj.requestDetails.push({
        cpName: values.tadigCode,
        fincaTadigCode: values.fincaId,
        serviceType: values.serviceType,
        customerPrimaryContact: [
          {
            name: values.primaryContactName,
            email: values.primaryEmailAddress,
          },
        ],
        customerAccountManagers: [
          {
            name: getAccountManagerName(values.accountManager),
            email: values.accountManagerEmail,
          },
        ],
      });
    }
    props
      .submitRequest(obj)
      .then((result) => {
        if (result.status) {
          props.openAlertPopup({
            message: `Customer onboarding request with ID: ${result?.res?.data?.requestId} has been sent to the Commercial Manager!`,
            image: IMAGES.success,
          });
          setIsSubmit(false);
          setFormValues(null);
          setShowConfirmation(false);
          close();
        } else {
          let error_msg = result?.error?.message ?? result.error;

          props.openAlertPopup({
            message: error_msg,
            image: IMAGES.error,
          });
          setIsSubmit(false);
          setShowConfirmation(false);
          close();
        }
      })
      .catch((error) => {
        props.openAlertPopup({
          message: "Something went wrong.",
          image: IMAGES.error,
          //  isFormOpen: true,
        });
        setIsSubmit(false);
        setShowConfirmation(false); // Close the confirmation popup
        close();
      });
  };

  const handleCancelClick = () => {
    setShowConfirmation(false);
    setIsSubmit(false)
  };

  return (
    <>
      <button onClick={() => setShow(true)} className={styles.continue}>
        Add New Customer
      </button>
      {showConfirmation && ( 
        <div className={styles.alertmessage}> 
          <div className={styles.cnfcontent}> 
            <div className={`${styles.header}`}> 
              <div className={styles.confirmheader}>Confirm Details</div>
            </div>
            <div className={styles.infomsgs}>
            Before submitting the form, Kindly ensure <span className={styles.cnfmsg}>{formValues?.serviceType===Incident_Service_Type_Values.IPX_Service&&`TADIG Code and FinCA Code`}{formValues?.serviceType===Incident_Service_Type_Values.IVC_Service&&`Account Abbreviation and Account Number`}</span> for <span className={styles.cnfmsg}>{formValues.organisationLegalName}</span> are correct to avoid any discrepancies.
              {/* <div className={styles.cnfparent}>
                {formValues?.serviceType===Incident_Service_Type_Values.IPX_Service&&(
                  <>
                  <div className={`${styles.fincaco} ${styles.borderbtm}`}>TADIG Code</div>
                  <div className={`${styles.fincaval} ${styles.borderbtm}`}>{formValues.tadigCode}</div>
                  <div className={`${styles.fincaco} ${styles.bkcolor}`}>FinCA Code</div>
                  <div className={`${styles.fincaval} ${styles.bkcolor}`}>{formValues.fincaId?formValues.fincaId :"---"}</div>
                  </>
                )}
                 {formValues?.serviceType===Incident_Service_Type_Values.IVC_Service&&(
                  <>
                  <div className={`${styles.fincaco} ${styles.borderbtm}`}>Account Abbreviation</div>
                  <div className={`${styles.fincaval} ${styles.borderbtm}`}>{formValues.accountAbbr}</div>
                  <div className={`${styles.fincaco} ${styles.bkcolor}`}>Account Number</div>
                  <div className={`${styles.fincaval} ${styles.bkcolor}`}>{formValues.accountNumber}</div>
                  </>
                )}
              </div> */}
              <div className={styles.cfparent}>
              {formValues?.serviceType===Incident_Service_Type_Values.IPX_Service&&(
                <>
                  <div className={`${styles.ipxcont} ${styles.borderbtm}`}>
                    <div className={styles.ipxcontlabel}>TADIG Code</div>
                    <div className={styles.ipxcontval}>{formValues.tadigCode}</div>
                  </div>
                  <div className={`${styles.ipxcont} ${styles.bkcolor}`}>
                    <div className={styles.ipxcontlabel}>FinCA Code</div>
                    <div className={styles.ipxcontval}>{formValues.fincaId?formValues.fincaId :"---"}</div>
                  </div>
                </>  
                )}
                 {formValues?.serviceType===Incident_Service_Type_Values.IVC_Service&&(
                    <>
                    <div className={`${styles.ipxcont} ${styles.borderbtm}`}>
                      <div className={styles.ipxcontlabel}>Account Abbreviation</div>
                      <div className={styles.ipxcontval}>{formValues.accountAbbr}</div>
                    </div>
                    <div className={`${styles.ipxcont} ${styles.bkcolor}`}>
                      <div className={styles.ipxcontlabel}>Account Number</div>
                      <div className={styles.ipxcontval}>{formValues.accountNumber}</div>
                    </div>
                  </> 
                )}
              </div>
            </div>
            <div className={styles.confirmfooter}>
              <button className={styles.cancelButton} onClick={handleCancelClick}>Cancel</button>
              {!isSubmit?<button className={styles.okButton} onClick={handleOkClick}>Submit</button>:<div className={styles.cnfloader}><Loader/></div>}
            </div>
          </div> 
        </div> 
      )}
      {show && (
        <Sidebar
          isOpen={show}
          headerTxt="Add New Customer"
          onClose={close}
          sideNavStyle={styles.sideNavStyle}
        >
          <div>
            <Formik
              validationSchema={validationSchema}
              initialValues={{
                serviceType: "",
                organisationLegalName: "",
                tadigCode: "",
                fincaId:null,
                primaryContactName: "",
                primaryEmailAddress: "",
                accountManager: "",
                accountManagerEmail: "",
                primaryContactNameivc: "",
                primaryEmailAddressivc: "",
                accountManagerivc: "",
                accountManagerEmailivc: "",
                accountAbbr: "",
                accountNumber: "",
                additionalInformation: "",
                files: [],
              }}
              // onSubmit={async (values, { resetForm }) => {
              //   setIsSubmit(true);
              //   let obj = {
              //     displayName: values.organisationLegalName,
              //     uniqueCustomerRefId: null,
              //     requestType: "new_onboarding",
              //     requestDetails: [],
              //     additionalInfoText: values.additionalInformation,
              //     additionalInfoAttachments: values.files,
              //   };

              //   if (values.serviceType == Incident_Service_Type_Values.IVC_Service) {
              //     obj.requestDetails.push({
              //       cpName: null,
              //       serviceType: values.serviceType,
              //       accountAbbr: values.accountAbbr,
              //       accountNumber: values.accountNumber,
              //       customerPrimaryContact: [
              //         {
              //           name: values.primaryContactNameivc,
              //           email: values.primaryEmailAddressivc,
              //         },
              //       ],
              //       customerAccountManagers: [
              //         {
              //           name: getAccountManagerName(values.accountManagerivc),
              //           email: values.accountManagerEmailivc,
              //         },
              //       ],
              //     });
              //   } else if (values.serviceType == Incident_Service_Type_Values.IPX_Service) {
              //     obj.requestDetails.push({
              //       cpName: values.tadigCode,
              //       fincaTadigCode: values.fincaId,
              //       serviceType: values.serviceType,
              //       customerPrimaryContact: [
              //         {
              //           name: values.primaryContactName,
              //           email: values.primaryEmailAddress,
              //         },
              //       ],
              //       customerAccountManagers: [
              //         {
              //           name: getAccountManagerName(values.accountManager),
              //           email: values.accountManagerEmail,
              //         },
              //       ],
              //     });
              //   }
              //   console.log("obj",obj)
              //   return 
              //   props
              //     .submitRequest(obj)
              //     .then((result) => {
              //       if (result.status) {
              //         props.openAlertPopup({
              //           message: `Customer onboarding request with ID: ${result?.res?.data?.requestId} has been sent to the Commercial Manager!`,
              //           image: IMAGES.success,
              //         });
              //         setIsSubmit(false);
              //         resetForm();
              //         close();
              //         // props.getServiceList();
              //       } else {
              //         let error_msg =
              //           result?.error?.message ?? result.error;

              //         props.openAlertPopup({
              //           message: error_msg,
              //           image: IMAGES.error,
              //           // isFormOpen: true,
              //         });
              //         setIsSubmit(false);
              //         close();
              //       }
              //     })
              //     .catch((error) => {
              //       props.openAlertPopup({
              //         message: "Something went wrong.",
              //         image: IMAGES.error,
              //         //  isFormOpen: true,
              //       });
              //       setIsSubmit(false);
              //       close();
              //     });
              // }}
              onSubmit={(values, { resetForm }) => {
                setFormValues(values);
                setShowConfirmation(true);
              }}
            >
              {({
                isValid,
                dirty,
                values,
                setFieldValue,
                resetForm,
                handleChange,
              }) => (
                <>
                  <Form  className={`${styles.form} ${styles.scroll}`} id="Add_Customer"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && e.target.tagName!=="TEXTAREA") {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Search
                      label="Organisation Legal Name"
                      placeholder="Search customer by typing their organisation legal name"
                      name="organisationLegalName"
                      customStyles={failuerTypeDropdownDropObj}
                      onChange={(value)=>{
                        setFieldValue("organisationLegalName",capitalizeWords(value));
                        setSearchQuery(value);
                      }}
                    />
                    {!searchQuery && (


                      <div className={styles.initalinfo}>
                        <div className={styles.initalinfohead}>
                          How to onboard a new customer?
                        </div>
                        <div className={styles.containerinfo}>
                          <div className={styles.dflexs}>
                            <div className={styles.redflex}>
                              <div className={styles.redin}></div>
                              <div className={styles.blackin}></div>
                            </div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.black}></div>
                            <div className={styles.redflex}>
                              <div className={styles.redin}></div>
                              <div className={styles.blackin}></div>
                            </div>
                          </div>
                          <div className={styles.dflex}>
                            <div className={styles.initalinfotext}>
                              <span className={styles.userinfosearch}> <img className={styles.userinfosearchimg} src={IMAGES.userinfosearch} /> </span>
                              1. Search For Existing Customer
                              <li className={styles.info}>
                                Before onboarding a new customer, search by their
                                organisation’s legal name.
                              </li>
                            </div>
                            <div className={styles.initalinfotext}>
                              <span className={styles.userinfosearch}> <img className={styles.userinfosearchimg} src={IMAGES.userinfosearch} /> </span>

                              2. Create Account
                              {/* <li className={styles.info}>
                            For existing customer, request access to update
                            their information.
                          </li> */}
                              <li className={styles.info}>
                                If customer does not exist, create a new customer
                                account.
                              </li>
                            </div>
                          </div>
                        </div>
                      </div>
                      // <div>
                      //   <img
                      //     className={styles.onboardinfoimg}
                      //     src={IMAGES.onboardinfo}
                      //     alt=""
                      //   />
                      // </div>
                    )}
                    {searchQuery && (
                      <>
                        {!continueClicked && filteredList?.length > 0 ? (
                          <div className={styles.containerlist}>
                            <CustomerList list={filteredList} myEmail={props.myRights.email} myRights={props.myRights} />
                          </div>
                        ) : (
                          !continueClicked && <CustomerNotFound />
                        )}
                        {continueClicked && (
                          <>
                            <div className={styles.chackboxtxt}>
                              Choose Service Type{" "}
                              <Tooltip
                                text={"Choose Service Type"}
                              />
                              <div></div>
                            </div>
                            <div className={styles.chackboxmain}>
                              <div  onClick={()=>{resetFormValues(resetForm, values, ["organisationLegalName","serviceType"])}}>
                                <RadioField name="serviceType" value={Incident_Service_Type_Values.IPX_Service} label={"IPX Services"} />
                              </div>
                              <div  onClick={()=>{resetFormValues(resetForm, values, ["organisationLegalName","serviceType"])}}>
                                <RadioField name="serviceType" value={Incident_Service_Type_Values.IVC_Service} label={"International Voice Services"} />
                              </div >
                            </div>
                            <ErrorMessage name={"serviceType"}>
                              {(msg) => <ErrorLabel text={msg} />}
                            </ErrorMessage>
                            {values.serviceType == Incident_Service_Type_Values.IPX_Service && (
                              <>
                                 <div className={styles.select}>
                              <div className={`${styles.selectwidth} ${styles.dropdownwidths}`}>
                                 <TextField
                                  label="TADIG Code"
                                  name="tadigCode"
                                  type="text"
                                  toltip="true"
                                  toltipText="TADIG Code"
                                  isMandatory={true}
                                  placeholder="Enter TADIG Code"
                                /> </div>
                                  <div className={styles.selectwidth}>
                                 <TextField
                                  label="FinCA Code"
                                  name="fincaId"
                                  type="text"
                                  toltip="true"
                                  toltipText="FinCA ID"
                                  isMandatory={false}
                                  placeholder="Enter FinCA Code"
                                  fincaTadig={props?.fincaTadig?.data}
                                />
                                </div>
                                </div>
                                <div className={styles.fincadesc}><span className={styles.statusLegendsIcon}></span>FinCA code description </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Customer User Name"
                                      name="primaryContactName"
                                      type="text"
                                      isMandatory={true}
                                      placeholder="Enter name"
                                      onChange={(e)=>{
                                        setFieldValue("primaryContactName",capitalizeWords(e.target.value));
                                        }}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Customer User Email Address"
                                      name="primaryEmailAddress"
                                      type="text"
                                      isMandatory={true}
                                      placeholder="Enter email address"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManager"
                                      isMandatory={true}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "accountManagerEmail",
                                          value
                                        );
                                        //handleChange();
                                      }}
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      placeholder="Enter name"
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>

                                    <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmail"
                                      type="text"
                                      isMandatory={true}
                                      value={values.accountManagerEmail}
                                      placeholder=""
                                      readOnly
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <AccountManagerNote myRights={props?.myRights}/>
                              </>
                            )}
                            {values.serviceType == Incident_Service_Type_Values.IVC_Service && (
                              <>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Account Abbreviation"
                                      name="accountAbbr"
                                      type="text"
                                      isMandatory={true}
                                      placeholder="Input text"
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Account Number"
                                      name="accountNumber"
                                      type="text"
                                      isMandatory={true}
                                      placeholder="Input text"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <TextField
                                      label="Customer User Name"
                                      name="primaryContactNameivc"
                                      type="text"
                                      isMandatory={true}
                                      placeholder="Input text"
                                      onChange={(e)=>{
                                        setFieldValue("primaryContactNameivc",capitalizeWords(e.target.value));
                                        }}
                                      
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>
                                    <TextField
                                      label="Customer User Email Address"
                                      name="primaryEmailAddressivc"
                                      type="text"
                                      isMandatory={true}
                                      placeholder="Input text"
                                    />
                                  </div>
                                </div>
                                <div className={styles.select}>
                                  <div
                                    className={`${styles.selectwidth} ${styles.dropdownwidths}`}
                                  >
                                    <SelectField
                                      title={"Account Manager"}
                                      name="accountManagerivc"
                                      isMandatory={true}
                                      options={accountManager}
                                      customStyles={styles.obj}
                                      onChange={(value) => {
                                        setFieldValue(
                                          "accountManagerEmailivc",
                                          value
                                        );
                                        //handleChange();
                                      }}
                                      additionalStyle={styles.additionalStyle}
                                    />
                                  </div>
                                  <div className={styles.selectwidth}>

                                    <TextField
                                      label="Account Manager Email Address"
                                      name="accountManagerEmailivc"
                                      isMandatory={true}
                                      value={values.accountManagerEmailivc}
                                      type="text"
                                      placeholder=""
                                      readOnly
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                                <AccountManagerNote myRights={props?.myRights}/>
                              </>
                            )}
                            {(values.serviceType) && (
                              <>
                                <TextArea
                                  label={"Additional Information"}
                                  id="additionalInformation"
                                  name="additionalInformation"
                                  placeholder="Enter the information"
                                  textLimit={"1000 characters"}
                                />
                                <ImageUpload
                                  name="files"
                                  value={values.files}
                                  setFieldValue={setFieldValue}
                                  optional={false}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Form>
                  <div className={styles.footer}>
                    <div>
                      {continueClicked?(
                        <button className={styles.backButton} onClick={()=>back()}>Back</button>
                      ):(
                        <BackButton
                        className={styles.backButton}
                        style={styles.back}
                        title={"Back"}
                        closePopup={close}
                      />
                      )}
                    </div>
                    <div>
                      {continueClicked ? (
                        <>
                          <Submit
                            id="saveButton"
                            className={styles.saveButton}
                            style={styles.submit}
                            title={"Submit"}
                            form="Add_Customer"
                            isSubmitting={isSubmit}
                            disabled={!dirty || !isValid}
                          />
                        </>
                      ) : (
                        <button
                          className={styles.continuebutton}
                          onClick={()=>{YesContinue(resetForm, values, ["organisationLegalName"])}}
                          disabled={searchQuery?.length === 0}
                        >
                          Create Account
                        </button>
                      )}
                    </div>
                    {/* )}  */}
                  </div>
                </>
              )}
            </Formik>
          </div>
        </Sidebar>
      )}
    </>
  );
};

export default NewCustomer;

import {
    BASE_URL,
    GET,
    LIVE_SERVICES,
    GET_LIVE_SERVICES_FILTER_VALUES,
    DOWNLOAD_Live_SERVICES_LIST,
} from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchLiveServices = async (cp_name,searchKey, filter,customer,serviceType,status) => {
    const token = await getAccessToken();
   let url=`${BASE_URL}${LIVE_SERVICES}`; 
   
   let array = [
    // {
    //   key:"filter",
    //   value:filter
    // },
    {
      key:"searchKey",
      value:encodeURIComponent(searchKey)
    },
    {
      key:"customer",
      value:encodeURIComponent(customer)
    },
    {
      key:"serviceType",
      value:encodeURIComponent(serviceType)
    },
    {
      key:"status",
      value:encodeURIComponent(status)
    },
    {
      key:"cp_name",
      value:cp_name
    }
  ]  

  let queryString=``;
  array.forEach((item)=>{
    if(item.value){
      if(queryString){
        queryString=queryString+`&${item.key}=${item.value}`
      }else{
        queryString=`${item.key}=${item.value}`
      }
    }
  })
  
  if(queryString){
    url=`${url}?${queryString}`;
    url = url.replace ("filter=&", "");
}

    return fetch(url, {
        method: GET,
        headers: {
            "Content-Type": "application/json",
            "userauth": `Bearer ${token}`
        },
    })
        .then(statusParsing)
        .then(jsonParsing);

};

export const fetchLiveServicesFilterValues = async() => {    
    const token = await getAccessToken();
    let resource = `${BASE_URL}${GET_LIVE_SERVICES_FILTER_VALUES}`;    
    return fetch(resource, {
      method: GET,
      headers: {
        "Content-Type": "application/json",
        "userauth" : `Bearer ${token}`
      },
    })
    .then(statusParsing)
    .then(jsonParsing);  
  };

  
export const downloadLiveServiceList = async (filter, searchKey,customer,serviceType,status) => {
  const token = await getAccessToken();
  let url = `${BASE_URL}${DOWNLOAD_Live_SERVICES_LIST}`;
  if (filter) {
    filter = filter.replace("filter=&", "");
  }
  let array = [
    // {
    //   key: "filter",
    //   value: filter
    // },
    {
      key: "searchKey",
      value: encodeURIComponent(searchKey)
    },
    {
      key:"customer",
      value:encodeURIComponent(customer)
    },
    {
      key:"serviceType",
      value:encodeURIComponent(serviceType)
    },
    {
      key:"status",
      value:encodeURIComponent(status)
    },
  ]

  let queryString = ``;
  array.forEach((item) => {
    if (item.value) {
      if (queryString) {
        queryString = queryString + `&${item.key}=${item.value}`
      } else {
        queryString = `${item.key}=${item.value}`
      }
    }
  })

  if (queryString) {
    url = `${url}?${queryString}`;
    url = url.replace("filter=&", "");
  }

  const response = fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userAuth": `bearer ${token}`
    },
  })
    .then(statusParsing)
    .then(jsonParsing);

  return response;

};
import React, { useId, useState, useEffect } from "react";
import {
  ChildContainer,
  Container,
} from "../../../../components/Container/Containers";
import styles from "./CustomerDetail.module.css";
// import NewCustomer from "../add/NewVFUser";
import IMAGES from "../../../../assets/images/images";
import { Banner } from "../../../../components/banner/Banners";
import NewUser from "../addUser/NewUser.container";
import { Field, Form, Formik,ErrorMessage,useFormik } from "formik";
import * as Yup from "yup";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import { Loader } from "../../../../components/loader/Loader";
// import { Alert } from "../../../../components/alert/alerts";
import { FUNCTIONAL_ROLES_CONSTANTS, USER_TYPE } from "../../../../utils/constants/userConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";
import { FormFieldLabel as Label ,FormErrorLabel as ErrorLabel} from "../../../../components/label/Labels";
import AddNewProduct from "./addNewProduct/addNewProduct";
import { headers as fincaheader,columnConfig, ServiceType } from "../../../../utils/constants/userManagementConstant";
import DynamicTable from "../../../../components/dynamicTable/dynamicTable";
import Editmodule from "./addNewProduct/editmodule";
import AddAccountManager from "../addAccountManager/addAccountManager";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SectionGuard from "../../../../hoc/guards/SectionGuard";
import CAN from "../../../../casl/can";
import { Action, Resource } from "../../../../casl/constants";
import { Incident_Service_Type_Values } from "../../../../utils/constants/incidentConstants";
import can from "../../../../casl/can";
import { formatDate } from "../../../../utils/arrayUtils";
import SecondaryTooltip from "../../../../components/secondaryToolTip/secondaryToolTip";
import Table from "../../../../components/table/Table";
import ConfirmationPopup from "../../../../components/confirmationPopup/ConfirmationPopup";


const Switch = ({ isChecked,onClick,cursorstyle }) => {
  return (
    <label className={styles.switch}>
      <SecondaryTooltip text={isChecked ? "Active" : "Inactive"} positions={['bottom', 'right']}>
        <input type="checkbox" checked={isChecked}  onClick={onClick} />
        <span className={`${styles.slider} ${cursorstyle ? styles.pointer : styles.notAllowed}`}></span>
      </SecondaryTooltip>
    </label>
  );
};
const CheckBox = ({ name, value, label }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};
const CheckBoxs = ({ name, value, label, onChange, checked }) => {
  const id = useId();
  return (
    <div className={styles.radioField}>
      <Field
        id={id}
        className={styles.radioInput}
        type="checkbox"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id} className={styles.radioLabel}>
        {label}
      </label>
    </div>
  );
};

const UserList = ({ data, functionalRolesData, updateUserPermissions, openAlertPopup }) => {
  let [isAlert, setIsAlert] = useState(false);
  let [isEditPermission, setIsEditPermission] = useState(false);
  let [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    image: IMAGES.success,
  });

  const validationSchema = Yup.object().shape({
    functionalRoles: Yup.array().min(1),
  });
  let initialValues = {
    functionalRoles:
    data?.functionalRoles && data?.functionalRoles.length > 0 ? data?.functionalRoles : [],
  };

  const editPermission = () => {
    setIsEditPermission(!isEditPermission);
  };

  return (
    <div className={styles.CustomerContainer}>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Contact Name</span>
        <p>{data?.displayName}</p>
      </div>

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Email Address</span>
        <p>{data.email}</p>
      </div>

      {/* <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Mobile Number</span>
        <p>{data.phoneNumber}</p>
      </div> */}

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Onboarded On</span>
        <p>{formatDate(data.createdAt)}</p>
      </div>

      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Status </span>
        <p>
          {""}Inactive{" "}
          <span className={styles.spanSwitch}>
            <Switch isChecked={data.status === "active" ? true : false} />
          </span>
          Active
        </p>
      </div>
      <ExpandableArea>
        <div style={{ width: "100%" }}>
          <div>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              validateOnChange
              onSubmit={async (values, { resetForm }) => {
                values.userType = data.userType;
                setShowLoader(true);
                updateUserPermissions(values, data.uid)
                  .then((result) => {
                    if (result.status) {
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: "User permissions updated successfully.",
                      //   image: IMAGES.success,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: "User permissions updated successfully!",
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    } else {
                      let error_msg = result?.error;
                      setIsEditPermission(!isEditPermission);
                      // setMessage({
                      //   message: error_msg,
                      //   image: IMAGES.error,
                      // });
                      // setIsAlert(true);
                      openAlertPopup({
                        message: error_msg,
                        image: IMAGES.success
                      });
                      setShowLoader(false);
                    }
                  })
                  .catch((error) => {
                    setIsEditPermission(!isEditPermission);
                    // setMessage({
                    //   message: "Something went wrong.",
                    //   image: IMAGES.error,
                    // });
                    // setIsAlert(true);
                    openAlertPopup({
                      message: "Something went wrong.",
                      image: IMAGES.error
                    });
                    setShowLoader(false);
                  });
              }}
            >
              {({ isValid, dirty, submitForm }) => (
                <Form className={styles.form}>
                  <div className={styles.sectionContainer}>
                    <div className={styles.sectionTitle}>Modules Assigned</div>
                    {showLoader ? (
                      <div className={styles.loaderContainer}>
                        <Loader style={styles.loaderContainer} />
                      </div>
                    ) : isEditPermission ? (
                      <div>
                        <img
                          src={IMAGES.save}
                          className={styles.Icon}
                          onClick={submitForm}
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={IMAGES.editIcon}
                          className={styles.Icon}
                          onClick={editPermission}
                        />
                      </div>
                    )}
                  </div>
                  <ul
                    style={{
                      listStyle: "none",
                      paddingInlineStart: 0,
                      display: "grid",
                      gap: 16,
                      gridTemplateColumns: "repeat(2, 1fr)",
                    }}
                  >
                    {functionalRolesData.map((role) => (
                      <li
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <fieldset disabled={!isEditPermission}>
                          <CheckBox
                            name="functionalRoles"
                            value={role.id}
                            label={role.name}
                          />
                        </fieldset>
                      </li>
                    ))}
                  <ErrorMessage name ="functionalRoles">
                    {(msg)=><ErrorLabel text={msg} />}
                  </ErrorMessage>
                  </ul>
                  <div></div>
                  {/* {isAlert && (
                    <div
                      style={{
                        zIndex: 20,
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <Alert
                        image={message.image}
                        message={message.message}
                        onClose={setIsAlert}
                      />
                    </div>
                  )} */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </ExpandableArea>
    </div>
  );
};

const ExpandableArea = ({ children }) => {
  const [expanded, setExpanded] = useState(false);

  if (!expanded) {
    return (
      <div className={styles.expanded}>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(true)}
        >
          <span>View More</span>
          <img src={IMAGES.arrowdown} width={24} height={24} alt="down arrow" />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.expanded}>
      <div>
        <button
          className={styles.transparentBtn}
          onClick={() => setExpanded(false)}
        >
          <span>View Less</span>
          <img src={IMAGES.arrowup} width={24} height={24} alt="up arrow" />
        </button>
      </div>

      {children}
    </div>
  );
};

const VodafoneUserList = ({ vfData }) => {
  return (
    <div className={styles.CustomerContainer}>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>
          {vfData?.pref == 0
            ? "Primary Contact Name"
            : "Additional Contact Name"}
        </span>
        <p>{vfData?.name}</p>
      </div>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Email Address</span>
        <p>{vfData?.email}</p>
      </div>
      {/*<div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Onboarded on</span>
        <p>22 May 2020</p>
      </div>
      <div className={styles.CustomerList}>
        <span className={styles.spanlabel}>Status </span>
        <p>
          Inactive{" "}
          <span className={styles.spanSwitch}>
            <Switch />
          </span>{" "}
          Active
        </p>
  </div>*/}
    </div>
  );
};

const CustomerDetail = ({
  customerId,
  customer,
  isLoadingCustomer,
  getDetails,
  onBack,
  myRights,
  next,
  ...props
}) => {
  const { userList, isLoading, functionalRoles, openAlertPopup } = props;
  const [showProduct, setShowProduct] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isIPXServicesChecked, setIsIPXServicesChecked] = useState(false);
  const [isInternationalVoiceServicesChecked,setIsInternationalVoiceServicesChecked] = useState(false);
   const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showFinca, setShowFinca] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOperatorCode, setSelectedOperatorCode] = useState('');
  const [fincaCode, setFincaCode] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formValues, setFormValues] = useState(null);
  let { requestId } = useParams();
  const addFincacode =(e,setFieldValue)=>{
    e.preventDefault();
    setFieldValue("fincaTadigCode", fincaCode);
    setShowFinca(false);
  }
  const showFincaDetails = () => {
    setShowFinca(!showFinca);
    setSelectedOperatorCode('');
    setFincaCode('');
  }
  const handleSearchChange = (event) => {
    event.preventDefault()
    setSearchTerm(event.target.value);
  };
  const handleRowClick = (operatorCode) => {
    setSelectedOperatorCode(operatorCode)
  };
  const filteredData = props?.fincaTadig?.data?.filter((item) =>
    item?.fincaTADIG?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
    item?.orgName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const mapdata = filteredData?.map((item)=>{
    return{
      "Operator Code":(item.fincaTADIG),
      "Operator Name":(item.orgName)
    };
})

  const addCode = (e, setFieldValue) => {
    e.preventDefault();
    setFieldValue("fincaTadigCode", selectedOperatorCode);
    setShowFinca(false);
  };

  const navigate = useNavigate();
  const gotoDetail = (requestId) => {
    if(CAN(Action.GET, Resource.CUSTOMER_ONBOARDING_APPROVAL)){
      navigate(`/OnboardingDetail/${requestId}`);
    }else if(CAN(Action.GET, Resource.CUSTOMER_ONBOARDING_REQUEST)){
      navigate(`/OnboardingRequestDetail/${requestId}`);
    }
  }
  const headers = ['Contact Name', 'Email Address', 'Onboarded On','Last Login Time', 'Status'];
  if (CAN(Action.CREATE, Resource.RIGHTS)) {
    headers.push('Edit Access');
     }

  // Separate primary contacts and other users
const primaryContacts = userList?.filter(user =>
  customer?.serviceSpecificDetails?.some(entry =>
      entry.primaryContacts.some(contact => contact.email === user.email)
  )
) || [];
//Separate primary contacts and other users
const otherUsers = userList?.filter(user =>
  !customer?.serviceSpecificDetails?.some(entry =>
      entry.primaryContacts.some(contact => contact.email === user.email)
  )
) || [];

// Combine primary contacts with other users
const combinedUserList = [...primaryContacts, ...otherUsers];
 
const onSubmit = (isChecked,id) => { 
  setIsSubmitActive(true); 
  setLoadingUserId(id);  
  const payload = {
    status: isChecked  ? "inactive" : "active"
  };
  props.SubmitActiveInactive(payload,id).then((result) => {
    if (result.status) {
      openAlertPopup({
        message: "User status updated successfully!",
        image: IMAGES.success,
      });
      setIsSubmitActive(false);
      setLoadingUserId(null);
      if(customerId){
        props.getUser(USER_TYPE.CUSTOMER, customerId, "", next);
      }else if(requestId){
        props.getUser(USER_TYPE.CUSTOMER, requestId, "", next);
      }
    } else {
      let error_msg = result?.error?.message ?? "Something went wrong.";
      openAlertPopup({
        message: error_msg,
        image: IMAGES.error,
      //  isFormOpen:true
      });
      setIsSubmitActive(false);
     setLoadingUserId(null);
    }
  })
  .catch((error) => {
    openAlertPopup({
      message: "Something went wrong.",
      image: IMAGES.error,
    //  isFormOpen:true
    });
    setIsSubmitActive(false);
  setLoadingUserId(null);
  });
};

const handleOkClick = () => {
  setIsSubmit(true);
  const values = formValues;
  let obj = {fincaTadigCode: values.fincaTadigCode,};
  props.SubmitFincaId(obj, customer?.uniqueCustomerRefId)
  .then((result) => {
    if (result.status) {
      props.openAlertPopup({
        message: "Customer details updated successfully!.",
        image: IMAGES.success,
      });
      setIsSubmit(false);
      setIsEditing(false);
      getDetails(customerId);
      setShowConfirmation(false);                
    } else {
      let error_msg = result?.error?.message ?? "Something went wrong.";
      props.openAlertPopup({message: error_msg,image: IMAGES.error,});
      setIsSubmit(false);
      setIsEditing(false);
      setShowConfirmation(false);
      }
    })
    .catch((error) => {
      props.openAlertPopup({message: "Something went wrong.",image: IMAGES.error,});
        setIsSubmit(false);
        setIsEditing(false);
        setShowConfirmation(false);
      });
};

const handleCancelClick = () => {
  setShowConfirmation(false);
  setIsSubmit(false)
};

const [showPopup, setShowPopup] = useState(false);
const [userToConfirm, setUserToConfirm] = useState(null);

const handleSwitchClick = (user) => {
  if (CAN(Action.TOGGLE_USER_STATUS, Resource.RIGHTS)) {
    setShowPopup(true);
    setUserToConfirm(user);
  }
};

const handleConfirm = () => {
  onSubmit(userToConfirm?.status === 'active', userToConfirm?.id);
  setShowPopup(false);
};

const handleCancel = () => {
  setShowPopup(false);
};
  const mappedData = combinedUserList?.map(user => ({
    'Contact Name': <span>{customer?.serviceSpecificDetails?.some(entry =>entry.primaryContacts.some(contact => contact.email === user.email))&&(<img className={styles.primary} src={IMAGES.primary} />)}{user?.displayName}</span>,
    'Email Address': user?.email,
    'Onboarded On':formatDate(user?.createdAt),
    'Last Login Time':user?.lastLoginInTime?formatDate(user?.lastLoginInTime):"Not Available", // Static value
    'Status': 
    <p  className={styles.active}>
    {isSubmitActive && loadingUserId === user?.id ? 
      <Loader loaderStyle={styles.loaderStyleswitch} /> : 
    // <>{""}Inactive{" "}  
    <span className={styles.spanSwitch}>
  <Switch
      cursorstyle={CAN(Action.TOGGLE_USER_STATUS, Resource.RIGHTS)}
      isChecked={user?.status === 'active'}
      onClick={() => handleSwitchClick(user)}
    />
     </span>
    // Active</>
  }
  </p>,
    'Edit Access': 
    <SectionGuard canAccess={CAN(Action.CREATE, Resource.RIGHTS)}>
      <NewUser customerData={customer} userData={user} isEdit={true} icon={user?.status === "active" ? true : false}/>
    </SectionGuard>
  }));
  
  let headerAccountManager = [];
  let mappedAccountManager = [];
  if (myRights.userType === USER_TYPE.INTERNAL) {
    headerAccountManager = ['Contact Name', 'Email Address', 'Assigned On', 'Status'];
    const emailSet = new Set();
    mappedAccountManager = customer?.serviceSpecificDetails?.reduce((acc, detail) => {
      const managers = detail.accountManagers?.map(manager => {
        if (emailSet.has(manager.email)) {
          return null;
        }
        emailSet.add(manager.email);
        return {
          'Contact Name': manager.name,
          'Email Address': manager.email,
          'Assigned On': formatDate(manager.onboardedDate),
          'Status': (
            <p className={styles.active}>
              {/* {""}Inactive{" "} */}
              <span className={styles.spanSwitch}>
                <Switch cursorstyle={false} isChecked={true} />
              </span>
              {/* Active */}
            </p>
          ),
        };
      }).filter(manager => manager !== null) || [];
      return acc.concat(managers);
    }, []) || [];
  } else if (myRights.userType === USER_TYPE.CUSTOMER) {
    headerAccountManager = ['Contact Name', 'Email Address'];
    const emailSet = new Set();
    mappedAccountManager = customer?.serviceSpecificDetails?.reduce((acc, detail) => {
      const managers = detail.accountManagers?.map(manager => {
        if (emailSet.has(manager.email)) {
          return null;
        }
        emailSet.add(manager.email);
        return {
          'Contact Name': manager.name,
          'Email Address': manager.email
        };
      }).filter(manager => manager !== null) || [];
      return acc.concat(managers);
    }, []) || [];
  }

const accountManagerList = props?.accountManagerList;
 // Extract emails from mappedAccountManager

 const mappedEmails = new Set(mappedAccountManager?.map(manager => manager['Email Address']));
 // Filter accountManagerList to include only those emails not present in mappedEmails

 const filteredAccountManager = accountManagerList?.filter(item => !mappedEmails.has(item.email))?.map(item => ({
   value: item.email,
   label: item.displayName,
 })) || [];

 // Log the filtered emails from accountManagerList
 filteredAccountManager.forEach(manager => {
   return {
      value: manager.email,
      label: manager.displayName,
   }
 });


  useEffect(() => {
    let searchKey = "";
    let type = USER_TYPE.CUSTOMER;
    let custID = customerId;
    let next=null;
    if(customerId){
      props.getUser(type, custID, searchKey,next);
    }else if(requestId){
      props.getUser(type, requestId, searchKey,next);
    }
    props.getPredefinedRoles("customer");
    getDetails(customerId?customerId:requestId);
    if(CAN(Action.LIST, Resource.FINCA_TADIG)){
      props.getFincaTadig()
    }
    window.scrollTo(0,0);
    return () => {
      props.resetUserList();
  };
  }, []);

  const back=()=>{
    navigate("/user-management")
  }

  return (
    <Container>
       {showConfirmation && ( 
        <div className={styles.alertmessage}> 
          <div className={styles.cnfcontent}> 
            <div className={`${styles.header}`}> 
              <div className={styles.confirmheader}>Confirm Code</div>
            </div>
            <div className={styles.infomsgs}>
              Kindly ensure <span className={styles.cnfmsg}>FinCA Code</span> for <span className={styles.cnfmsg}>{customer?.displayName}</span> is correct to avoid any discrepancies.
              {/* <div className={styles.cnfparent}>
                  <div className={`${styles.fincaco} ${styles.bkcolor}`}>FinCA Code</div>
                  <div className={`${styles.fincaval} ${styles.bkcolor}`}>{formValues.fincaTadigCode}</div>
              </div> */}
               <div className={styles.cfparent}>
                  <div className={`${styles.ipxcont} ${styles.bkcolor}`}>
                    <div className={styles.ipxcontlabel}>FinCA Code</div>
                    <div className={styles.ipxcontval}>{formValues.fincaTadigCode}</div>
                  </div>
               </div>
            </div>
            <div className={styles.confirmfooter}>
              <button className={styles.cancelButton} onClick={handleCancelClick}>Cancel</button>
              {!isSubmit?<button className={styles.okButton} onClick={handleOkClick}>Submit</button>:<div className={styles.cnfloader}><Loader/></div>}
            </div>
          </div> 
        </div> 
      )}
      {myRights.userType === USER_TYPE.CUSTOMER ? (
        <Banner image={IMAGES.banner} text={"Customer Details"}></Banner>
      ): null}
      {isLoadingCustomer  ? (
        <Loader />
      ) : (
        <ChildContainer>
          <div className={styles.customer}>
            {myRights.userType === USER_TYPE.INTERNAL  ? (
              <img
                className={styles.icon}
                onClick={customerId?onBack:back}
                src={IMAGES.backarrow}
                alt=""
              />
            ) : null}
            <div className={styles.textCustomer}>{customer?.displayName}</div>
          </div> 
          <div>
            <div className={styles.CustomerContainers}>
            <div className={styles.requestDetail}>Customer Details</div>
            <div  className={styles.Customerbox}>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Organisation Legal Name</span>
                <p>{customer?.displayName}</p>
              </div>
             
              </div>
              <div className={styles.requestDetail}>Availed Service</div>
              {customer?.serviceSpecificDetails?.map((detail,index, array)=>
      <div className={`${array.length > 1 && index !== array.length - 1 ? styles.borderbottom : ''}`}>
        <div key={index} className={`${styles.Customerbox}`} >
        <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Service Type</span>
                <p>{detail?.serviceType?.label}</p>
              </div>
              {detail?.cpName!==null&&(
                 <div className={styles.CustomerList}>
                 <span className={styles.spanlabel}>TADIG Code</span>
                 <p spandata>{detail?.cpName}</p>
               </div>
              )}
      {detail?.serviceType?.value === Incident_Service_Type_Values.IPX_Service && (
  <div className={styles.CustomerList}>
    {(myRights.userType === USER_TYPE.INTERNAL && CAN(Action.UPDATE, Resource.CUSTOMERS) && CAN(Action.LIST, Resource.FINCA_TADIG))?
    <><span className={styles.statusLegendsIcon}></span>
    <span className={styles.spanlabel}>FinCA Code</span></>:<span className={styles.spanlabel}>FinCA Code</span>}
    {myRights.userType !== USER_TYPE.CUSTOMER ? (
  <>
    {isEditing ? (
      <Formik
        initialValues={{ fincaTadigCode: detail?.fincaTadigCode || null }}
        onSubmit={(values) => {
          setFormValues(values);
          setShowConfirmation(true);
          // setIsSubmit(true);
          // let obj = {
          //   fincaTadigCode: values.fincaTadigCode,
          // };
          // props.SubmitFincaId(obj, customer?.uniqueCustomerRefId)
          //   .then((result) => {
          //     if (result.status) {
          //       props.openAlertPopup({
          //         message: "Customer details updated successfully!.",
          //         image: IMAGES.success,
          //       });
          //       setIsSubmit(false);
          //       setIsEditing(false);
          //       getDetails(customerId);                
          //     } else {
          //       let error_msg = result?.error?.message ?? "Something went wrong.";
          //       props.openAlertPopup({
          //         message: error_msg,
          //         image: IMAGES.error,
          //       });
          //       setIsSubmit(false);
          //       setIsEditing(false);
          //     }
          //   })
          //   .catch((error) => {
          //     props.openAlertPopup({
          //       message: "Something went wrong.",
          //       image: IMAGES.error,
          //     });
          //     setIsSubmit(false);
          //     setIsEditing(false);
          //   });        
        }}
      >
        {({ dirty, isValid,values, setFieldValue }) => (
          <Form>
            <Field
              name="fincaTadigCode"
              placeholder="Enter here"
              className={styles.input}
            />
            <div className={styles.iconimg}>
             <span className={styles.iconparent} onClick={showFincaDetails}>
              <img className={styles.questionicon} src={IMAGES.questionicon} alt="" />
             </span>
             {showFinca && ( 
        <div className={styles.alertmessage}> 
         <div className={styles.alertcontent}> 
          <div className={`${styles.header}`}> 
            <div className={styles.headertext}>Add FinCA Code</div>
            <img className={styles.close} src={IMAGES.close} alt="Close"   onClick={showFincaDetails}/> 
          </div>
          <div className={styles.infomsg}>FinCA codes has been generated by FinCA team. Kindly align with the team if the code is not right.</div>
          <div className={styles.inputfieldcontainer}>
            <input
              type="text"
              placeholder="Search by Operator Code or Name..."
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchInput}
            />
            <div className={styles.searchwrapper}>
              <img className={styles.serchimage} src={IMAGES.searchimage} alt="Search" />
            </div>
          </div>
          <div className={styles.tableparent}>
              <Table
                headers={fincaheader}
                data={mapdata}
                maxHeight="300px"
                width="100%"
                columnConfig={columnConfig}
                nodata="No Code Found"
                onRowClick={handleRowClick}
                isClisk = {true}
              />
          </div>
          {mapdata?.length===0&&(
            <>
              <div className={styles.nodataintable}>
                <span className={styles.fincamsg}>You can enter the code for <span className={styles.searchTerm}> {searchTerm}</span>. If you don’t have the code, kindly contact the team</span>
              </div>
              <div className={styles.fincainput}>
                <label className={styles.fincalabel}>FinCA Code</label>
                <input
                  type="text"
                  placeholder="Enter FinCA Code"
                  value={fincaCode}
                  onChange={(e) => setFincaCode(e.target.value)}
                  className={styles.textinput}
            />
              </div>
            </>
          )}
          <div className={styles.footerfinca}>
          {mapdata?.length===0?<button className={`${styles.addcode} ${fincaCode?.trim()===""?styles.disablebgtn:""}`} disabled={fincaCode?.trim()===""} onClick={(e)=>{addFincacode(e,setFieldValue)}}>Add Code</button>:
          <button className={`${styles.addcode} ${selectedOperatorCode?.trim()===""?styles.disablebgtn:""}`} disabled={selectedOperatorCode?.trim()===""} onClick={(e)=>{addCode(e,setFieldValue)}}>Add Code</button>}
          </div>
         </div> 
        </div> )}
             {isSubmit ? 
            <Loader loaderStyle={styles.loaderStyle}/>:
            <button
            //  disabled={!dirty}
              type="submit"
              className={`${styles.saveButton} ${styles.submitbtn} ${(values.fincaTadigCode==="" || values.fincaTadigCode===null)?styles.disablesavebtn:""}`}
              disabled={values.fincaTadigCode==="" || values.fincaTadigCode===null}
            >
              {(values.fincaTadigCode===""||values.fincaTadigCode===null)?<img src={IMAGES.disablesave} className={styles.disablesavebtn} alt="Save" />:<img src={IMAGES.save} alt="Save" />}
            </button> }
            </div>
          </Form>
        )}
      </Formik>
    ) : (
      <>
        <span className={styles.inputvalue}>{(detail?.fincaTadigCode ===null||detail?.fincaTadigCode===""|| !detail?.hasOwnProperty("fincaTadigCode")) ? <span className={styles.notadded}>Code Not Added</span>:detail?.fincaTadigCode}</span>
        <SectionGuard canAccess={CAN(Action.UPDATE, Resource.CUSTOMERS)}>
          <SecondaryTooltip text={"Click to add code"} positions={['bottom', 'right']}>
           {detail?.fincaTadigCode?<img className={styles.editbtn} src={IMAGES.editrole} onClick={() => setIsEditing(true)}/>:<img className={styles.editbtn} src={IMAGES.addicon} onClick={() => setIsEditing(true)}/>}
          </SecondaryTooltip>
         </SectionGuard>
      </>
    )}
  </>
) : (
  <p>{detail?.fincaTadigCode !==null ? detail?.fincaTadigCode : '---'}</p>
)}
  </div>
)}
              {detail?.serviceType?.value===Incident_Service_Type_Values.IVC_Service&& myRights.userType!==USER_TYPE.CUSTOMER&&(
                <>
                  <div className={styles.CustomerList}>
                   <span className={styles.spanlabel}>Account Number</span>
                   <p>{detail?.accountNumber}</p>
                  </div>
                  <div className={styles.CustomerList}>
                   <span className={styles.spanlabel}>Account Abbreviation</span>
                   <p spandata>{detail?.accountAbbr}</p>
                  </div>
                </>
              )}
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Approved By</span>
                <p>{detail?.approvedBy?.name}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Onboarded On</span>
                <p spandata>{formatDate(detail?.approvedAt)}</p>
              </div>
        </div>
        {detail?.serviceType?.value === Incident_Service_Type_Values.IPX_Service &&
        <SectionGuard canAccess={CAN(Action.UPDATE, Resource.CUSTOMERS)}>
          <div className={styles.fincadesc}><span className={styles.statusLegendsIcon}></span>FinCA code description </div>
         </SectionGuard>}
        {
          detail?.requestDocumentId ?
            <SectionGuard canAccess={CAN(Action.GET, Resource.CUSTOMER_ONBOARDING_APPROVAL) || CAN(Action.GET, Resource.CUSTOMER_ONBOARDING_REQUEST)}>
              <div className={styles.linkdiv}> <span className={styles.linkgoto} onClick={() => gotoDetail(detail?.requestDocumentId)}>View Request Details</span></div>
            </SectionGuard> : null
        }
      </div>
         )}
              {/* <div  className={styles.Customerbox}>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>TADIG ID</span>
                <p>{customer?.cpName}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Onboarding Date</span>
                <p>{formatSecondsToDate(customer?.createdAt)}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Primary Contact Name</span>
                <p spandata>{customer?.primaryContact?.displayName}</p>
              </div>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Email Address</span>
                <p spandata>{customer?.primaryContact?.email}</p>
              </div>
              </div> */}
              <div className={styles.checkboxmain}>
                {/* <Formik
                 initialValues={{ services: [] }}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <div  className={styles.checkboxdiv}>
                      <div className={styles.checkboxipx}>
                        <CheckBoxs
                          name="servicesipx"
                          value={ServiceType.IPX_Services}
                          label="IPX Services"   
                          onChange={(e) => handleCheckboxChange(e, setFieldValue, values)}                 
                          checked={values.services.includes(ServiceType.IPX_Services)}
                        />
                      </div>
                      <div>
                        <CheckBoxs
                          name="servicesivc"
                          value={ServiceType.International_Voice_Services}
                          label="International Voice Services"
                          onChange={(e) => handleCheckboxChange(e, setFieldValue, values)}
                        checked={values.services.includes(ServiceType.International_Voice_Services)}
                        />
                        </div></div>
                        <div className={styles.headnote}>International Voice Services details are pending. Kindly fill up the details to extend the service for customer.</div>
                    
                    </Form>
                  )}
                </Formik> */}
                {/* {showProduct && <AddNewProduct source={"ipx"} open={setShowProduct}  serviceType={serviceType} 
               isIPXServicesChecked={isIPXServicesChecked} isInternationalVoiceServicesChecked={isInternationalVoiceServicesChecked}/> } */}
               {/* {myRights?.userType==USER_TYPE.INTERNAL && customer?.serviceSpecificDetails?.length<=1&& (  
                customer?.requestedByOnboarding?.some((item) => item?.email === myRights?.email) 
                ? <span className={styles.raisedreq}>A request has already been raised for {customer?.serviceSpecificDetails[0]?.serviceType?.value==="ipx_service"?"IVC":"IPX"}.</span>
                : <AddNewProduct serviceType={customer?.serviceSpecificDetails[0]?.serviceType} customerId={customer?.uniqueCustomerRefId} />
               )} */}
              </div>
  
            </div>
          </div>

          <div
            data-vf-user={
              myRights.userType === USER_TYPE.INTERNAL ? true : false
            }
            className={styles.detailsContainer}
          >
            <div>
            <div className={styles.alignbtns}>
              <div className={styles.textuser}>Account Manager </div>
              {myRights?.functionalRoles[0] === FUNCTIONAL_ROLES_CONSTANTS["super_admin_internal"]?.value &&(

                <AddAccountManager  customerData={customer}  filteredAccountManager={filteredAccountManager}/>)}
              </div>
             {/* <div className={styles.sharedEmailContainer}>
              <div className={styles.CustomerList}>
                <span className={styles.spanlabel}>Shared Mailbox</span>
                <p>{customer?.vfSharedMailDL}</p>
              </div>
            </div> */}
              {/* {customer?.vfPOC.map((vfData) => (
                <VodafoneUserList vfData={vfData} />
              ))} */}
                {mappedAccountManager?.length>0?(
                 <DynamicTable headers={headerAccountManager} data={mappedAccountManager} className="custom-table" userType={myRights.userType} />
                ):<EmptyContainer>No Account Manager Found</EmptyContainer>}            
            </div>
            <div>    

              <div className={styles.header}>            
                <div  className={styles.text}>
                Customer Contacts
                </div >               
                 <div className={styles.alignbtn}>
                 <NewUser customerData={customer} isEdit={false}/>
                </div>              
              </div>
              { userList?.length ? (
                <InfiniteScroll
                  dataLength={userList?.length} //This is important field to render the next data
                  next={() => {
                    return props.getUser(
                     USER_TYPE.CUSTOMER,
                     customerId?customerId:requestId,
                      "",
                      next
                    );
                  }}
                  hasMore={next}
                  loader={
                      <Loader />
                  }
                >
                  <div className={styles.tabel}>
                   <DynamicTable headers={headers} data={mappedData} className="custom-table" />
                   <div className={styles.primarylabel}><img className={styles.primary} src={IMAGES.primary} />First Onboarded Primary Customer Contact.</div>
                   </div>
                  {/* {userList?.map((userData) => (
                    console.log("userData",userData),
                    
                    <UserList
                      data={userData}
                      functionalRolesData={functionalRoles}
                      updateUserPermissions={props.updateUserPermissions}
                      openAlertPopup= {openAlertPopup}
                    />
                  ))} */}
                </InfiniteScroll>
              ) : (
                // <div className={styles.emptyContainer}>No users found</div>
                <EmptyContainer>No User Found</EmptyContainer>
              )}
            </div>
            {showPopup && (
      <ConfirmationPopup
        header={"Status Update"}
        data={<span>Do you wish to change status to <span className={styles.popupmsg}>{userToConfirm.status ==='active'? 'Inactive': "Active"}</span> for {userToConfirm.displayName}?</span>}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    )}
          </div>
        </ChildContainer>
      )}
    </Container>
  );
};

export default CustomerDetail;

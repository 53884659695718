import Select from 'react-select'
import IMAGES from '../../assets/images/images';
import React, { forwardRef } from 'react';

const dropdownStyle = {
    control: (provided, state)=> {
        const {selectProps: {hasError, active}, isDisabled,isFocused} = state
       
        return (
            {...provided, pointerEvents: 'initial', backgroundColor: active ? "#007c92":isDisabled ? "#CCCCCC" : "#fff",cursor: isDisabled ? 'not-allowed' : 'pointer', borderRadius: 5, height: 48,borderWidth: 1, borderColor: isFocused ? "#555" : (hasError ? "#CD3500" : "#979797")
                , boxShadow: isFocused ? '0 0 0 1px #555' : 'none', // Ensure the focus border color is applied
                '&:hover': {
                    borderColor: isFocused ? "#555" : (hasError ? "#CD3500" : "#979797")
                }
            }
        );
    },
    singleValue: (provided, state)=> {
        const { selectProps: { active } } = state;
        return {...provided,  backgroundColor: "transparent", color: active ? "#FFFFFF":"#0D0D0D", fontFamily: "VodafoneRg-Regular", fontSize:20};},
    placeholder: (provided, state)=> ({...provided, backgroundColor: "transparent", color: "#666666", fontFamily: "VodafoneRg-Regular", fontSize:20}),
    indicatorSeparator: (provided, state)=> ({...provided, backgroundColor: "transparent", width: 0}),
    // dropdownIndicator: (provided, state)=> ({...provided, color: "#333333"}),
    dropdownIndicator: (provided, state) => {
        const { selectProps: { active, menuIsOpen,monthdropdown } } = state;
        return({...provided,padding: 0,color: 'transparent', position: 'relative', transform:`${menuIsOpen&&monthdropdown?'rotate(180deg)':''}`,
        '&::before': {content: `url(${active?IMAGES.montharrow:IMAGES.arrowdown})`,display: 'block',position: 'absolute',right: `${active&&!menuIsOpen?"6px":menuIsOpen&&monthdropdown?"-6px":"3px"}`,height:`${active?"31px":"24px"}`,width:'24px',top: '50%',transform: 'translateY(-50%)',color: "#333333"},
        '&:hover::before': {content: `url(${active?IMAGES.montharrow:IMAGES.arrowdown})`,display: 'block'},
        '&:hover': {color: 'transparent'}
    })},
    //container: (provided, state)=> ({...provided, width: 500,}),
    container: (provided, state)=> {
        const {selectProps: {customStyles}} = state
        return (
            {...provided, ...customStyles}
        );
    },
    option: (provided, state) => ({...provided,backgroundColor: state.isSelected ? "#E0C0C0" : state.isFocused ? "#FFF0F0" : "transparent",color:state.isSelected?"inherit":"",
        "&:hover": {backgroundColor: "#FFF0F0"}}),

}
let obj={
    width:500 
}
const DropDown = forwardRef(({options,placeholder, onChange,value, hasError,isDisabled=false,customStyles=obj,menuIsOpen,active,monthdropdown=false },ref)=>{
    return (
        <Select 
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            styles={dropdownStyle}
            hasError={hasError}
            value={value}
            customStyles={customStyles}
            isDisabled={isDisabled}
            ref={ref}
            menuIsOpen={menuIsOpen}
            active={active}
            monthdropdown={monthdropdown}
           />
    );
})

export default DropDown;

import { FETCH_LIVE_SERVICES, FETCH_LIVE_SERVICES_FILTER,FETCH_LIVE_SERVICES_FOR_AMEND_DROPDOWN} from "../../actions/actionTypes";

const initialState = {
    isLoading:false,
    isLiveServiceDropDownLoading:false,
    data:[],
    liveServicedataOnNewDemandForm:[],
    locations:[],
    locationsOnNewDemandForm:[],
    filters:{},
    statusCount:{},
    isFilterLoading:false,
};

const reducer = (state=initialState, action)=>{    
    switch(action.type){
        case FETCH_LIVE_SERVICES.REQUEST:
            return {...state,data:[],locations:[], isLoading:true}
        case FETCH_LIVE_SERVICES.FAILURE:
            return {...state,data:[],statusCount:{},locations:[], isLoading:false}
        case FETCH_LIVE_SERVICES.SUCCESS:           
            return {...state, data:action.data?.liveServices,locations:action.data?.locations,statusCount:action.data?.statusCount,isLoading:false}  
        case FETCH_LIVE_SERVICES_FILTER.REQUEST:
            return {...state, filters:{}, isFilterLoading:true}
        case FETCH_LIVE_SERVICES_FILTER.FAILURE:
            return {...state, filters:{}, isFilterLoading:false}
        case FETCH_LIVE_SERVICES_FILTER.SUCCESS:          
            return {...state, filters:action.data, isFilterLoading:false}   
        case FETCH_LIVE_SERVICES_FOR_AMEND_DROPDOWN.REQUEST:
            return {...state,liveServicedataOnNewDemandForm:[],locationsOnNewDemandForm:[], isLiveServiceDropDownLoading:true}
        case FETCH_LIVE_SERVICES_FOR_AMEND_DROPDOWN.FAILURE:
            return {...state,liveServicedataOnNewDemandForm:[],locationsOnNewDemandForm:[], isLiveServiceDropDownLoading:false}
        case FETCH_LIVE_SERVICES_FOR_AMEND_DROPDOWN.SUCCESS:           
            return {...state, liveServicedataOnNewDemandForm:action.data?.liveServices,locationsOnNewDemandForm:action.data?.locations,isLiveServiceDropDownLoading:false}  
        
        default:
            return state
    }   
}

export default reducer;

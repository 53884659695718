import { FETCH_MISSING_FINCA_CUSTOMERS } from "../../actions/actionTypes";

const initialState = {
    data: [],
    isLoading: false,
    next: null,
    totalcount:0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MISSING_FINCA_CUSTOMERS.REQUEST:
            return { ...state, isLoading: true };
        case FETCH_MISSING_FINCA_CUSTOMERS.FAILURE:
            return { ...state, isLoading: false };
        case FETCH_MISSING_FINCA_CUSTOMERS.SUCCESS:
            return {
                ...state,
                data: [...action?.obj?.data],
                totalcount:action?.obj?.totalcount?action?.obj?.totalcount:0,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default reducer;

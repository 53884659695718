import React, { useEffect, useRef, useState } from "react";
import { Container, ChildContainer } from "../../../../components/Container/Containers";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../../../../components/sidebar/Sidebar";
import { ServiceListContainer, StatusContainer, ServiceDetailContainer, ServiceHeader, ServiceMiddleDiv, ServiceDescription, DateOfSubmission, ServiceName, ServiceFooter, IconsContainer } from "../../../../components/list/list";
import styles from "./NewRequestViewer.module.css";
import { formatSecondsToDate } from "../../../../utils/dateTime";
import comment from '../../../../assets/images/comment.png'
import ammendService from "../../../../assets/images/edit.png";
import Comment from "../../comment/comment.container";
import { Search } from "../../../../components/search/search";
import DropDown from "../../../../components/dropDown/DropDown";
import { Loader } from "../../../../components/loader/Loader";
import ServiceStatus from "../../serviceStatus/serviceStatus.container";
import { allCount, DEMAND_STATUS, MY_SERVICE_TABS, SERVICE_STATUS_OPTIONS } from "../../../../utils/constants/serviceConstants";
import SectionGuard from "../../../../hoc/guards/SectionGuard";
import CAN from '../../../../casl/can';  
import {Action , Resource} from '../../../../casl/constants'; 
import { USER_TYPE } from "../../../../utils/constants/userConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { EmptyContainer } from "../../../dashboard/graphs/component/component";
import { isString } from "../../../../utils/utils";
import Overlay from "../../../dashboard/graphs/overlay/Overlay";
import { Formik, useField, Form, Field } from 'formik';
import moment from 'moment';
import * as Yup from "yup";
import {
  FormFieldLabel as Label,
  FormErrorLabel as ErrorLabel,
} from "../../../../components/label/Labels";
import * as XLSX from 'xlsx';
import IMAGES from "../../../../assets/images/images";
import { DIAMETER, SIGTRAN, TRANSPORT, DNS, GRX } from "../../../../utils/constants/serviceConstants";
import {formatDate} from "../../../../utils/arrayUtils";
import Status from "../../../../components/status/Status";
import SecondaryTooltip from "../../../../components/secondaryToolTip/secondaryToolTip";
import Table from "../../../../components/table/Table";
import { columnConfig } from "../../../../utils/constants/serviceConstants";
import { AppliedFilter, Filter } from "../../../../components/filter/filter";
import { checkAllFalse } from "../../../../utils/constants/liveServiceConstants";
const getAllFormattedData = (data) => {
  let formattedData = [];
  data.forEach(item => {
    let statObj = SERVICE_STATUS_OPTIONS?.find(({ value }) => value === item?.status);
    let fData = {};
    fData.requestId = item?.requestId;
    fData.createdBy = isString(item?.createdBy) ? item?.createdBy : item.createdBy?.name;
    fData.createdAt = item?.createdAt ? formatDate(item.createdAt) : '';
    fData.service = item?.service;
    fData.requestId = item?.requestId;
    fData.customer = item?.organization;
    fData.location = item?.locationDetails?.serviceRegion?.label;
    fData.country = item?.locationDetails?.serviceCountry?.label;
    fData.status = statObj?.label;
    formattedData.push(fData);
  });
  return formattedData;
}
const getExcelHeaderBySearvice = (serviceName) => {
  switch (serviceName) {
    case DIAMETER:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Avg Monthly DTR Volumes',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    case SIGTRAN:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Type',
        'Avg Monthly MSU Volumes',
        'Top Destinations',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    case GRX:
    case TRANSPORT:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Allocated Capacity',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    case DNS:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'How Many DNS',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
    default:
      return [[
        'Request Id',
        'Requestor Name',
        'Submitted on',
        'Service',
        'Avg Monthly DTR Volumes',
        'How Many DNS',
        'Type',
        'Avg Monthly MSU Volumes',
        'Top Destinations',
        'Allocated Capacity',
        'Organisation',
        'Region',
        'Country',
        'Status'
      ]];
  }

}
const exportToExcel = (data, fileName, sheetName) => {
  const services = [DIAMETER, SIGTRAN, TRANSPORT, DNS, GRX];
  let headers = [];
  const workbook = XLSX.utils.book_new();
  let formattedDataByService = {};
  let dataByServices = data.reduce(function (accObj, currentObj) {
    if (services.includes(currentObj?.service.trim())) {
      accObj[currentObj?.service] = accObj[currentObj?.service] || [];
      accObj[currentObj?.service].push(currentObj);
    }
    return accObj;
  }, {});

  Object.keys(dataByServices).forEach((services) => {
    formattedDataByService[services] = [];
    Object.keys(dataByServices[services]).forEach((service) => {
      let item = dataByServices[services][service];        
      let statObj = SERVICE_STATUS_OPTIONS?.find(({ value }) => value === item?.status);
      let fData = {};
      fData.requestId = item?.requestId;
      fData.createdBy = isString(item?.createdBy) ? item?.createdBy : item.createdBy?.name;
      fData.createdAt = item?.createdAt ? formatDate(item.createdAt) : '';
      fData.service = item?.service;
      if (services == DIAMETER) {
        fData.avg_monthly_dtr_volumes = item?.service_option.avg_monthly_dtr_volumes;
      }
      if (services == DNS) {
        fData.dns_number = item?.service_option.dns_number;
      }
      if (services == SIGTRAN) {
        fData.type = item?.service_option.type ? item.service_option.type.toString() : '';
        fData.avg_monthly_msu_volumes = item?.service_option.avg_monthly_msu_volumes;
        fData.top_destinations = item?.service_option.top_destinations;
      }
      if (services == TRANSPORT || services == GRX) {
        fData.allocated_capacity = item?.service_option.allocated_capacity;
      }
      fData.customer = item?.organization;
      fData.location = item?.locationDetails?.serviceRegion?.label;
      fData.country = item?.locationDetails?.serviceCountry?.label;
      fData.status = statObj?.label;
      formattedDataByService[services].push(fData);
    });
  });    

  let allDataHeaders = [[
    'Request Id',
    'Requestor Name',
    'Submitted on',
    'Service',
    'Organisation',
    'Region',
    'Country',
    'Status' 
  ]];
  const worksheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(worksheet, allDataHeaders);
  XLSX.utils.sheet_add_json(worksheet, getAllFormattedData(data), { origin: 'A2', skipHeader: true });
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);


  Object.keys(formattedDataByService).forEach((service) => {
    headers = getExcelHeaderBySearvice(service);
    var worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, headers);
    XLSX.utils.sheet_add_json(worksheet, formattedDataByService[service], { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(workbook, worksheet, service);
  });
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `${fileName}.xlsx`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(url);
};


const ServiceStatusIcon = (props) => {
  return (
    <img className={styles.ammendServiceIcon} src={ammendService} onClick={props.openServiceStatusForm}  />
  );
}


const ServiceList = ({ReadNotification, indicator, data, key, hide, showCommentPopup, showServiceStatusPopup, isInternalUser, ...props }) => { 
  const navigate = useNavigate();

  const goToServiceDetails = (requestId) => {
    navigate(`/demands/${requestId}`);
  }
  return (
    <li id={key} className={`${hide} ${styles.listCardMmargin}`}   >
      <ServiceListContainer>
        {/* <StatusContainer statusName={data.status} /> */}
        <ServiceDetailContainer requestId={data.id} statusName={data.status} >
          <ServiceHeader>
           <div className={styles.header} onClick={()=>goToServiceDetails(data.id)}>
            <ServiceName displayId={data.requestId}/>
            <StatusContainer statusName={data.status} />
           </div>
            <div className={styles.headeright}>
              {/* <DateOfSubmission date={formatSecondsToDate(data.createdAt)} /> */}
            <IconsContainer>
              {/* <SectionGuard canAccess={CAN(Action.CREATE_COMMENT, Resource.DEMANDS)}>
              <CommentBox openCommentForm={(event) => {
              return showCommentPopup(event,data);
              }} />
              </SectionGuard> */}
              {/* <SectionGuard canAccess={CAN(Action.STATUS_UPDATE, Resource.DEMANDS)}>
                <ServiceStatusIcon openServiceStatusForm={(event) => {
                  return showServiceStatusPopup(event,data);}}
                 />
             </SectionGuard> */}
            </IconsContainer></div>
          </ServiceHeader>
          <ServiceMiddleDiv>
            {/* <ServiceDescription description={data.comment} /> */}
          </ServiceMiddleDiv>
          <ServiceFooter indicator={indicator} companyName={data.organization} country={data?.locationDetails?.serviceCountry?.label} location={data?.locationDetails?.serviceRegion?.label} requestId={data.id} createdBy={isString(data.createdBy)?data.createdBy:data.createdBy?.name} createdByemail={isString(data.createdBy)?data.createdBy:data.createdBy?.email} displayId={data.requestId} isInternalUser={isInternalUser} serviceName={data.service} date={formatDate(data.createdAt)}/>
        </ServiceDetailContainer>
      </ServiceListContainer>
      {/* <div className={styles.collapsibleContainer}>
      <div className={styles.collapsible}>
      <div className={styles.header}>
          <div>
            <span className={styles.SRid} onClick={()=>goToServiceDetails(data.id)}>
              {data.requestId}
            </span>
            <span
              className={`${styles.status} ${styles[data?.status]}`}>
              {data.status}
            </span>
          </div>
        </div>
      </div>
      </div> */}
    </li>
  );
}

const FieldWrapper = ({ children }) => {
  return <div className={styles.fieldContainer}>{children}</div>;
};

const SelectField = ({ title, options, ...props }) => {
  //let _options = options?.map((item) => ({ value: item.value, label: item.name }));
  //console.log("---optionsssss in dropdown--",_options)
  const [field, meta, helpers] = useField(props);
  
  const hasError = meta.touched && meta.error ? true : false;
  return (
    <FieldWrapper>
      <div>
      <Label text={title} />
      </div>
      <DropDown
        options={options}   
        customStyles={props.customStyles}
        onChange={({ label, value }) => {
          helpers.setValue(value);             
        }}
        value={
          (options && field.value)
            ? options?.find((option) => option.value === field.value)
            : ""
        }
        hasError={hasError}
        isDisabled={props.isDisabled}
      />
      {hasError ? <ErrorLabel text={meta.error} /> : null}
    </FieldWrapper>
  );
};
const SelectedFilters=({selectedFilters,...props})=>{  
  let statusName = selectedFilters?.status?selectedFilters?.status=='All'?'ALL':SERVICE_STATUS_OPTIONS?.find(({value}) => value === selectedFilters?.status)?.label?.toUpperCase():'NOT SELECTED';
  return(
      <div className={styles.selectedFilters}> 
      <div className={styles.labelShowFilter}>
            <span>Showing results for &nbsp;</span>
      </div>        
      {
        selectedFilters?.user_type == USER_TYPE.INTERNAL ?
          <div className={styles.selectedItem}>
            <span className={styles.label}>Customer</span><span className={styles.value}>{selectedFilters?.customer ? selectedFilters?.customer?.toUpperCase() : "NOT SELECTED"}</span>
          </div> : <></>
      }         
      <React.Fragment>             
          <div className={styles.selectedItem}>
              <span className={styles.label}>Service</span><span className={styles.value}>{selectedFilters?.serviceType ? selectedFilters?.serviceType?.toUpperCase() : "NOT SELECTED"}</span>
          </div> 
          <div className={styles.selectedItem}>
              <span className={styles.label}>Status</span><span className={styles.value}>{statusName}</span>
          </div>
      </React.Fragment>
         
    </div>
  )
}
const sortArray =(array)=>{
  if (array.length > 0){
  const newArray=JSON.parse(JSON.stringify(array)); // No change in original array
  return newArray.sort((a,b)=>a?.toUpperCase().localeCompare(b?.toUpperCase()));  // Ascending alphabetical
  }
  return [];
}
const ServicesList = ({setSearchKeyValue,statusCount,isQuickSearch, setIsQuickSearch,columnFilter,columnSecondFilter,sortFilter,sendStatusData, usertype ,ReadNotification,indicator,customerName,getServicesList,data,fetchData,hasNext,isAmendMode, showCommentPopup, statusOptions, filterData, isLoading, getSearchKeyValue, showServiceStatusPopup, isInternalUser,onKeyDownHandler, props, resetFilters, defaultFilters, childRef, services, customerList, searchKeyValue, setStatusFilter, setCustFilter, setServiceFilter, filterSearch, handleExportToExcel}) => {
    const navigate = useNavigate();
  
  const filterIconRef = useRef(null);
  const statuses = [
    { id: 'all', label: 'All', count: allCount(statusCount), className: 'statusAll',color: '#CCCCCC',outline:"#9E9E9E" },
    { id: 'received', label: 'Received', count: statusCount?.received || 0,color: '#FFEA96',infoMessage : "",outline:"#F4D55B" },
    { id: 'in-progress', label: 'In Progress', count: statusCount?.["in-progress"] || 0 ,color: '#FFE1AB',infoMessage : "" ,outline:"#F4B035"},
    { id: 'pending-customer-action', label: 'Pending Customer Action', count:statusCount?.["pending-customer-action"] || 0  , color: '#DCDBFF', infoMessage: "",outline:"#9188E0", },
    { id: 'on-hold', label: 'On Hold', count: statusCount?.["on-hold"] || 0 , color: '#D4D4D4', infoMessage: "" ,outline:"#9B9191"},
    { id: 'completed', label: 'Completed', count: statusCount?.completed || 0 , color: '#ACEDD0', infoMessage: "",outline:"#80D8B0" },
    { id: 'closed', label: 'Closed', count: statusCount?.closed || 0 , color: '#E8E8E8', infoMessage: "",outline:"#C2C2C2" },
   
 ];
  const [checkedStatuses, setCheckedStatuses] = useState(statuses.reduce((acc, status) => ({ ...acc, [status.id]: true }), {}));
  const [filterState, setFilterState] = useState({
    isFilterPopupOpen: false,
    selectedFilterOptions: [],
    isFilterPopupOpenService: false,
    selectedInvoicingPartyOptions: []
  });
  const [sortOrder, setSortOrder] = useState("desc"); 
 const [isSortedAscending, setIsSortedAscending] = useState(true);
  const handleSort = () => {
    const newSortOrder = sortOrder === "desc" ? "asc" : "desc";
    setSortOrder(newSortOrder);
    setIsSortedAscending(!isSortedAscending);
    sortFilter(newSortOrder)
  };
   const getStatusLegendColorClass = (status, userType) => {
      switch (true) {
        case status === DEMAND_STATUS.received && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
          return "statusLegendsIconAmber";
        case status === DEMAND_STATUS.inprogress && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
          return "statusLegendsIconinprogress";
        case status === DEMAND_STATUS.pendingcustomeraction && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
          return "statusLegendsIconBlue";
          case status === DEMAND_STATUS.onhold && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
            return "statusLegendsIconhold";
        case status === DEMAND_STATUS.completed && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
          return "statusLegendsIconcompleted";  
        case status === DEMAND_STATUS.closed && (userType === USER_TYPE.INTERNAL || userType === USER_TYPE.CUSTOMER):
          return "statusLegendsIconclose";
      
        default:
          return status;
      }
    };
 
    const handleFilterApply = (selectedOptions,lastremoved) => {
      setSearchKeyValue('')
     setFilterState(prevState => ({
      ...prevState,
      selectedFilterOptions: selectedOptions
    }));
   if(lastremoved){
    setFilterState(prevState => ({
      ...prevState,
      selectedInvoicingPartyOptions: []
    }));
    columnSecondFilter([])
    }else{
      columnFilter(selectedOptions)
    }
   
  };
  
  const handleInvoicingFilterApply = (options,lastremoved) => {
     setSearchKeyValue('')
    setFilterState(prevState => ({
      ...prevState,
      selectedInvoicingPartyOptions: options
    }));
    if(lastremoved){
      setFilterState(prevState => ({
        ...prevState,
        selectedFilterOptions: []
      }));
      columnFilter([])
    }
    else{
      columnSecondFilter(options)
    }
  
  };
  
  const handleFilterReset = () => {
    setFilterState(prevState => ({
      ...prevState,
      selectedFilterOptions: [],
    }));
    columnFilter([]);
  };
  
  const handleServiceFilterReset = () => {
    setFilterState(prevState => ({
      ...prevState,
      selectedInvoicingPartyOptions: [],
    }));
    columnSecondFilter([])
  };
  
  const resetappliedFilter = () =>{
    handleFilterReset();
    handleServiceFilterReset();
    columnFilter([]);
    columnSecondFilter([])
    setIsQuickSearch(false)
  }

    const headers = usertype === USER_TYPE.INTERNAL
    ? [
      <div className={styles.organisationName} key="requestid"><div className={styles.orgname}>Request ID</div></div>,
            <div className={styles.organisationName} key="organisationlegalname"><div className={styles.orgname}>Organisation TADIG Code</div>
          <img className={styles.filter} src={IMAGES.filtericon} 
           onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpen: true }))}
            alt="Filter"/>
             {filterState.isFilterPopupOpen && (
                       <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpen} getServicesList={getServicesList} options={customerList?.map(list => ({ value: list.cpName, label: list.cpName }))} onApply={handleFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpen: false }))} label={"Organisation Legal Name"} initialSelectedOptions={filterState.selectedFilterOptions} onReset={handleFilterReset}/>
                     </div>)}
        </div>,   
         <div className={styles.organisationName} key="Service"><div className={styles.orgname}>Service</div>
         <img className={styles.filter} src={IMAGES.filtericon} 
          onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenService: true }))}
           alt="Filter"/>
           {filterState.isFilterPopupOpenService && (
                       <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpenService} options={services?.map(list => ({ value: list.value, label: list.label }))} onApply={handleInvoicingFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenService: false }))} label={"Service"} initialSelectedOptions={filterState.selectedInvoicingPartyOptions} onReset={handleServiceFilterReset}/>
                     </div>)}
       </div>,   
         <div className={styles.organisationName} key="region"><div className={styles.orgname}>Region</div></div>,
         <div className={styles.organisationName} key="country"><div className={styles.orgname}>Country</div></div>,
         <div className={styles.organisationName} key="requestor"><div className={styles.orgname}>Requestor</div></div>,
        <div className={styles.organisationName} key="submittedOn">
          <div className={styles.orgname}>Submitted On</div>
          <img className={`${styles.sorting} ${isSortedAscending?"":styles.sortasc}`} src={IMAGES.sorticon} onClick={handleSort} alt="sort"/>
          </div>]
        : 
        [  <div className={styles.organisationName} key="requestid"><div className={styles.orgname}>Request ID</div></div>,
          <div className={styles.organisationName} key="Service"><div className={styles.orgname}>Service</div>
          <img className={styles.filter} src={IMAGES.filtericon} 
           onClick={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenService: true }))}
            alt="Filter"/>
            {filterState.isFilterPopupOpenService && (
                       <div className={styles.popup}><Filter isOpen={filterState.isFilterPopupOpenService} options={services?.map(list => ({ value: list.value, label: list.label }))} onApply={handleInvoicingFilterApply} onRequestClose={() => setFilterState(prevState => ({ ...prevState, isFilterPopupOpenService: false }))} label={"Service"} initialSelectedOptions={filterState.selectedInvoicingPartyOptions} onReset={handleServiceFilterReset}/>
                     </div>)}
        </div>,  
                 <div className={styles.organisationName} key="region"><div className={styles.orgname}>Region</div></div>,
                 <div className={styles.organisationName} key="country"><div className={styles.orgname}>Country</div></div>,
                 <div className={styles.organisationName} key="requestor"><div className={styles.orgname}>Requestor</div></div>,
        <div className={styles.organisationName} key="submittedOn">
          <div className={styles.orgname}>Submitted On</div>
          <img className={`${styles.sorting} ${isSortedAscending?"":styles.sortasc}`} src={IMAGES.sorticon} onClick={handleSort} alt="sort"/>
          </div>];

const gotoDetail = (requestId,notificationId) => {
  navigate(`/demands/${requestId}`);
  const payload = {
    read: true,
  };
  if(notificationId){
  ReadNotification(payload, notificationId)
  }
}

    const mappedData = data?.map((demandfiltered) => {
    const colorClass = getStatusLegendColorClass(demandfiltered.status, usertype);
    const match = indicator?.data?.DEMANDS_MODULE?.find(row => row.detailsId === demandfiltered.id);
    const highlightClass = match ? styles.highlightedRow : '';
      return {
        "requestid": (
          <span className={`${styles.customDataContent} ${styles.posrelative}`}>
           <SecondaryTooltip 
           text={colorClass==="statusLegendsIconAmber"?"Received":colorClass==="statusLegendsIconinprogress"?"In Progress":colorClass==="statusLegendsIconBlue"?"Pending Customer Action":colorClass==="statusLegendsIconhold"?"On Hold":colorClass==="statusLegendsIconcompleted"?"Completed":"Closed"} 
           positions={['bottom', 'left']}>
            <span className={`${highlightClass}`}></span>
            <span className={`${styles.statusLegendsIcon} ${match ? styles.margingleft:""} ${styles[colorClass]} ${styles.spanSpaceing}`} />
            </SecondaryTooltip>
            <span className={`${styles.invoiceId}`}
         onClick={()=>gotoDetail(demandfiltered.id,match?.id)}
         >
              <SecondaryTooltip text={demandfiltered?.requestId} positions={['bottom', 'right']}>{demandfiltered?.requestId}</SecondaryTooltip></span>
          </span>
        ),
        
    "organisationlegalname": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={demandfiltered?.organization} positions={['bottom', 'right']}>{demandfiltered?.organization}</SecondaryTooltip></span>,     
    "Service": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={demandfiltered?.service} positions={['bottom', 'right']}>{demandfiltered?.service}</SecondaryTooltip></span>,
    "region": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={demandfiltered?.locationDetails.serviceRegion.label} positions={['bottom', 'right']}>{demandfiltered?.locationDetails.serviceRegion.label}</SecondaryTooltip></span>,    
    "submittedOn": <div className={styles.invoicedue}><SecondaryTooltip text={formatDate(demandfiltered?.createdAt)} positions={['bottom', 'right']}>{formatDate(demandfiltered?.createdAt)}</SecondaryTooltip></div>,
    "requestor": <span className={`${styles.customDataContent}`}><SecondaryTooltip copy={true} text={demandfiltered?.createdBy.email} positions={['bottom', 'right']}>{demandfiltered?.createdBy.name}</SecondaryTooltip></span>,
    "country": <span className={`${styles.customDataContent}`}><SecondaryTooltip text={demandfiltered?.locationDetails.serviceCountry.label} positions={['bottom', 'right']}>{demandfiltered?.locationDetails.serviceCountry.label}</SecondaryTooltip></span>,

      };
    });
    const handleStatusChange = (newCheckedStatuses) => {
      setCheckedStatuses(newCheckedStatuses)
     sendStatusData(newCheckedStatuses);
    };
    

    useEffect(() => {
      if (isQuickSearch) {
        resetappliedFilter();
      }
    }, [isQuickSearch]);
    


  return (
<>
      <SearchBar setSearchKeyValue={setSearchKeyValue} setIsQuickSearch={setIsQuickSearch}customerName={customerName} data={data} usertype={usertype} filterData={filterData} getSearchKeyValue={getSearchKeyValue} onKeyDownHandler={onKeyDownHandler} defaultFilters={defaultFilters} childRef={childRef} services={services} customerList={customerList} searchKeyValue={searchKeyValue} setStatusFilter={setStatusFilter} setCustFilter={setCustFilter} setServiceFilter={setServiceFilter} filterSearch={filterSearch} handleExportToExcel={handleExportToExcel}/>    
      
    <div className={styles.listContainer}>
    {isLoading && <Loader />}
      <Status statuses={statuses} isQuickSearch={isQuickSearch} onStatusChange={handleStatusChange} infoImage={IMAGES.infoCircle}   />         
      <ul className={styles.servicesList}>
        
        {
          <InfiniteScroll
          dataLength={data?.length} //This is important field to render the next data
          next={() => {
           if (!checkAllFalse(checkedStatuses)){
            fetchData();
           }
          }}
          hasMore={!checkAllFalse(checkedStatuses) ? hasNext :null}
          style={{ overflow: "visible" }}
          loader={
              <Loader />
          }
        > 
             {(filterState.selectedFilterOptions.length > 0 || filterState.selectedInvoicingPartyOptions.length>0) && (<AppliedFilter selectedFilterOptions={filterState.selectedFilterOptions} selectedInvoicingPartyOptions={filterState.selectedInvoicingPartyOptions} onReset={resetappliedFilter}setSelectedFilterOptions={setFilterState} handleFilterApply={handleFilterApply} handleInvoicingFilterApply={handleInvoicingFilterApply}  firstFilterLabel={"Organisation Legal Name"} secondFilterLabel={"Service"}/>)}
                  <div className={styles.wrapper}>
                     <Table headers={headers} data={checkAllFalse(checkedStatuses)?[]:mappedData} height={`${mappedData?.length<8&&(filterState?.isFilterPopupOpen||filterState?.isFilterPopupOpenService)?"480px":"auto"}`} width="100%" 
                    columnConfig={columnConfig} 
                     nodata={!isLoading && mappedData?.length===0?"No Data.":checkAllFalse(checkedStatuses)?"Select Filter for data.":"No Data"} />
                  </div>
        </InfiniteScroll>
        }
      </ul>
    </div>
</>
  );
}


const CommentBox = (props) => { 
  return (
    <img className={styles.commentIcon} src={comment} onClick={props.openCommentForm} />
  );
}

const SearchBar = ({ setSearchKeyValue,usertype,customerName,data,setIsQuickSearch, filterData, getSearchKeyValue,onKeyDownHandler,resetFilters, defaultFilters, childRef, services, customerList, searchKeyValue, setStatusFilter, setCustFilter, setServiceFilter, filterSearch, handleExportToExcel}) => {
  let obj = {
    width: '185px',
  }
  const inputStyle = `${styles.inputContainer} `;

  const filterByQuickSearch=(value)=>{
    setSearchKeyValue(value)
    setIsQuickSearch(true);
  }
  
  return (
    <>
      <div className={styles.mainContainer}>
      <div className={styles.orgnamelable}>{usertype === USER_TYPE.INTERNAL ? "Demand Requests" : `${customerName} - Demand`} </div>
      <div className={styles.searchContainermain}>
        <div className={styles.searchContainer}>
          <Search 
           placeholder={"Search"}
           style={inputStyle}
           onButtonClick={filterByQuickSearch}
           searchKeyValue={searchKeyValue}
          />        
        </div>
        <SectionGuard canAccess={CAN(Action.DOWNLOAD, Resource.DEMANDS)}>
        <div>
        <button className={styles.download} onClick={handleExportToExcel}> <SecondaryTooltip text={"Download Excel"}  positions={['bottom', 'right']} > <span><img className={styles.downloadlogo} src={IMAGES.downloadxlxs}/></span> </SecondaryTooltip></button> 
        </div>
        </SectionGuard>  
        </div> 
      </div>
      </>
  );
};

const NewRequestViewer = (props) => {
  let servicesList = props.servicesList; 
  let [serviceData, setServiceData] = useState({});  
  let [serviceStatus, setServiceStatus] = useState('');  
  let [searchKeyValue, setSearchKeyValue] = useState('');
  let [filterSearch, setFilterSearch] = useState(false);
  let [filterSearchValue, setFilterSearchValue] = useState('');
  const [status, setStatus] = useState('');
  const [orgLegal, setOrgLegal] = useState([]);
  const [serviceName, setService] = useState([]);
  const [sortOrder, setSort] = useState();
  const [isQuickSearch, setIsQuickSearch] = useState(false);
  
  const childRef = useRef();


  useEffect(() => {
   // props.getServicesList();
    props.getCustomers();
    props.getServices();
  }, []);


  const filterData = () => {      
    let searchKey = searchKeyValue?.trim();
    let statusFilter = Object.keys(status)?.filter((key) => status[key]).join(",")
    const orgName = orgLegal.map((item) => item.value).join(",");
    const service = serviceName.map((item) => item.value).join(",");
    if(!checkAllFalse(status)){
      props.getServicesList("", searchKey,null,statusFilter,orgName,service,sortOrder)
    }
    
  }

  const handleStatusData = (data) => {
    setStatus(data);
  };

  const handleOrglegal = (data) =>{
    setOrgLegal(data);
  }
  const handleServiceName=(data)=>{
    setService(data)
  }
  const hadleSortFilter=(data)=>{
    setSort(data)
  }

  const closeCommentsSidebar = () => {
    props.closeCommentForm();
  }
  

    //this.state.setServiceData({serviceDetails}); // open blank form 
  const showCommentPopup=(event,data)=>{  
    event.stopPropagation();  
    setServiceData({data});    
    props.openCommentForm();
  }
  const showServiceStatusPopup=(event,data)=>{  
    event.stopPropagation(); 
    setServiceData({data});
    setServiceStatus(data.status);
    props.openServiceStatusForm();
  }

  const fetchData=()=>{
    if(props.next){
      let statusFilter = Object.keys(status)?.filter((key) => status[key]).join(",")
      const orgName = orgLegal.map((item) => item.value).join(",");
      const service = serviceName.map((item) => item.value).join(",");
      props.getServicesList('',searchKeyValue,props.next,statusFilter,orgName,service,sortOrder);
    }
  }

  const onKeyDownHandler = (e) => {   // filter data on enter event

    if (e.key === "Enter"){
      setIsQuickSearch(true)
      const orgName = orgLegal.map((item) => item.value).join(",");
      const service = serviceName.map((item) => item.value).join(",");
      let statusFilter = Object.keys(status)?.filter((key) => status[key]).join(",");
      filterData('',searchKeyValue,null,statusFilter,orgName,service,sortOrder);     
    }
  };

  useEffect(()=>{
    const service = serviceName.map((item) => item.value).join(",");
    let statusFilter = Object.keys(status)?.filter((key) => status[key]).join(",")
    const orgName = orgLegal.map((item) => item.value).join(",");
    filterData('',searchKeyValue,props.next,statusFilter,orgName,service,sortOrder);
  },[status,orgLegal,serviceName,sortOrder])


const handleExportToExcel = () => { 
  let fileNameStartWith=props.myRights?.userType==USER_TYPE.INTERNAL?"Demands":"My Demands" // dynamic tab name
  const orgName = orgLegal.map((item) => item.value).join(",");
  const service = serviceName.map((item) => item.value).join(",");
  let statusFilter = Object.keys(status)?.filter((key) => status[key]).join(",")
  let qString = filterSearchValue+"&download="+encodeURIComponent(true); 
  props.downloadServicesList(qString,searchKeyValue,statusFilter,orgName,service,sortOrder).then((result) => {
    //console.log("result",result.downloadedData);
    const date = new Date();
    const formattedDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear().toString().slice(-2)}`;
    exportToExcel(result.downloadedData, `${fileNameStartWith?.split(" ").join("")}-${formattedDate}`,fileNameStartWith); 
  })
  .catch((error) => {   
  }); 
};

const tabsInfoCustomer="This section provides an overview of all your submitted demands, including their current status and additional details. You can also add comments and attach additional documents, if any."
const tabsInfoInternal="This section provides an overview of all demands raised by customers. Additionally, you can add comments and update the status as needed."
 
  return (
    <React.Fragment>
      {/* <Sidebar
        isOpen={props.isCommentFormOpen}
        onClose={closeCommentsSidebar}
        headerTxt={"Add comment"}
      > */}
      <div className={`${styles.moduledescription} ${CAN(Action.LIST, Resource.DEMANDS) && CAN(Action.LIST, Resource.LIVE_SERVICES)?"":styles.marginfromtop}`}> <img  className={styles.descriptioncircle} src={IMAGES.infoCircle}/>{props.myRights?.userType === USER_TYPE.INTERNAL ? tabsInfoInternal : tabsInfoCustomer }</div>
      <Comment serviceData={serviceData}></Comment>
      {/* </Sidebar>     */}
      <ServiceStatus openFrom={"NewServiceViewer"} serviceData={serviceData} status={serviceStatus}></ServiceStatus>
      <div  className={styles.collapsibleContainer}>
      <ServicesList setSearchKeyValue={setSearchKeyValue} statusCount={props?.statusCount}isQuickSearch={isQuickSearch} setIsQuickSearch={setIsQuickSearch} sendStatusData={handleStatusData} columnSecondFilter={handleServiceName} columnFilter={handleOrglegal} sortFilter={hadleSortFilter}  usertype={props?.myRights?.userType}ReadNotification={props.ReadNotification}  indicator={props.notifications} getServicesList={props.getServicesList}customerName={props?.myRights?.customerUserMetaInfo?.customerName} data={servicesList} fetchData={fetchData} hasNext={props.next} showCommentPopup={showCommentPopup} statusOptions={SERVICE_STATUS_OPTIONS} filterData={filterData} isLoading={props.isLoading}  showServiceStatusPopup={showServiceStatusPopup} isInternalUser={props.myRights?.userType===USER_TYPE.INTERNAL?true:false} onKeyDownHandler={onKeyDownHandler}  childRef={childRef} services={props.services} customerList={props.customers} searchKeyValue={searchKeyValue}  filterSearch={filterSearch} handleExportToExcel={handleExportToExcel}/>
      </div>
    </React.Fragment>
  )
};
export default NewRequestViewer;
import { BASE_URL, GET, SERVICES_LIST, DOWNLOAD_SERVICES_LIST } from "../constants";
import { statusParsing, jsonParsing } from "../utils";
import { getAccessToken } from "../../authActions/authActions";

export const fetchServicesList = async (filter, searchKey,next,status="",customer="",serviceType="",orderBy="") => {
    const token = await getAccessToken();
    let url = `${BASE_URL}${SERVICES_LIST}`;
    if(filter){    
      filter = filter.replace ("filter=&", "");
  }
  let array = [
    {
      key:"filter",
      value:filter
    },
    {
      key:"searchKey",
      value:encodeURIComponent(searchKey)
    },
    {
      key:"status",
      value:encodeURIComponent(status)
    },
    {
      key:"customer",
      value:encodeURIComponent(customer)
    },
    {
      key:"serviceType",
      value:encodeURIComponent(serviceType)
    },
    {
      key:"orderBy",
      value:encodeURIComponent(orderBy)
    },
    {
      key:"next",
      value:next
    } // download true
  ]  

  let queryString=``;
  array.forEach((item)=>{
    if(item.value){
      if(queryString){
        queryString=queryString+`&${item.key}=${item.value}`
      }else{
        queryString=`${item.key}=${item.value}`
      }
    }
  })
 
  if(queryString){
    url=`${url}?${queryString}`;
    url = url.replace ("filter=&", "");
}
    
    const response =  fetch(url, {
        method: GET,
        headers: {
          "Content-Type": "application/json",
          "userAuth" : `bearer ${token}`
        },
      })
      .then(statusParsing)
      .then(jsonParsing);
      
      return response;

};

export const downloadServiceList = async (filter, searchKey,status,customer,serviceType,orderBy) => {
  const token = await getAccessToken();
  let url = `${BASE_URL}${DOWNLOAD_SERVICES_LIST}`;
  if (filter) {
    filter = filter.replace("filter=&", "");
  }
  let array = [
    // {
    //   key: "filter",
    //   value: filter
    // },
    {
      key: "searchKey",
      value: encodeURIComponent(searchKey)
    },
    {
      key:"status",
      value:encodeURIComponent(status)
    },
    {
      key:"customer",
      value:encodeURIComponent(customer)
    },
    {
      key:"serviceType",
      value:encodeURIComponent(serviceType)
    },
    {
      key:"orderBy",
      value:encodeURIComponent(orderBy)
    },
  ]

  let queryString = ``;
  array.forEach((item) => {
    if (item.value) {
      if (queryString) {
        queryString = queryString + `&${item.key}=${item.value}`
      } else {
        queryString = `${item.key}=${item.value}`
      }
    }
  })

  if (queryString) {
    url = `${url}?${queryString}`;
    url = url.replace("filter=&", "");
  }

  const response = fetch(url, {
    method: GET,
    headers: {
      "Content-Type": "application/json",
      "userAuth": `bearer ${token}`
    },
  })
    .then(statusParsing)
    .then(jsonParsing);

  return response;

};
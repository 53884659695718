import React from 'react';
import styles from './ConfirmationPopup.module.css'
import IMAGES from '../../assets/images/images';

const ConfirmationPopup = ({ onConfirm, onCancel,data='',header="" }) => {
  return (
    <div className={styles.confirmationpopup}>
      <div className={styles.confirmationpopupcontent}>
        <div className={styles.header}>
          <div className={styles.headertxt}>{header}</div>
          <div><img onClick={onCancel} className={styles.close} src={IMAGES.close}/></div>
        </div>
        <div>{data}</div>
        <div className={styles.buttons} >
        <button className={styles.cancle} onClick={onCancel}>No, Cancel</button>
        <button className={styles.submit} onClick={onConfirm}>Yes, Continue</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopup;

import { FETCH_INCIDENT_LIST } from "../../actions/actionTypes";

const initialState = {
    data: [],
    isLoading: false,
    next:null,
    statusCount: {}
};

const reducer = (state=initialState, action)=>{
    switch(action.type){
        case FETCH_INCIDENT_LIST.REQUEST:
            return {...state, isLoading: true}
        case FETCH_INCIDENT_LIST.FAILURE:
            return {...state, isLoading:false}
        case FETCH_INCIDENT_LIST.SUCCESS:     
        return {
            ...state,
            data: action.obj.isLoadMore ? [...state.data,...action.obj.data] : action.obj.data,
            next: action.obj.next,
            isLoading:false,
            statusCount: action.obj.statusCount
          };      
        default:
            return state
    }   
}

export default reducer;
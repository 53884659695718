import { FETCH_INCIDENT_LIST } from "../actionTypes";
import { fetchIncidentList } from "../../../api/incidentManagement/incidentList.service";

const incidentListRequest = ()=>({type: FETCH_INCIDENT_LIST.REQUEST})
const incidentListFailure = (error)=>({type: FETCH_INCIDENT_LIST.FAILURE, error})
const incidentListSuccess = (obj)=>({type: FETCH_INCIDENT_LIST.SUCCESS, obj})


export const getIncidentList = (next=null,status,orderBy)=>{
    // console.log("sddsfdf")
    return (dispatch, getState)=>{        
        dispatch(incidentListRequest())
        return fetchIncidentList(next,status,orderBy)
        .then(response => {         
            let obj={
                'data':response.data,
                'next':response.next,
                "isLoadMore": next?true:false,
                'statusCount':response?.statusCount
            }    
            dispatch(incidentListSuccess(obj))
            return Promise.resolve(true)
        })
        .catch(error => {
            dispatch(incidentListFailure(error?.message))
            return Promise.resolve(false)
        })
    }
}